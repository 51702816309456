import React, { useState } from 'react';
import { Stack } from '@mui/material';
import {
  ConfirmDialog,
  TextInput,
  Typography,
} from '../../../../newComponents';
import { useTheme } from '@mui/material/styles';

type RejectHolidaysDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
};
export const RejectHolidaysDialog: React.FC<RejectHolidaysDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <ConfirmDialog
      title={'Rechazar solicitud de vacaciones'}
      severity={'error'}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      handleCloseIconButton={true}
      onConfirm={() => onConfirm(inputValue)}
      confirmLabel={'Enviar'}
      cancelLabel={'Cancelar'}
      ConfirmButtonProps={{
        disabled: !inputValue.length,
      }}
    >
      <Stack spacing={2}>
        <Typography color={theme.newPalette.text.secondary}>
          Escribe el motivo de rechazo:
        </Typography>
        <TextInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Escribe aquí..."
        />
      </Stack>
    </ConfirmDialog>
  );
};
