// @ts-check
import { MenuItem as MuiMenuItem } from '@mui/material';

/**
 * @typedef {import('@mui/material/MenuItem').MenuItemProps} MenuItemProps
 */
/**
 * MenuItem wrapper component
 * @param {MenuItemProps} props
 */
export const MenuItem = (props) => {
  // @ts-ignore
  return <MuiMenuItem {...props} />;
};
