//@ts-check
import React from 'react';
import { Stack } from '@mui/material';
import { renderCellExpand } from '../../../../../../components/Datagrid/renderCellExpand';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { Button } from '../../../../../../newComponents/Button';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Typography } from '../../../../../../newComponents/Typography';
import { DateCell } from '../../../../../../newComponents/DataGrid/Cells/DateCell/DateCell';
import { DocumentHistoryButton } from '../../../../../../businessComponents/DocumentHistory';
import { DOCUMENT_STATUS } from './RecordChecklistTable.constants';
import { EmployeeRecordTagCell } from '../../../../../../newComponents/DataGrid/Cells/EmployeeRecordTagCell/EmployeeRecordTagCell';
import {
  DocumentPendingStatusChip,
  DocumentPendingReviewStatusChip,
  DocumentRequestedStatusChip,
  DocumentRejectedStatusChip,
  DocumentApprovedStatusChip,
} from '../../../../../../businessComponents/DocumentStatusChip';

/**
 * @typedef {import('../../../../../../types/permissions.types').UserPermissions} UserPermissions
 * @typedef {import('../../../../../../types/colors.types').ColorMode} ColorMode
 * @typedef {import('../../EmployeeInfo.types').EmployeeInfo} EmployeeInfo*/

/**
 * @typedef {object} RecordChecklistGridColDefExtras
 * @property {ColorMode} [palette]
 * @property {UserPermissions} [userPermissions]
 * @property {EmployeeInfo} [employeeData]
 * @property {string} [employeeId]
 * @property {(input: {docId: string, userId: string}) => void} [handleClickDocumentTitle]
 * @property {(input: {row: object, colDef: RecordChecklistGridColDef}) => void} [handleUploadDocumentFromTableButton]
 * @property {() => void} [handleOnStatusChange]
 */

/**
 * @typedef {import('@mui/x-data-grid').GridColDef & RecordChecklistGridColDefExtras} RecordChecklistGridColDef
 */

/** @type {RecordChecklistGridColDef[]} */
export const recordChecklistTableColumns = [
  {
    field: '_id',
    width: 80,
    headerName: '',
    renderCell: (params) => {
      /** @type {RecordChecklistGridColDef} */
      const colDef = params.colDef;
      return (
        <Stack alignItems="center" width="100%" p="16px">
          <Stack
            alignItems="center"
            justifyContent="center"
            border={`1px solid ${colDef.palette?.grey.transparent32}`}
            borderRadius="6px"
            width="22px"
            height="24px"
          >
            <Typography variant="body2">{params.row.position}</Typography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    field: 'documentType',
    headerName: 'Tipo de documento',
    width: 280,
    valueGetter: (params) => {
      const { row } = params;
      return `${row.category}:${row.subcategory}`;
    },
    renderCell: EmployeeRecordTagCell,
  },
  {
    field: 'title',
    headerName: 'Nombre del documento',
    maxWidth: 360,
    minWidth: 200,
    flex: 1,
    valueFormatter: (params) => params.value || 'Sin titulo',
    renderCell: (params) => {
      /** @type {RecordChecklistGridColDef} */
      const colDef = params.colDef;
      const { row } = params;
      const { userPermissions } = colDef;

      if (userPermissions && !userPermissions.docs?.read) {
        return (
          <Tooltip
            title="Necesitas permisos para esta acción"
            arrow
            placement="top"
          >
            <span
              style={{
                cursor: 'pointer',
              }}
            >
              {renderCellExpand(params, null)}
            </span>
          </Tooltip>
        );
      }

      if (!row.uploaded) {
        return (
          <Button
            endIcon={<Icon icon="upload_2_line" />}
            color="default"
            variant="contained"
            size="small"
            onClick={() => {
              colDef.handleUploadDocumentFromTableButton &&
                colDef.handleUploadDocumentFromTableButton({ row, colDef });
            }}
          >
            Cargar documento
          </Button>
        );
      }

      return renderCellExpand(params, () =>
        colDef.handleClickDocumentTitle
          ? colDef.handleClickDocumentTitle(row)
          : null,
      );
    },
  },
  {
    field: 'status',
    headerName: 'Estatus',
    headerAlign: 'left',
    width: 220,
    renderCell: (params) => {
      /** @type {RecordChecklistGridColDef} */
      const colDef = params.colDef;
      const { row } = params;

      if (row.status === DOCUMENT_STATUS.PENDING) {
        return <DocumentPendingStatusChip />;
      }
      if (row.status === DOCUMENT_STATUS.PENDING_REVIEW) {
        return <DocumentPendingReviewStatusChip isEmployee={false} />;
      }
      if (row.status === DOCUMENT_STATUS.EMPLOYEE_UPLOAD_DOC) {
        return <DocumentRequestedStatusChip isEmployee={false} />;
      }
      if (row.status === DOCUMENT_STATUS.REJECTED) {
        return (
          <DocumentRejectedStatusChip
            documentId={row._id}
            onStatusChanged={colDef.handleOnStatusChange}
            isEmployee={false}
          />
        );
      }
      return (
        <DocumentApprovedStatusChip
          documentId={row._id}
          hideMenu={!row.canRequestNewDoc}
          onDocumentRequested={colDef.handleOnStatusChange}
        />
      );
    },
  },
  {
    field: 'history',
    headerName: 'Historial',
    width: 100,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ row }) => {
      return <DocumentHistoryButton document={row} />;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de carga',
    width: 150,
    renderCell: ({ value }) => {
      return (
        <Stack alignItems="center" width="100%">
          <DateCell value={value} />
        </Stack>
      );
    },
  },
  {
    field: 'signedAt',
    headerName: 'Fecha de firmado',
    width: 150,
    renderCell: ({ value }) => {
      return (
        <Stack alignItems="center" width="100%">
          <DateCell value={value} />
        </Stack>
      );
    },
  },
];
