import { Container, Stack } from '@mui/material';
import { HolidaysHeader } from '../components/HolidaysHeader';
import { HolidaysTable } from '../components/HolidaysTable';

export const Holidays: React.FC = () => {
  return (
    <>
      <Container
        maxWidth={false}
        style={{
          maxWidth: '1920px',
          flexGrow: '1',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginBottom: '16px',
        }}
      >
        <HolidaysHeader />
        <Stack flexGrow="1">
          <HolidaysTable />
        </Stack>
      </Container>
    </>
  );
};
