import { OrderableAuthorizer } from '../CreateHolidayAuthorizationTeamDialog.types';
import React, { CSSProperties, Fragment } from 'react';
import { ListItem } from '../../../../../../../../newComponents/ListItem';
import { IconButton } from '@mui/material';
import { Checkbox } from '../../../../../../../../newComponents/Checkbox';
import { Label } from '../../../../../../../../newComponents/Label';
import { useTheme } from '@mui/material/styles';
import { AuthorizersTableColumnType } from './TableColumns';
import { DeleteActionType } from './TableComponent';

type RowProps = {
  row: OrderableAuthorizer;
  style: CSSProperties;
  columns: AuthorizersTableColumnType[];
  handleDeleteItems: (
    action: DeleteActionType,
    row?: OrderableAuthorizer,
  ) => void;
  selectedRows: OrderableAuthorizer[];
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    row: OrderableAuthorizer,
  ) => void;
  showIndex: boolean;
  index: number;
};

export const RowItem: React.FC<RowProps> = ({
  row,
  style,
  columns,
  handleDeleteItems,
  selectedRows = [],
  handleCheckboxChange,
  showIndex = true,
  index = 1,
}) => {
  const theme = useTheme();
  const palette = theme.newPalette;
  const authorizersSelectedIds = selectedRows.map(
    (authorizer) => authorizer.id,
  );

  return (
    <ListItem
      key={row.id}
      disablePadding
      sx={{
        '&.MuiListItem-root': {
          height: 76,
          gap: 2,
          px: 1,
          '.delete_button': {
            visibility: 'hidden',
          },
        },
        '&.MuiListItem-root:hover': {
          background: palette.actionStates.hover,
          '.delete_button': {
            visibility: 'visible',
          },
        },
      }}
      style={style}
    >
      <IconButton>
        <Checkbox
          color="primary"
          checked={authorizersSelectedIds.includes(row.id)}
          onChange={(event, checked) =>
            handleCheckboxChange(event, checked, row)
          }
          // disabled={disableActions}
        />
      </IconButton>
      {showIndex && (
        <Label variant={'outlined'} color={'default'} label={index} />
      )}
      {columns.map((column, i) => (
        <Fragment key={i}>
          {column.render
            ? column.render({
                row,
                handleDeleteItems,
                selectedRows,
                palette,
              })
            : row[column.id]}
        </Fragment>
      ))}
    </ListItem>
  );
};
