import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export type VerificationDataType = 'INEIFE' | 'PASSPORT' | 'OTHER';

export type UseResetPasswordType = {
  onSuccess?: (response: any) => void;
  type: VerificationDataType;
};

export function useGetVerificationData() {
  const handleGetVerificationData = async (data: UseResetPasswordType) => {
    const { type, onSuccess } = data;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo identificación...',
      });

      const response = await sifeEndpoints.verificationData.get(
        { params: { type: type } },
        {},
      );

      if (response.status === 200) {
        if (onSuccess) {
          onSuccess(response?.data?.data);
        }

        return;
      }
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleGetVerificationData };
}
