import { AuthorizersTeam } from './DisplayAuthorizersTeams.types';
import { Card, CardContent, Stack } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';
import { Icon } from '../../../../../../components/Icons/Icons';
import { AvatarGroup } from '../../../../../../newComponents/AvatarGroup';
import { Avatar } from '../../../../../../newComponents/Avatar';
import React from 'react';
import { Chip } from '../../../../../../newComponents/Chip';
import { useTheme } from '@mui/material/styles';

type AuthorizersTeamItemProps = {
  authorizersTeam: AuthorizersTeam;
  openUpdateAuthorizersDialog: (team: AuthorizersTeam) => void;
};

export const AuthorizersTeamListItem: React.FC<AuthorizersTeamItemProps> = ({
  authorizersTeam,
  openUpdateAuthorizersDialog,
}) => {
  const theme = useTheme();
  return (
    <>
      <Card
        sx={{
          cursor: 'pointer',
          border: `1px solid ${theme.newPalette.divider}`,
          borderRadius: '1em',
        }}
        onClick={() => openUpdateAuthorizersDialog(authorizersTeam)}
      >
        <CardContent>
          <Stack className="AuthorizersGroupItem_card-CardContent">
            <div>
              <Avatar cssColor={authorizersTeam.color}>
                <Icon icon={'organization_chart'} fill={'white'} />
              </Avatar>
            </div>
            <Typography
              variant="h5"
              sx={{
                borderBottom: `1px solid ${theme.newPalette.divider}`,
              }}
            >
              {authorizersTeam.name}
            </Typography>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={1}
            >
              <Typography variant="body1">Autorizadores:</Typography>
              <Stack direction={'row'} alignItems={'center'}>
                <Icon
                  icon={
                    authorizersTeam.signInOrder
                      ? 'list_ordered'
                      : 'shuffle_line'
                  }
                  disabled
                />
                <AvatarGroup
                  size={'small'}
                  total={authorizersTeam.authorizers.length}
                  max={3}
                >
                  {authorizersTeam.authorizers.map(
                    ({ _id, profilePicture }) => (
                      <Avatar size={'small'} src={profilePicture} key={_id} />
                    ),
                  )}
                </AvatarGroup>
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="body1">Empleados:</Typography>
              <Chip
                variant={'soft'}
                label={`${authorizersTeam.employeesCount} empleado${authorizersTeam.employeesCount === 1 ? '' : 's'}`}
                color={'success'}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
