import { Chip } from '../../newComponents/Chip';
import { Icon } from '../../components/Icons/Icons';
import { Tooltip } from '../../newComponents/Tooltip';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
};

export const DocumentPendingStatusChip = (
  props: DocumentPendingStatusChipProps,
) => {
  const { chipProps, iconProps } = props;

  return (
    <Tooltip
      title="El administrador no ha cargado el documento"
      placement="top"
      arrow
    >
      <Chip
        icon={
          <Icon
            icon="error_warning_line"
            width="24px"
            height="24px"
            {...iconProps}
          />
        }
        color="warning"
        label="Pendiente"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
