import React from 'react';
import { Stack } from '@mui/material';
import { DisplayHolidaysSchemeHeader } from '../components/DisplayHolidaysSchemeHeader';
import { DisplayHolidaysScheme } from '../components/DisplayHolidaysScheme';

export const HolidaySchemes: React.FC = () => {
  return (
    <>
      <Stack m={2} gap={2}>
        <DisplayHolidaysSchemeHeader />
        <DisplayHolidaysScheme />
      </Stack>
    </>
  );
};
