import React from 'react';
import { EditHolidaySchemeDialogFormType } from './EditHolidaySchemeDialog.types';
import { Stack } from '@mui/material';
import { useScreenSize } from '../../../../../../../Hooks';
import { DaysGranted } from './DaysGranted';
import { UseFormReturn } from 'react-hook-form';
import { EditHolidaySchemeProperties } from './EditHolidaySchemeProperties';

type EHSEditSchemeProps = {
  form: UseFormReturn<EditHolidaySchemeDialogFormType>;
};
export const EHSEditScheme: React.FC<EHSEditSchemeProps> = ({ form }) => {
  const { isMobile } = useScreenSize();
  return (
    <>
      <Stack
        className={'EHSEditScheme'}
        direction={isMobile ? 'column' : 'row'}
        gap={1}
      >
        <DaysGranted form={form} />
        <EditHolidaySchemeProperties form={form} />
      </Stack>
    </>
  );
};
