import { ImportError } from './MassiveAuthorizersTeamsImport.types';
import { Alert } from '../../../../../../../newComponents/Alert';
import { Stack, Typography } from '@mui/material';
import { AlertTitle } from '../../../../../../../newComponents/AlertTitle';
import React from 'react';
import { Tabs } from '../../../../../../../newComponents/Tabs';
import { Tab } from '../../../../../../../newComponents/Tab';
import { Chip } from '../../../../../../../newComponents/Chip';
import { useScreenSize } from '../../../../../../../Hooks';
import { TableComponent } from '../AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { useTheme } from '@mui/material/styles';

type ImportMassiveAuthorizersErrorSlideProps = {
  errors: ImportError[];
};
export const ImportMassiveAuthorizersErrorSlide: React.FC<
  ImportMassiveAuthorizersErrorSlideProps
> = ({ errors }) => {
  const theme = useTheme();
  const { md } = useScreenSize();
  const palette = theme.newPalette;

  return (
    <>
      <Stack>
        <Alert variant="standard" severity="error">
          <AlertTitle>Se encontraron errores</AlertTitle>
          Se han encontrado los siguientes errores al actualizar masivamente a
          los flujos de autorización:
        </Alert>
        <Stack
          margin="0 auto"
          width={md ? '100%' : 480}
          sx={{ borderRadius: '0px' }}
        >
          <Tabs value={'errors'} onChange={() => {}} color={'error'}>
            <Tab
              value="errors"
              label="Errores"
              id="assignedWithErrors"
              icon={
                <Chip
                  label={errors.length.toString()}
                  variant="soft"
                  color="error"
                  size="small"
                  sx={chipStyles}
                />
              }
              iconPosition="start"
            />
          </Tabs>
          <TableComponent
            width="100%"
            height={300}
            selectedRows={[]}
            deleteRows={() => {}}
            areAllChecked={false}
            headerBgColor={palette.grey.transparent24}
            headerTextColor={palette.text.secondary}
            rows={errors.map((e) => ({
              ...e,
              _id: e.line.toString(),
              success: false,
              type: 'Employee',
              user: e.value === '' ? 'Celda vacia' : e.value,
            }))}
            columns={[
              {
                id: 'name',
                label: 'Nombre',
                align: 'left',
                disabledPadding: true,
                style: {
                  '&.MuiTableCell-root': {
                    border: 'none',
                    pl: 0.5,
                  },
                },
                render: ({ row, palette }) => {
                  return (
                    <>
                      <Stack>
                        <Typography variant="subtitle2">
                          <strong>{row.user}</strong> (fila {row._id})
                        </Typography>
                        <Typography variant="body2" color={palette.error.main}>
                          {row.message}
                        </Typography>
                      </Stack>
                    </>
                  );
                },
              },
            ]}
            handleLoadMore={() => {}}
            loadedRowCount={errors.length}
            totalRowsCount={errors.length}
          />
        </Stack>
      </Stack>
    </>
  );
};

const chipStyles = {
  '& .MuiChip-label': {
    padding: 1,
  },
  width: 'auto',
  height: 24,
  borderRadius: '6px',
  fontWeight: '700',
};
