import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { EditHolidaySchemeDialogFormType } from './EditHolidaySchemeDialog.types';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../../newComponents';
import { useTheme } from '@mui/material/styles';
import { newPaletteType } from '../../../../../../../theme';
import { Alert } from '../../../../../../../newComponents';
import { TemplateSelector } from '../../../../../../../newComponents/TemplateSelector/TemplateSelector';
import { TemplatesListContainerDialog } from '../../../../../../MyCompany/Settings/Preferences/Templates/TemplatesListDialog/TemplatesListContainerDialog';

type EditHolidaySchemePropertiesProps = {
  form: UseFormReturn<EditHolidaySchemeDialogFormType>;
};

export const EditHolidaySchemeProperties: React.FC<
  EditHolidaySchemePropertiesProps
> = ({ form }) => {
  const theme = useTheme();
  const palette: newPaletteType = theme.newPalette;

  const { getValues } = form;
  const approveTemplateId = getValues('approveTemplateId');
  const holidayTakenTemplateId = getValues('holidayTakenTemplateId');

  return (
    <Stack>
      <Stack sx={{ borderBottom: `1px solid ${palette.divider}` }}>
        <Stack p={2} gap={1}>
          <Typography variant="body2">
            <strong>Reglas de otorgamiento de vacaciones</strong>
          </Typography>
          <Typography variant="body2" color={palette.text.secondary}>
            Cumplido el año laboral el empleado tiene 18 meses para disfrutar
            sus vacaciones
          </Typography>
          <Typography variant="body2">
            <strong>Reglas de vencimiento</strong>
          </Typography>
          <Typography variant="body2" color={palette.text.secondary}>
            Cumplido el año laboral el empleado tiene 18 meses para solicitar
            sus vacaciones
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ borderBottom: `1px solid ${palette.divider}` }}>
        <Stack p={2} gap={1}>
          <Typography variant="body2">
            <strong>Firma de solicitud de vacaciones</strong>
          </Typography>
          <Alert variant="standard" severity="info">
            Una vez apruebes las vacaciones a un empleado, tú y el empleado
            deberán firmar la solicitud de vacaciones
          </Alert>
          <TemplateSelector
            selectedTemplateId={approveTemplateId}
            onSelectTemplate={(template) => {
              form.setValue('approveTemplateId', template?._id || null);
              form.clearErrors('approveTemplateId');
            }}
            error={!!form.formState.errors.approveTemplateId}
          />
          {form.formState.errors.approveTemplateId && (
            <Typography color="error" variant={'body2'}>
              {form.formState.errors.approveTemplateId.message}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ borderBottom: `1px solid ${palette.divider}` }}>
        <Stack p={2} gap={1}>
          <Typography variant="body2">
            <strong>Firma de carta de disfrute de vacaciones</strong>
          </Typography>
          <Alert variant="standard" severity="info">
            Una vez que el empleado regrese de vacaciones el empleado debe
            firmar carta de disfrute de vacaciones
          </Alert>
          <TemplateSelector
            selectedTemplateId={holidayTakenTemplateId}
            onSelectTemplate={(template) => {
              form.setValue('holidayTakenTemplateId', template?._id || null);
            }}
          />
        </Stack>
        <TemplatesListContainerDialog />
      </Stack>
    </Stack>
  );
};
