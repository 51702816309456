import React, { useState } from 'react';
import { Button, Dialog, Typography } from '../../../../../../newComponents';
import { useReactiveVar } from '@apollo/client';
import { MyHolidaysConfigVar } from '../../MyCalendar.vars';
import { HolidaysIllustration } from '../../../../../../components/Illustrations/Illustrations';
import DialogContent from '@mui/material/DialogContent';
import { Stack } from '@mui/material';
import { useQuery } from '@apollo/client/react/hooks';
import { GET_EMPLOYEE_HOLIDAYS_SUMMARY } from '../../MyCalendar.gql';
import { parseHolidaysSummary } from '../../MyCalendar.utils';
import { globalSnackbarVar } from '../../../../../../cache.reactiveVars';
import type { FileToView } from '../../../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.types';
import { openFileViewerDialogInSignableMode } from '../../../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';

export const HolidayRequestApprovedDialog: React.FC = () => {
  const [open, setOpen] = useState(true); // if there is an unsigned approved holiday request always open this dialog
  const { unsignedApprovedHolidayRequest } =
    useReactiveVar(MyHolidaysConfigVar);
  const isThereAnUnsignedRequest = !!unsignedApprovedHolidayRequest;
  const { refetch } = useQuery(GET_EMPLOYEE_HOLIDAYS_SUMMARY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const { getEmployeeHolidaysSummary } = data;
      try {
        const parsedData = parseHolidaysSummary(getEmployeeHolidaysSummary);
        MyHolidaysConfigVar({
          ...MyHolidaysConfigVar(),
          ...parsedData,
        });
      } catch (error) {
        console.error(error);
      }
    },
  });

  const onConfirm = async () => {
    await refetch();
    globalSnackbarVar({
      message: 'Documento firmado',
      show: true,
      severity: 'success',
    });
    MyHolidaysConfigVar({
      ...MyHolidaysConfigVar(),
      approvedHolidays: unsignedApprovedHolidayRequest,
    });
  };

  const openSigner = () => {
    const fileId = unsignedApprovedHolidayRequest.approveEmployeeDocumentId;
    const fileToView: FileToView = {
      id: fileId,
      title: 'Solicitud de vacaciones',
      type: 'DOCUMENT',
    };

    return openFileViewerDialogInSignableMode({
      file: fileToView,
      onSuccess: () => {
        onConfirm();
      },
      onNoFilesToSign: () => {
        globalSnackbarVar({
          show: true,
          message: 'No hay documentos para firmar',
          severity: 'info',
        });
      },
    });
  };

  return (
    <>
      <Dialog
        open={open && isThereAnUnsignedRequest}
        title="Solicitud de vacaciones aprobada"
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <Stack gap={2}>
            <Stack justifyContent={'center'} alignItems={'center'}>
              <HolidaysIllustration
                style={{
                  width: '200px',
                  height: '200px',
                }}
              />
            </Stack>
            <Typography variant="h6" align="center">
              Tus vacaciones fueron aprobadas
            </Typography>
            <Typography variant="body1" align="center">
              Para concluir, deberás firmar tu solicitud de vacaciones.
            </Typography>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={openSigner}
            >
              Firmar
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
