//@ts-check
import React, { useMemo, useState } from 'react';
import {
  Popover,
  Typography,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  IconButton,
} from '../../../../newComponents';
import { Stack } from '@mui/material';
import { EmployeeAvatar } from '../../../../businessComponents/EmployeeAvatar';
import {
  HolidayRequest,
  HolidayRequestAuthorization,
  HolidayRequestAuthorizationStatus,
} from '../../EmployeePortal/MyCalendar/MyCalendar.types';
import { TimelineDotProps } from '@mui/lab/TimelineDot/TimelineDot';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

type PopoverCommentsProps = {
  holiday: HolidayRequest;
};

type TimeLineItem = {
  user: string;
  createdAt: Date;
  comments: string;
  status: HolidayRequestAuthorizationStatus;
  isTheEmployee?: boolean;
};

export const PopoverComments: React.FC<PopoverCommentsProps> = ({
  holiday,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'holidays-simple-popover' : undefined;
  const fullName = `${holiday?.employee?.names} ${holiday?.employee?.lastNameP} ${holiday?.employee?.lastNameM}`;
  const timeLines: TimeLineItem[] = useMemo(
    () =>
      holiday.authorizations.map(
        (authorization: HolidayRequestAuthorization) => {
          const fullname =
            `${authorization.authorizer.names} ${authorization.authorizer.lastNameP} ${authorization.authorizer.lastNameM}`.trim();
          const isTheEmployee =
            holiday?.employee?._id === authorization.authorizer._id;
          return {
            user: fullname,
            comments:
              authorization.status === 'APPROVED'
                ? isTheEmployee
                  ? 'Firmó la solicitud aprobada'
                  : 'Aprobó la solicitud'
                : authorization.comments,
            createdAt: authorization.updatedAt,
            status: authorization.status,
            isTheEmployee,
          };
        },
      ),
    [holiday.authorizations, holiday.employee],
  );

  if (!holiday) return null;

  return (
    <>
      <IconButton icon="chat_4_line" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        arrowDirection="left"
        sx={{}}
      >
        <Stack p={3} spacing={3} sx={{ width: 350, height: 250 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <EmployeeAvatar
                profilePicture={holiday?.employee?.profilePicture}
                name={holiday?.employee?.names}
                size="small"
              />
              <Stack>
                <Typography variant="subtitle2">{fullName}</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontSize={'0.8em'}
                  lineHeight={1.5}
                  p={0}
                >
                  {format(holiday.timestamps.createdAt, 'dd MMM yyyy', {
                    locale: es,
                  })}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Solicitó vacaciones
                </Typography>
                {holiday.comments.length > 0 && (
                  <Typography variant="body2" color="text.secondary">
                    {holiday.comments}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <IconButton icon="close_line" onClick={handleClose} />
          </Stack>
          <Stack height={400} overflow="auto">
            <Timeline
              sx={{
                '& .MuiTimelineItem-root:before': {
                  flex: 0,
                  padding: 0,
                },
                '&.MuiTimeline-root': {
                  padding: 0,
                },
              }}
            >
              {timeLines.map((timeLine, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot
                      color={
                        timeLine.isTheEmployee
                          ? 'info'
                          : {
                              [HolidayRequestAuthorizationStatus.APPROVED]:
                                'success' as TimelineDotProps['color'],
                              [HolidayRequestAuthorizationStatus.REJECTED]:
                                'error' as TimelineDotProps['color'],
                            }[timeLine.status]
                      }
                      variant="filled"
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="subtitle2">{timeLine.user}</Typography>
                    <Stack spacing={1}>
                      <Typography variant="caption" color="text.secondary">
                        {format(timeLine.createdAt, 'dd MMM yyyy', {
                          locale: es,
                        })}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {timeLine.comments}
                      </Typography>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
