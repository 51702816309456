import { Stack, DialogContent, DialogTitle, Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { Typography } from '../../../newComponents/Typography';
import { IconButton } from '../../../newComponents/IconButton';
import { Avatar } from '../../../newComponents/Avatar';
import { useScreenSize } from '../../../Hooks';
import { RequestedEmployeeDocumentList } from './RequestedEmployeeDocumentList';
import {
  requestedEmployeeDocumentsDialogVar,
  resetRequestedEmployeeDocumentsDialog,
} from './RequestedEmployeeDocumentsDialog.vars';

export const RequestedEmployeeDocumentsDialog = () => {
  const { isMobile } = useScreenSize();
  const { open, stepNumberLabel, onClose } = useReactiveVar(
    requestedEmployeeDocumentsDialogVar,
  );

  const handleClose = () => {
    resetRequestedEmployeeDocumentsDialog();
    onClose && onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        sx={{ padding: '24px' }}
      >
        <Stack>
          <Stack direction="row" gap={2} alignItems="center">
            {stepNumberLabel && (
              <Avatar color="primary" size="tiny">
                {stepNumberLabel}
              </Avatar>
            )}
            <Typography variant="h6">Completa tu expediente</Typography>
          </Stack>
          {!isMobile && (
            <Typography variant="body2">
              Para completar tu expediente debes enviar a tu administrador los
              siguientes documentos.
            </Typography>
          )}
        </Stack>
        <Box>
          <IconButton
            icon="close_line"
            onClick={handleClose}
            aria-label="Cerrar diálogo"
          />
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ minHeight: isMobile ? '50vh' : '600px', padding: '16px' }}
      >
        <RequestedEmployeeDocumentList />
      </DialogContent>
    </Dialog>
  );
};
