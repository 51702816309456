// @ts-check
import { StepContent as MuiStepContent } from '@mui/material';

/**
 * @typedef {import('@mui/material/StepContent').StepContentProps} StepContentProps
 */
/**
 * @param {StepContentProps} props
 */
export const StepContent = (props) => {
  // @ts-ignore
  return <MuiStepContent {...props} />;
};
