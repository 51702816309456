import React from 'react';
import { Stack } from '@mui/material';
import { Authorizer } from '../../EmployeePortal/MyCalendar/MyCalendar.types';
import { AvatarGroup, IconButton, Tooltip } from '../../../../newComponents';
import { EmployeeAvatar } from '../../../../layoutComponents/EmployeeAvatar/EmployeeAvatar';
import { Icon } from '../../../../components/Icons/Icons';

type AuthorizersAvatarGroupProps = {
  authorizers: Authorizer[];
  signInOrder: boolean;
  handleOpenAddSigners: () => void;
  handleOpenSignersPopover: () => void;
};
export const AuthorizersAvatarGroup: React.FC<AuthorizersAvatarGroupProps> = ({
  authorizers = [],
  signInOrder,
  handleOpenAddSigners,
  handleOpenSignersPopover = null,
}) => {
  if (authorizers?.length) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap="8px"
      >
        {signInOrder ? (
          <Tooltip title="Firmado por orden">
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                icon="list_ordered"
                onClick={handleOpenSignersPopover}
                size={'small'}
                disabled={!handleOpenSignersPopover}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Firmado aleatorio">
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton
                icon="shuffle_line"
                onClick={handleOpenSignersPopover}
                size={'small'}
                disabled={!handleOpenSignersPopover}
              />
            </span>
          </Tooltip>
        )}
        <AvatarGroup
          total={authorizers?.length}
          sx={{
            justifyContent: 'flex-end',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {authorizers.map((user, i) => {
            const fullname = `${user.names} ${user.lastNameP} ${user.lastNameM}`;
            return (
              <EmployeeAvatar
                key={i}
                profilePicture={user.profilePicture}
                name={fullname}
                color="primary"
                showBadge={false}
                size={'tiny'}
              />
            );
          })}
        </AvatarGroup>
      </Stack>
    );
  }

  return handleOpenAddSigners ? (
    <Icon
      icon="add_line"
      height="20px"
      onClick={() => {
        handleOpenAddSigners();
      }}
    />
  ) : null;
};
