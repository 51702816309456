import { SystemProps } from '@mui/system';
import { Card, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { Fragment, useState } from 'react';
import { CardHeader } from '../../../../../../../Landing/Development/Components/Card/CardHeader';
import { Typography } from '../../../../../../../../newComponents/Typography';
import { Avatar } from '../../../../../../../../newComponents/Avatar';
import { Chip } from '../../../../../../../../newComponents/Chip';
import { Popover } from '../../../../../../../../newComponents/Popover';
import { Icon } from '../../../../../../../../components/Icons/Icons';
import { Authorizer } from '../CreateHolidayAuthorizationTeamDialog.types';

const CustomAvatarPopover = ({
  palette,
  open,
  anchorEl,
  handlePopoverClose,
}) => {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      arrowDirection="right"
    >
      <Stack>
        <Typography variant="subtitle2">Orden aleatorio</Typography>
        <Stack px={3}>
          <ul className="bullets">
            <li>
              <Typography variant="body2" color={palette.text.secondary}>
                Los autorizadores pueden autorizar las vacaciones en cualquier
                orden
              </Typography>
            </li>
          </ul>
        </Stack>
      </Stack>
    </Popover>
  );
};

const stylesCard = (width, rows) => {
  return {
    overflow: 'initial',
    borderRadius: '10px',
    minWidth: width,
    maxWidth: width,
    ...(!rows && {
      height: 117,
    }),
    ...(rows && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
  };
};

type TableCardHeaderProps = {
  width: SystemProps['width'];
  rows: Authorizer[];
  cardTitle: string;
  cardSubHeader: string;
  headerBgColor: string;
  signInOrder: boolean;
  success?: boolean;
};

export const TableCardHeader: React.FC<TableCardHeaderProps> = ({
  width,
  rows,
  cardTitle,
  cardSubHeader,
  headerBgColor,
  signInOrder,
  success = false,
}) => {
  const theme = useTheme();
  const palette = theme.newPalette;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Card sx={stylesCard(width, rows)}>
      <CardHeader
        sx={{
          '&.MuiCardHeader-root': {
            py: 3,
            pl: 3,
            pr: 2,
            '& .MuiCardHeader-action': {
              margin: 0,
            },
            borderBottom: `1px solid ${palette.grey.transparent24}`,
          },
        }}
        title={<Typography variant="subtitle2">{cardTitle}</Typography>}
        subheader={
          <Typography variant="body2" color={palette.text.secondary}>
            {cardSubHeader}
          </Typography>
        }
        avatar={
          <Fragment>
            <Avatar
              sx={{
                '&.MuiAvatar-colorDefault': {
                  backgroundColor: headerBgColor,
                },
              }}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={signInOrder ? undefined : handlePopoverOpen}
              onMouseLeave={signInOrder ? undefined : handlePopoverClose}
            >
              <Icon icon="group" height="20px" fill={palette.grey.grey600} />
            </Avatar>
            <CustomAvatarPopover
              palette={palette}
              open={open}
              anchorEl={anchorEl}
              handlePopoverClose={handlePopoverClose}
            />
          </Fragment>
        }
        action={
          <Chip
            label={`${rows.length} ${rows.length > 1 ? 'autorizadores' : 'autorizador'}`}
            variant="soft"
            size="small"
            color={success ? 'success' : 'primary'}
          />
        }
      >
        {''}
      </CardHeader>
    </Card>
  );
};
