import { HolidayScheme } from '../../holidaySchemes.types';
import { Dialog } from '../../../../../../../newComponents';
import { DialogTitle, IconButton, Stack } from '@mui/material';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { Typography } from '../../../../../../../newComponents';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import {
  EditHolidaySchemeDialogFormType,
  EditHolidaySchemeSteps,
} from './EditHolidaySchemeDialog.types';
import { EHSForm } from './EHSForm';

type EditHolidaySchemeDialogProps = {
  scheme: HolidayScheme;
  open: boolean;
  onClose: () => void;
};
export const EditHolidaySchemeDialog: React.FC<
  EditHolidaySchemeDialogProps
> = ({ scheme, open = false, onClose }) => {
  const theme = useTheme();
  const form = useForm<EditHolidaySchemeDialogFormType>({
    defaultValues: {
      selectedScheme: scheme,
      approveTemplateId: scheme.approveTemplateId,
      holidayTakenTemplateId: scheme.holidayTakenTemplateId,
    },
    mode: 'onBlur',
    shouldFocusError: true,
  });

  const [step, setStep] = useState<EditHolidaySchemeSteps>(
    EditHolidaySchemeSteps.DaysGranted,
  );

  const handlePrev = () => {
    if (step === EditHolidaySchemeSteps.Employees) {
      setStep(EditHolidaySchemeSteps.DaysGranted);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setStep(EditHolidaySchemeSteps.DaysGranted);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        slideMode
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${theme.newPalette.divider}`,
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <IconButton onClick={handleClose}>
              <Icon
                fill={theme.customPalette.textColor.text_black}
                icon="close_line"
                height="20px"
              />
            </IconButton>
            <Typography variant="h6">Editar esquema de vacaciones</Typography>
          </Stack>
        </DialogTitle>
        <EHSForm
          form={form}
          step={step}
          setStep={setStep}
          onClose={handleClose}
          handlePrev={handlePrev}
        />
      </Dialog>
    </>
  );
};
