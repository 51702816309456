import { gql } from '@apollo/client';

export const GET_MY_USER_PROFILE = gql`
  query getMyUserProfile($input: GetMyUserProfileInput) {
    getMyUserProfile(input: $input) {
      __typename
      ... on ErrorToGetMyUserProfile {
        message
      }
      ... on GetMyUserProfileResult {
        message
        success
        user {
          _id
          names
          lastNameP
          lastNameM
          rfc
          email
          phone
          curp
          profilePicture
          emailIsVerified
          phoneIsVerified
        }
      }
    }
  }
`;

export const SEND_VERIFICATION_DATA = gql`
  mutation sendVerificationData($input: SendVerificationDataInput) {
    sendVerificationData(input: $input) {
      ... on ErrorToSendVerificationData {
        message
      }
      ... on SendVerificationDataResult {
        success
        message
      }
    }
  }
`;

export const GET_COMPANY_SIGNER_NOTIFICATIONS_CONFIG = gql`
  query GetCompanySignerNotificationsConfig {
    getCompanySignerNotificationsConfig {
      groupCompanySignerNotifications {
        active
      }
      disableCompanySignerNotifications {
        active
      }
    }
  }
`;

export const UPDATE_COMPANY_SIGNER_NOTIFICATIONS_CONFIG = gql`
  mutation UpdateCompanySignerNotificationsConfig(
    $input: UpdateCompanySignerNotificationsConfigInput
  ) {
    updateCompanySignerNotificationsConfig(input: $input) {
      success
    }
  }
`;
