import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingToViewStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
  isEmployee?: boolean;
};

export const DocumentPendingToViewStatusChip = (
  props: DocumentPendingToViewStatusChipProps,
) => {
  const { chipProps, iconProps, isEmployee = true } = props;

  return (
    <Tooltip
      title={
        isEmployee
          ? 'Debes leer y marcar como visto este documento'
          : 'Uno o más usuarios faltan de marcar cómo visto el documento'
      }
      placement="top"
      arrow
    >
      <Chip
        icon={
          <Icon icon="eye_line" width="24px" height="24px" {...iconProps} />
        }
        color="warning"
        label="Ver"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
