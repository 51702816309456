import gql from 'graphql-tag';

export const GET_MY_USER_PROFILE = gql`
  query getMyProfile {
    getMyProfile {
      user {
        id
        fullName
        email
        phone
        rfc
        profilePicture
        birthdate
        signatures {
          cryptoSignature {
            encryptedKey
            publicKeyEncoded
            active
            reqVerification
            verificationConcluded
          }
        }
      }
    }
  }
`;

export const GET_MY_PROFILE = gql`
  query getMyProfile {
    getMyProfile {
      user {
        id
        fullName
        email
        phone
        rfc
        profilePicture
        signatures {
          cryptoSignature {
            encryptedKey
            publicKeyEncoded
            active
            reqVerification
            verificationConcluded
          }
        }
      }
      pendingDocs {
        onboardingDocs {
          _id
          companyId
          title
          body
          constantType
          updated
        }
        highPriorityDocs {
          _id
          companyId
          title
          body
          constantType
          updated
          canBeSigned
        }
        adminDocs {
          _id
          companyId
          title
          body
          constantType
          updated
        }
      }
    }
  }
`;

export const MY_DASHBOARD = gql`
  query MyDashboard {
    myDashboard {
      pendingTasks {
        id
        name
        type
        date
        canBeSigned
        receiptData {
          startDate
          endDate
          paymentDate
          uuid
        }
        isActive
        priority
      }
    }
  }
`;

export const GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS = gql`
  query GetMyPendingDocumentsAndReceipts($page: Int, $perPage: Int) {
    getMyPendingDocumentsAndReceipts(page: $page, perPage: $perPage) {
      total
      documentsAndReceipts {
        ... on MyPendingDocument {
          __typename
          _id
          title
          canBeSigned
          category
          subcategory
          queueStatus
          timestamps {
            createdAt
          }
          itemType
        }
        ... on MyPendingReceipt {
          __typename
          _id
          queueStatus
          receiptDetail {
            paymentStartDate
            paymentEndDate
          }
          timestamps {
            createdAt
          }
          itemType
        }
      }
      requestedDocuments {
        _id
        title
        category
        subcategory
        fileExtension
        createdAt
        status
        history {
          action
          comment
          createdAt
          createdBy {
            _id
            names
            lastNameP
            lastNameM
            rfc
            curp
            fullName
            profilePicture
          }
        }
      }
    }
  }
`;

export const MY_DOCUMENTS = gql`
  query MyDocuments($page: Int, $perPage: Int, $filter: MyDocumentsFilter) {
    myDocuments(page: $page, perPage: $perPage, filter: $filter) {
      count
      documents {
        _id
        title
        type
        signed
        signDate
        seen
        canBeSigned
        category
        subcategory
        completed
        timestamps {
          createdAt
        }
        fileExtension
        historyStatus
        history {
          action
          comment
          createdAt
          createdBy {
            _id
            names
            lastNameP
            lastNameM
            rfc
            curp
            fullName
            profilePicture
          }
        }
      }
      requestedDocuments {
        _id
        title
        category
        subcategory
        fileExtension
        createdAt
        status
        history {
          action
          comment
          createdAt
          createdBy {
            _id
            names
            lastNameP
            lastNameM
            rfc
            curp
            fullName
            profilePicture
          }
        }
      }
    }
  }
`;

export const MY_RECEIPTS = gql`
  query MyReceipts(
    $page: Int
    $perPage: Int
    $filter: MyReceiptsFilter
    $sort: MyReceiptsSort
  ) {
    myReceipts(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      receipts {
        id
        title
        type
        signed
        signDate
        type
        canBeSigned
        signatureType
        timestamps {
          createdAt
        }
        isActive
        receiptDetail {
          stampUUID
          paymentStartDate
          paymentEndDate
          paymentDate
        }
      }
    }
  }
`;

export const SET_MY_DATA = gql`
  mutation SetMyData($input: MyDataInput) {
    setMyData(input: $input) {
      id
      email
      phone
      rfc
    }
  }
`;

export const UPLOAD_PROFILE_PICTURE = gql`
  mutation uploadProfilePicture($file: Upload!) {
    uploadProfilePicture(file: $file)
  }
`;

export const SKIP_PROFILE_PICTURE = gql`
  mutation SkipProfilePicture {
    skipProfilePicture
  }
`;

export const DOWNLOAD_MY_FILES = gql`
  query downloadMyFiles($input: DownloadMyFilesInput) {
    downloadMyFiles(input: $input)
  }
`;
export const SIFE_SEND_DOCUMENTS = gql`
  mutation sifeSendDocuments($input: [SifeSendDocumentInput]) {
    sifeSendDocuments(input: $input) {
      __typename
      ... on UserNotFound {
        message
      }
      ... on InvalidToken {
        message
      }
      ... on TokenExpired {
        message
      }
    }
  }
`;

export const SIFE_CONFIRM_DOCUMENT_SIGN = gql`
  mutation SifeConfirmDocumentSign($input: [SifeConfirmSignInput]) {
    sifeConfirmDocumentSign(input: $input)
  }
`;

export const GET_MY_HTML_FILE = gql`
  query getMyFileAsHtml($type: DownloadRequestTypeEnum!, $searchId: String) {
    getMyFileAsHtml(type: $type, searchId: $searchId)
  }
`;

export const DOWNLOAD_MY_FILE = gql`
  query downloadMyFile(
    $type: DownloadRequestTypeEnum!
    $searchId: String!
    $asAttachment: Boolean
  ) {
    downloadMyFile(
      type: $type
      searchId: $searchId
      asAttachment: $asAttachment
    ) {
      __typename
      ... on FilePresignedUrl {
        url
      }
      ... on DownloadUserFileException {
        message
        code
      }
    }
  }
`;

export const SET_DOCUMENT_SEEN = gql`
  mutation SetDocumentSeen($documentId: String) {
    setDocumentSeen(documentId: $documentId) {
      id
    }
  }
`;

export const CLICK_NOTICE = gql`
  mutation clickNotice($noticeId: String!) {
    clickNotice(noticeId: $noticeId) {
      id
      title
      destinationUrl
    }
  }
`;

export const OLDEST_UNSIGNED_RECEIPT = gql`
  query OldestUnsignedReceipt {
    oldestUnsignedReceipt {
      data {
        _id
        id
        canBeSigned
        employeeId
        fullName
        isActive
        linkUuid
        period
        previousId
        queueStatus
      }
      unsignedReceiptsCount
    }
  }
`;
