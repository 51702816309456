import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Stack } from '@mui/material';
import { AuthorizersTeam } from './DisplayAuthorizersTeams.types';
import { Button, Typography } from '../../../../../../newComponents';
import Searchbar from '../../../../../../components/Inputs/Searchbar';
import { AuthorizersTeamListItem } from './AuthorizersTeamListItem';
import { useScreenSize } from '../../../../../../Hooks';

type DisplayAuthorizersTeamsProps = {
  authorizersTeams: AuthorizersTeam[];
  openAddAuthorizersDialog: () => void;
  openAddMassiveAuthorizersDialog: () => void;
  openUpdateAuthorizersDialog: (team: AuthorizersTeam) => void;
};

export const DisplayAuthorizersTeams: React.FC<
  DisplayAuthorizersTeamsProps
> = ({
  authorizersTeams,
  openAddAuthorizersDialog,
  openUpdateAuthorizersDialog,
  openAddMassiveAuthorizersDialog,
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const [searchValue, setSearchValue] = useState('');

  const updateSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      <Stack
        className={'authorizersGroups_header'}
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'space-between'}
        p={2}
        gap={2}
      >
        <Stack>
          <Typography variant="h4">Autorizadores de vacaciones</Typography>
          <Typography variant="body1" color={theme.newPalette.text.secondary}>
            Define a tus equipos, asignales empleados y configura el orden de
            autorización de las vacaciones
          </Typography>
        </Stack>
        <Stack direction={'row'} gap={2}>
          {!isMobile && (
            <Button
              onClick={openAddMassiveAuthorizersDialog}
              variant="outlined"
              color={'default'}
            >
              Actualización masiva
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={openAddAuthorizersDialog}
            fullWidth={isMobile}
          >
            Crear
          </Button>
        </Stack>
      </Stack>
      <Stack className="authorizersGroups_section" p={2} gap={2}>
        <Searchbar
          fullWidth
          disabled={false}
          setInputValue={setSearchValue}
          onChange={updateSearchValue}
          value={searchValue}
          placeholder={'Buscar equipos'}
        />
        <Grid container spacing={2}>
          {authorizersTeams
            .filter((authorizersTeam) =>
              authorizersTeam.name
                .toLowerCase()
                .includes(searchValue.toLowerCase()),
            )
            .map((authorizersTeam) => (
              <Grid item xs={12} md={3} lg={3} key={authorizersTeam._id} p={2}>
                <AuthorizersTeamListItem
                  authorizersTeam={authorizersTeam}
                  openUpdateAuthorizersDialog={openUpdateAuthorizersDialog}
                />
              </Grid>
            ))}
        </Grid>
      </Stack>
    </>
  );
};
