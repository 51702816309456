// @ts-check
import { Stack, Box, useTheme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Icon } from '../../../../components/Icons/Icons';
import { Button } from '../../../../newComponents/Button';
import { getHmtlFromCreator } from './helper';
import { DocumentPreviewDialog } from './DocumentPreviewDialog';
import { TextEditor } from '../Common/TextEditor';
import { TemplateList } from '../Templates/TemplateList';
import { SimpleTemplateCreationDialog } from '../Templates/TemplateCreationDialog';
import { DocumentSettings } from '../Common/DocumentSettings';
import { DocumentSignatureSettings } from '../Common/DocumentSignatureSettings';
import { DocumentSidebar } from './DocumentSidebar';
import { DocumentVariables } from '../Common/DocumentVariables';
import { TooltipDark } from '../../../../components/Tooltip/Tooltip';
import {
  globalBackdropVar,
  userPermissionsVar,
} from '../../../../cache.reactiveVars';
import { openTemplatesListDialogVar } from '../../../MyCompany/Settings/Preferences/Templates/Templates.vars';
import { TemplatesListContainerDialog } from '../../../MyCompany/Settings/Preferences/Templates/TemplatesListDialog/TemplatesListContainerDialog';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

/**
 * @param {object} props
 * @param {boolean} props.watchTitle
 * @param {React.Dispatch<boolean>} props.setTemplateCreatorDialog
 * @param {React.Dispatch<boolean>} props.setPreview
 */
const OptionButtons = (props) => {
  const { watchTitle, setTemplateCreatorDialog, setPreview } = props;
  const userPermissions = useReactiveVar(userPermissionsVar);
  const userHasPermissionToCreateOrEditTemplate =
    userPermissions &&
    userPermissions.templates &&
    userPermissions.templates.update;

  return (
    <>
      <TooltipDark
        title={
          (!userHasPermissionToCreateOrEditTemplate &&
            'No cuentas con los permisos para esta acción') ||
          (!watchTitle &&
            'Debes agregar un Nombre al documento para poder guardar como plantilla')
        }
        placement="top"
      >
        <span>
          <Button
            variant="soft"
            color="primary"
            size="small"
            disabled={!userHasPermissionToCreateOrEditTemplate || !watchTitle}
            startIcon={<Icon height="18px" icon="save_line" />}
            onClick={() => setTemplateCreatorDialog(true)}
            sx={{ textTransform: 'none !important', p: '4px 10px' }}
          >
            Guardar como plantilla
          </Button>
        </span>
      </TooltipDark>
      <Box>
        <TooltipDark
          title={
            !watchTitle &&
            'Debes agregar un Nombre al documento para poder visualizar'
          }
          placement="top"
        >
          <span>
            <Button
              color="default"
              variant="outlined"
              size="small"
              disabled={!watchTitle}
              startIcon={<Icon height="18px" icon="search_eye_line" />}
              onClick={() => setPreview(true)}
              sx={{ textTransform: 'none !important', p: '4px 10px' }}
            >
              Previsualizar
            </Button>
          </span>
        </TooltipDark>
      </Box>
    </>
  );
};

export const DocumentHandler = ({ form, editorRef }) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const watchTitle = form.watch('title');

  const [activeTab, setActiveTab] = useState('settings');
  const [preview, setPreview] = useState(false);
  const [templateCreatorDialog, setTemplateCreatorDialog] = useState(false);

  const createHtml = () => {
    const values = form.getValues();
    const content = values.myEditor;
    const htmlPopulate = getHmtlFromCreator({
      data: values,
      preview,
      content,
    });
    return htmlPopulate;
  };

  const handleAddVariable = ({ variable, color }) => {
    if (!editorRef?.current) return;
    const text = `<span id="variable" style="color: ${color};">[${variable}]</span>`;
    editorRef.current.insertContent(text);
    editorRef.current.insertContent(` `);
  };

  const onOpenTemplate = (data) => {
    if (!data) return;
    form.setValue('title', data.title);
    form.setValue('myEditor', data.htmlContent);
  };

  const handleChangeTab = (tab) => {
    if (tab === 'templates') {
      globalBackdropVar({ open: true, text: 'Cargando plantillas...' });
      return openTemplatesListDialogVar({ open: true, onOpenTemplate });
    }
    setActiveTab(tab);
  };

  if (isMobile) {
    return (
      <Stack display="flex" direction="column" gap={4}>
        <Stack display="flex" direction="column" ml={4}>
          <DocumentSidebar
            activeTab={activeTab}
            setActiveTab={handleChangeTab}
          />
          <Stack
            spacing={3}
            flexGrow="1"
            display="flex"
            justifyContent="center"
          >
            <DocumentSettings form={form} />
            {activeTab === 'settings' && (
              <DocumentSignatureSettings form={form} docType="creator" />
            )}
            {activeTab === 'variables' && (
              <DocumentVariables handleAddVariable={handleAddVariable} />
            )}
            {activeTab === 'templates' && (
              <TemplateList onOpenTemplate={onOpenTemplate} />
            )}
          </Stack>
        </Stack>
        <Stack ml={4} mb={2}>
          <Stack display="flex" flexDirection="column" gap="16px" mb={4}>
            <OptionButtons
              watchTitle={watchTitle}
              setTemplateCreatorDialog={setTemplateCreatorDialog}
              setPreview={setPreview}
            />
          </Stack>
          <Stack>
            <TextEditor form={form} editorRef={editorRef} />
          </Stack>
        </Stack>
        <SimpleTemplateCreationDialog
          showDialog={templateCreatorDialog}
          closeDialog={() => setTemplateCreatorDialog(false)}
          template={{
            title: form.getValues('title'),
            myEditor: form.getValues('myEditor'),
            category: form.getValues('categories').category,
            subcategory: form.getValues('categories').subcategory,
          }}
        />
        {preview && (
          <DocumentPreviewDialog
            open={preview}
            setOpen={setPreview}
            createHtml={createHtml}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack display="flex" direction="row" gap="24px">
      <Stack display="flex" direction="row">
        <DocumentSidebar activeTab={activeTab} setActiveTab={handleChangeTab} />
        <Stack
          spacing={3}
          sx={{
            width: '350px',
            minWidth: '250px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {activeTab === 'settings' && (
            <>
              <DocumentSettings form={form} />
              <DocumentSignatureSettings form={form} docType="creator" />
            </>
          )}
          {activeTab === 'variables' && (
            <>
              <DocumentSettings form={form} />
              <DocumentVariables handleAddVariable={handleAddVariable} />
            </>
          )}
          {activeTab === 'templates' && (
            <>
              <DocumentSettings form={form} />
              <TemplateList onOpenTemplate={onOpenTemplate} />
            </>
          )}
        </Stack>
      </Stack>
      <Stack flex="1">
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={2}
          mt="24px"
        >
          <OptionButtons
            watchTitle={watchTitle}
            setTemplateCreatorDialog={setTemplateCreatorDialog}
            setPreview={setPreview}
          />
        </Stack>
        <Stack>
          <TextEditor form={form} editorRef={editorRef} />
        </Stack>
      </Stack>
      {templateCreatorDialog && (
        <SimpleTemplateCreationDialog
          showDialog={templateCreatorDialog}
          closeDialog={() => setTemplateCreatorDialog(false)}
          template={{
            title: form.getValues('title'),
            myEditor: form.getValues('myEditor'),
            category: form.getValues('categories').category,
            subcategory: form.getValues('categories').subcategory,
          }}
        />
      )}
      {preview && (
        <DocumentPreviewDialog
          open={preview}
          setOpen={setPreview}
          createHtml={createHtml}
        />
      )}

      <TemplatesListContainerDialog />
    </Stack>
  );
};
