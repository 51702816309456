import { resetAddDocumentsOptionsDialog } from './AddDocumentOptionsDialog/AddDocumentOptionsDialog.vars';
import { resetCompletePersonalInformationDialog } from './CompletePersonalInformationDialog/CompletePersonalInformationDialog.vars';
import { resetDocumentCreatorDialog } from './DocumentCreatorDialog/DocumentCreatorDialog.vars';
import { resetDocumentSignDialog } from './DocumentSignDialog/DocumentSignDialog.vars';
import { resetElectronicSignatureCreationDialog } from './ElectronicSignatureCreationDialog/ElectronicSignatureCreationDialog.vars';
import { resetElectronicSignatureWorkflowDialog } from './EletronicSignatureWorkflowDialog/ElectronicSignatureWorkflowDialog.vars';
import { resetFileViewerDialog } from './FileViewerDialog/FileViewerDialog.vars';
import { resetIdentityVerificationDialog } from './IdentityVerificationDialog/IdentityVerificationDialog.vars';
import { resetRequestedEmployeeDocumentsDialog } from './RequestedEmployeeDocumentsDialog/RequestedEmployeeDocumentsDialog.vars';
import { resetRevokeElectronicSignatureDialog } from './RevokeElectronicSignatureDialog/RevokeElectronicSignatureDialog.vars';
import { resetSendDocumentsOptionsDialog } from './SendDocumentsOptionsDialog/SendDocumentsOptionsDialog.vars';
import { resetSignOnboardingDocumentsDialog } from './SignOnboardingDocumentsDialog/SignOnboardingDocumentsDialog.vars';
import { resetSingleDocumentConfigurationUploadDialog } from './SingleDocumentConfigurationUploadDialog/SingleDocumentConfigurationUploadDialog.vars';
import { resetSingleDocumentUploadDialog } from './SingleDocumentUploadDialog/SingleDocumentUploadDialog.vars';
import { resetUploadDocumentsDialog } from './UploadDocumentsDialog/UploadDocumentsDialog.vars';
import { resetUserOnboardingDialog } from './UserOnboardingDialog/UserOnboardingDialog.vars';
import { resetWebcamDialog } from './WebcamDialog/WebcamDialog.vars';
import { resetLogoutDialog } from './LogoutDialog/LogoutDialog.vars';

export const resetAllDialogVars = () => {
  resetAddDocumentsOptionsDialog();
  resetCompletePersonalInformationDialog();
  resetDocumentCreatorDialog();
  resetDocumentSignDialog();
  resetElectronicSignatureCreationDialog();
  resetElectronicSignatureWorkflowDialog();
  resetFileViewerDialog();
  resetIdentityVerificationDialog();
  resetRequestedEmployeeDocumentsDialog();
  resetRevokeElectronicSignatureDialog();
  resetSendDocumentsOptionsDialog();
  resetSignOnboardingDocumentsDialog();
  resetSingleDocumentConfigurationUploadDialog();
  resetSingleDocumentUploadDialog();
  resetUploadDocumentsDialog();
  resetUserOnboardingDialog();
  resetWebcamDialog();
  resetLogoutDialog();
};
