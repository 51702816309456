// @ts-check
import React from 'react';
import { Box, LinearProgress as MuiLinearProgress } from '@mui/material';
import { Typography } from '../Typography';

/**
 * @typedef {import('@mui/material/LinearProgress').LinearProgressProps} LinearProgressProps
 */

/**
 * @type {React.FC<LinearProgressProps & {hideCaption?: boolean}>}
 */
export const LinearProgress = ({ hideCaption, ...props }) => {
  const isValidValue =
    props.value !== null && props.value !== undefined && !isNaN(props.value);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <MuiLinearProgress {...props} />
      </Box>
      {!hideCaption && props.variant === 'determinate' && isValidValue && (
        <Box
          sx={{
            minWidth: 35,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption">{`${Math.round(
            props.value ?? 0,
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};
