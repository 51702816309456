import { gql } from '@apollo/client';

export const CREATE_OFFICIAL_HOLIDAY = gql`
  mutation createOfficialHoliday($input: CreateHolidayInput!) {
    createOfficialHoliday(input: $input) {
      __typename
    }
  }
`;

export const UPDATE_OFFICIAL_HOLIDAY = gql`
  mutation updateOfficialHoliday($input: UpdateHolidayInput!) {
    updateOfficialHoliday(input: $input) {
      __typename
    }
  }
`;

export const DELETE_OFFICIAL_HOLIDAY = gql`
  mutation deleteOfficialHoliday($input: DeleteHolidayInput!) {
    deleteOfficialHoliday(input: $input) {
      __typename
    }
  }
`;

export const GET_OFFICIAL_HOLIDAYS = gql`
  query getOfficialHolidays($input: GetCompanyHolidaysInput!) {
    getOfficialHolidays(input: $input) {
      __typename
      ... on HolidaysResult {
        holidays {
          _id
          date
          title
          type
          displayTitle
          creationType
          color
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
