import { makeVar } from '@apollo/client';

type RevokeElectronicSignatureDialogVar = {
  open: boolean;
  stepNumberLabel: string;
  onElectronicSignatureRevoked: () => void;
  onClose?: () => void;
};

const revokeElectronicSignatureDialogDefault: RevokeElectronicSignatureDialogVar =
  {
    open: false,
    stepNumberLabel: '',
    onElectronicSignatureRevoked: () => {},
    onClose: () => {},
  };

export const revokeElectronicSignatureDialogVar = makeVar(
  revokeElectronicSignatureDialogDefault,
);

export const resetRevokeElectronicSignatureDialog = () => {
  revokeElectronicSignatureDialogVar(revokeElectronicSignatureDialogDefault);
};

export const setRevokeElectronicSignatureDialogVar = (
  input: Partial<RevokeElectronicSignatureDialogVar>,
) => {
  revokeElectronicSignatureDialogVar({
    ...revokeElectronicSignatureDialogVar(),
    ...input,
  });
};
