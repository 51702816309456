import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { PrimaryButton } from '../../../../../components/Buttons/Buttons';
import { SoraGrid } from '../../../../../components/Datagrid/Datagrid';
import TooltipBlock from '../../../../../components/Tooltip/TooltipBlock';
import { columns } from './DefaultRoles.constants';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  permissionsListVar,
  userPermissionsVar,
} from '../../../../../cache.reactiveVars';
import { useBlockableActions } from '../../../../../components/ModuleBlockableComponent';
import { SORA_MODULES } from '../../../../../components/ModuleBlockableComponent';
import { useCustomTheme } from '../../../../../Hooks/useCustomTheme';

export const ListPermissions = ({
  rows = [],
  typeRoles,
  allRoles,
  savePermissions,
  loading,
  expandHeight,
}) => {
  const [allRows, setAllRows] = useState(rows);
  const userPermissions = useReactiveVar(userPermissionsVar);
  const theme = useCustomTheme();
  const palette = theme.customPalette;
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const {
    onClick: onClickBlocked,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  useEffect(() => {
    setAllRows(rows);
  }, [rows]);

  const handleChange = (rowToModify, e) => {
    if (
      ['documents', 'templates'].includes(rowToModify.key) &&
      !isDocumentsModuleAvailable
    ) {
      onClickBlocked();
      return;
    }
    const { name, checked } = e.target;
    const { fullKey, key } = rowToModify;
    const copiedObject = _.cloneDeep(allRoles);
    const modifiedRows = [...allRows];
    //THIS PART IS TO SAVE OBJECT WITH INFO TO SEND BACK
    //fullKey = roles; superAdmin, adminRh, adminWC
    //key = permissions group
    if (typeRoles === 'superAdmin') {
      const existPermissions = _.cloneDeep(
        copiedObject.roles?.superAdmin?.permissions,
      );

      let permissions = permissionsListVar()?.superAdmin?.[fullKey]?.[key]
        ? _.cloneDeep(permissionsListVar()?.superAdmin)
        : existPermissions
          ? existPermissions
          : _.cloneDeep(copiedObject.roles?.superAdmin);

      permissions[fullKey][key][name] = checked;
      if (name === 'read' && !checked) {
        const value = permissions[fullKey][key].update === null ? null : false;
        permissions[fullKey][key].update = value;
      }

      const checkboxRead = permissions[fullKey][key].read;

      if (name === 'update' && checked) {
        const value = checkboxRead === null ? null : true;
        permissions[fullKey][key].read = value;
      }
      permissionsListVar({ ...permissionsListVar(), superAdmin: permissions });
    }

    if (typeRoles === 'adminRh') {
      const existPermissions = _.cloneDeep(
        copiedObject.roles?.adminRh?.permissions,
      );

      let permissions = permissionsListVar()?.adminRh?.[fullKey]?.[key]
        ? _.cloneDeep(permissionsListVar()?.adminRh)
        : existPermissions
          ? existPermissions
          : _.cloneDeep(copiedObject.roles?.adminRh);

      permissions[fullKey][key][name] = checked;
      if (name === 'read' && !checked) {
        const value = permissions[fullKey][key].update === null ? null : false;
        permissions[fullKey][key].update = value;
      }

      const checkboxRead = permissions[fullKey][key].read;
      if (name === 'update' && checked) {
        const value = checkboxRead === null ? null : true;
        permissions[fullKey][key].read = value;
      }
      permissionsListVar({ ...permissionsListVar(), adminRh: permissions });
    }

    if (typeRoles === 'adminWC') {
      const existPermissions = _.cloneDeep(
        copiedObject.roles?.adminWC?.permissions,
      );
      let permissions = permissionsListVar()?.adminWC?.[fullKey]?.[key]
        ? _.cloneDeep(permissionsListVar()?.adminWC)
        : existPermissions
          ? existPermissions
          : _.cloneDeep(copiedObject.roles?.adminWC);

      permissions[fullKey][key][name] = checked;
      if (name === 'read' && !checked) {
        const value = permissions[fullKey][key].update === null ? null : false;
        permissions[fullKey][key].update = value;
      }

      const checkboxRead = permissions[fullKey][key].read;
      if (name === 'update' && checked) {
        const value = checkboxRead === null ? null : true;
        permissions[fullKey][key].read = value;
      }
      permissionsListVar({ ...permissionsListVar(), adminWC: permissions });
    }

    //THIS MODIFY ROWS (JUST CHECKBOXS) IN DATA GRID TABLE
    const indexToModify = modifiedRows.findIndex(
      (row) => row.key === rowToModify.key,
    );
    if (name === 'update') {
      modifiedRows[indexToModify].allCheckboxs.editCheckbox.checked = checked;
      if (checked) {
        modifiedRows[indexToModify].allCheckboxs.readCheckbox.checked = true;
      }
    }

    if (name === 'read') {
      modifiedRows[indexToModify].allCheckboxs.readCheckbox.checked = checked;
      if (checked === false) {
        modifiedRows[indexToModify].allCheckboxs.editCheckbox.checked = false;
      }
    }
    setAllRows(modifiedRows);
  };

  const modifiedColumns = columns(handleChange, palette);

  return (
    <>
      <Stack pt={2} gap={2} height={md ? '100%' : 'auto'}>
        <Box display="flex" width="100%" height="90%">
          <Box width="100%">
            <SoraGrid
              hideConfig
              rows={allRows}
              columns={modifiedColumns}
              disableSelectionOnClick
              checkboxSelection={false}
              disableColumnFilter
              disableColumnMenu
              loading={loading}
              disableColumnSelector
              hideFooter
              headerColor="#F4F6F8"
              headerTextColor={palette.status.inactive_gray}
              headerHeight={48}
              rowHeight={48}
              getRowId={(row) => JSON.stringify(row)}
              headerAlign="center"
              cellPadding="0px"
              height="800px"
              textTransform="none"
              datagridSx={{
                '& .MuiDataGrid-virtualScroller	': {
                  overflowX: { md: 'hidden' },
                },
              }}
            />
          </Box>
        </Box>
      </Stack>
      {typeof savePermissions === 'function' && (
        <Box
          sx={md ? { position: 'fixed', bottom: 0, right: 0, p: 3 } : { py: 4 }}
          display="flex"
          justifyContent="flex-end"
        >
          {userPermissions && !userPermissions.roles?.update ? (
            <TooltipBlock placement="left">
              <span>
                <PrimaryButton
                  disabled
                  type="submit"
                  startIcon={<Icon icon="save_3_line" />}
                >
                  Guardar cambios
                </PrimaryButton>
              </span>
            </TooltipBlock>
          ) : (
            <PrimaryButton
              onClick={savePermissions}
              type="submit"
              startIcon={<Icon icon="save_3_line" />}
            >
              Guardar cambios
            </PrimaryButton>
          )}
        </Box>
      )}
    </>
  );
};
