import { Stack } from '@mui/material';
import { Tooltip, AvatarGroup, IconButton } from '../../newComponents';
import { Icon } from '../../components/Icons/Icons';
import { EmployeeAvatar } from '../../layoutComponents/EmployeeAvatar/EmployeeAvatar';

/**
 * @param {import('./SignersAvatarGroup.types').SignersAvatarGroupProps} props
 * @returns {React.JSX.Element}
 */
export function SignersAvatarGroup({
  assignedTo = [],
  orderedSigning,
  handleOpenAddSigners,
  handleOpenSignersPopover,
  buttonSize = 'medium',
  avatarSize = 'small',
}) {
  if (assignedTo?.length) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap="8px"
      >
        {assignedTo.some((user) => user.signerType === 'companySigners') && (
          <>
            {orderedSigning ? (
              <Tooltip title="Firmado por orden">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    icon="list_ordered"
                    onClick={
                      handleOpenSignersPopover ? handleOpenSignersPopover : null
                    }
                    size={buttonSize}
                    disabled={!handleOpenSignersPopover}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Firmado aleatorio">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    icon="shuffle_line"
                    onClick={
                      handleOpenSignersPopover ? handleOpenSignersPopover : null
                    }
                    size={buttonSize}
                    disabled={!handleOpenSignersPopover}
                  />
                </span>
              </Tooltip>
            )}
          </>
        )}
        <AvatarGroup
          total={assignedTo?.length}
          sx={{
            justifyContent: 'flex-end',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {assignedTo.map((user, i) => {
            return (
              <EmployeeAvatar
                key={i}
                profilePicture={user.profilePicture}
                name={user.fullName}
                color="primary"
                showBadge={user.signerType === 'companySigners'}
                size={avatarSize}
              />
            );
          })}
        </AvatarGroup>
      </Stack>
    );
  }

  return handleOpenAddSigners ? (
    <Icon
      icon="add_line"
      height="20px"
      onClick={() => {
        handleOpenAddSigners();
      }}
    />
  ) : null;
}
