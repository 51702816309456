import React from 'react';
import {
  Avatar,
  Tooltip,
  Chip,
  IconButton,
} from '../../../../../../../../newComponents';
import { Icon } from '../../../../../../../../components/Icons/Icons';
import { Stack, Typography } from '@mui/material';
import { RenderExpandedText } from '../../../../../../../../components/ExpandedText/RenderExpandedText';
import { CustomAvatarSigners } from '../../../../../../../RHPod/Documents/PdfDocuments/Signers/signers.constants';
import { SignersTableColumn } from './TableComponent';

const avatarColorsByType = {
  WorkCenter: 'success',
  WorkTitle: 'error',
  Group: 'warning',
};

export const COLUMNS_TABLE_SIGNERS: SignersTableColumn[] = [
  {
    id: 'name',
    label: 'Nombre',
    align: 'left',
    disabledPadding: true,
    style: {
      '&.MuiTableCell-root': {
        border: 'none',
        pl: 0.5,
      },
    },
    render: ({ row, palette }) => {
      const AvatarValue = () => {
        if (row.success === false) {
          return (
            <Avatar
              color="error"
              sx={{
                '&.MuiAvatar-colorError': {
                  background: palette.error.transparent24,
                  color: palette.error.main,
                },
              }}
            >
              <Icon
                icon="error_warning_line"
                height="20px"
                fill={palette.error.main}
              />
            </Avatar>
          );
        }

        return (
          <CustomAvatarSigners
            name={row.fullName}
            type={row.type}
            profilePicture={row.profilePicture}
            color={avatarColorsByType[row.type] ?? 'primary'}
            isAutocomplete={false}
            selectedItems={[]}
            id=""
          />
        );
      };

      const CustomText = () => {
        if (row.success === false) {
          return (
            <>
              <Typography variant="subtitle2">{row.user}</Typography>
              <Typography variant="body2" color={palette.error.main}>
                {row.message}
              </Typography>
            </>
          );
        }

        if (row?.type === 'ALL_EMPLOYEES') {
          return (
            <>
              <Typography variant="subtitle2">{row.fullName}</Typography>
              <Typography variant="body2" color={palette.text.secondary}>
                Todos los empleados serán asignados a este grupo de
                autorizadores
              </Typography>
            </>
          );
        }

        if (row?.type === 'WorkCenter') {
          return (
            <Stack justifyContent="center" width="200px">
              <RenderExpandedText text={row.name || 'Sin nombre de CT'} arrow>
                <Typography variant="subtitle2" component="span">
                  {row.name || 'Sin nombre de CT'}
                </Typography>
              </RenderExpandedText>
              <Stack
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                mt="2px"
              >
                <Typography variant="body2" color={palette.text.secondary}>
                  Centro de trabajo
                </Typography>
              </Stack>
            </Stack>
          );
        }

        if (['WorkTitle', 'Group'].includes(row?.type)) {
          return (
            <Stack justifyContent="center" width="200px">
              <RenderExpandedText text={row.name || 'Sin nombre'} arrow>
                <Typography variant="subtitle2" component="span">
                  {row.name || 'Sin nombre'}
                </Typography>
              </RenderExpandedText>
              <Stack
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                mt="2px"
              >
                <Typography variant="body2" color={palette.text.secondary}>
                  {row?.type === 'WorkTitle' ? 'Puesto de trabajo' : 'Grupo'}
                </Typography>
              </Stack>
            </Stack>
          );
        }

        return (
          <Stack width="250px ">
            <Typography variant="subtitle2">
              {row.fullName || row.name || 'Sin nombre'}
            </Typography>

            <RenderExpandedText
              arrow
              tooltipMaxWidth="10px"
              text={
                row.type === 'Employee'
                  ? row.workCenterName
                  : 'Firmante de empresa'
              }
            >
              <Typography
                variant="body2"
                color={palette.text.secondary}
                component="span"
              >
                {row.type === 'Employee' && row.workCenterName}
              </Typography>
            </RenderExpandedText>

            <Typography
              variant="body2"
              color={palette.text.secondary}
            ></Typography>
          </Stack>
        );
      };

      return (
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <AvatarValue />
          <Stack flexWrap="wrap" width="100%" whiteSpace="normal">
            <CustomText />
          </Stack>
        </Stack>
      );
    },
  },
  {
    id: 'delete',
    label: '',
    align: 'right',
    disabledPadding: true,
    style: {
      '&.MuiTableCell-root': {
        border: 'none',
      },
    },
    render: ({ row, deleteRow }) => {
      const totalEmployees = row?.employeeCount || 0;

      return (
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          <Stack>
            {row?.type === 'WorkCenter' && (
              <Tooltip title="Firmantes" arrow>
                <div>
                  <Chip
                    variant="soft"
                    color="primary"
                    label={`${totalEmployees}`}
                    size="small"
                    style={{ minWidth: '30px', height: '20px' }}
                  />
                </div>
              </Tooltip>
            )}
          </Stack>
          <Stack>
            <IconButton
              icon="delete_bin_line"
              size="small"
              color="error"
              className={'delete_button'}
              onClick={() => deleteRow('single', row)}
            />
          </Stack>
        </Stack>
      );
    },
  },
];
