import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '../../../../../newComponents';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { useUpdateCompanySignerNotificationsConfig } from '../../Hooks/useUpdateCompanySignerNotificationsConfig';
import { NotificationsConfig } from '../../ProfileDialog.types';

export const ProfileUserNotificationsContainer = ({
  data,
  fetchNotificationsConfig,
}: {
  data: NotificationsConfig;
  fetchNotificationsConfig: () => void;
}) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      groupCompanySignerNotifications: false,
      disableCompanySignerNotifications: false,
    },
  });

  const { updateCompanySignerNotificationsConfig } =
    useUpdateCompanySignerNotificationsConfig();

  const updateNotificationsUserConfig = async (data: any) => {
    globalBackdropVar({ open: true });

    const input = {
      groupCompanySignerNotifications: {
        active: data.groupCompanySignerNotifications,
      },
      disableCompanySignerNotifications: {
        active: data.disableCompanySignerNotifications,
      },
    };
    const result = await updateCompanySignerNotificationsConfig(input);
    if (result) fetchNotificationsConfig();
    globalBackdropVar({ open: false });
    if (result)
      globalSnackbarVar({
        show: true,
        message: 'Configuración actualizada',
        severity: 'success',
      });
    else {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al actualizar la configuración',
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        groupCompanySignerNotifications:
          data.groupCompanySignerNotifications.active,
        disableCompanySignerNotifications:
          data.disableCompanySignerNotifications.active,
      });
    }
  }, [data, reset]);

  return (
    <>
      <Stack
        width="100%"
        alignItems="left"
        justifyContent="center"
        marginBottom={3}
        position="relative"
      >
        <Typography variant="h6">Configura tus notificaciones</Typography>
      </Stack>
      <form onSubmit={handleSubmit(updateNotificationsUserConfig)}>
        <Stack
          spacing={2}
          width="100%"
          alignItems="left"
          justifyContent="center"
        >
          <Stack>
            <Typography variant="subtitle1">Firmante de empresa</Typography>
            <Controller
              name="groupCompanySignerNotifications"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <FormControlLabel
                      value={value}
                      control={
                        <Switch
                          checked={value}
                          value={value}
                          onChange={onChange}
                          color="primary"
                        />
                      }
                      label={`Agrupar notificaciones "Te han enviado un documento"`}
                      labelPlacement="end"
                    />
                    <Typography variant="caption" color="grey">
                      Al activar esta opción recibirás notificaciones agrupadas
                      por cada empresa que te haya enviado documentos para
                      firmar durante el dia.
                    </Typography>
                  </>
                );
              }}
            />
          </Stack>
          <Stack>
            <Controller
              name="disableCompanySignerNotifications"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <FormControlLabel
                      value={value}
                      control={
                        <Switch
                          checked={value}
                          value={value}
                          onChange={onChange}
                          color="primary"
                        />
                      }
                      label={`Apagar notificaciones "Te han enviado un documento"`}
                      labelPlacement="end"
                    />
                    <Typography variant="caption" color="grey">
                      Al activar esta opción no te llegará ninguna notificación
                      cuando tengas un documento nuevo por firmar.
                    </Typography>
                  </>
                );
              }}
            />
          </Stack>

          <Stack bottom={30} right={30} position="absolute">
            <Button
              color="primary"
              variant="contained"
              disabled={!isDirty}
              type="submit"
            >
              Actualizar
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
};
