import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { GET_ADMIN_USERS_FROM_COMPANY } from '../../../../MyCompany/Company/company.gql';
import { VirtualizedAutocompleteMemo } from '../__common/VirtualizedAutocomplete';
import { CompSignersSearchProps, Signer } from './CompSignersSearch.types';

export const CompSignersSearch: React.FC<CompSignersSearchProps> = ({
  handleAddSigner,
  selectedItems = [],
  disabled = false,
  onClose,
  helperText = '',
  placeholder = 'Buscar firmantes de empresa',
  noOptionsText = 'No se encontraron firmantes de empresa...',
  loadingText = 'Cargando firmantes de empresa...',
  searchPermission = 'signDocuments',
  origin = 'companySigners',
}) => {
  const [searchCompAdmin, setSearchCompAdmins] = useState('');
  const [compAdmins, setCompAdmins] = useState<{
    compAdmins: Signer[];
    total: number;
  }>({ compAdmins: [], total: 0 });
  // eslint-disable-next-line no-undef
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const [allCompSignersQuery] = useLazyQuery(GET_ADMIN_USERS_FROM_COMPANY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      adminTypes: ['masterAdmin', 'wcAdmin', 'rhAdmin'],
      searchPermission: searchPermission,
    },
    onCompleted: (response) => {
      if (response?.getCompanyAdmins?.length) {
        setCompAdmins({
          compAdmins: response?.getCompanyAdmins ?? [],
          total: response?.getCompanyAdmins?.length || 0,
        });
      }
    },
  });

  useEffect(() => {
    allCompSignersQuery();
  }, [allCompSignersQuery]);

  const onSelectSigner = (signer, type) => {
    handleAddSigner(signer, type);
  };

  const onChangeInput = (value, origin, event) => {
    if (value?.includes(',')) return;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setSearchCompAdmins(value);
    }, 700);

    //@ts-ignore
    setTimeoutId(newTimeoutId);
  };

  const filteredOptions = useMemo(() => {
    const filteredOptions = (compAdmins?.compAdmins || []).filter(
      (admin) =>
        admin?.fullName
          ?.toLowerCase()
          .includes(searchCompAdmin.toLowerCase()) ||
        admin?.email?.toLowerCase().includes(searchCompAdmin.toLowerCase()) ||
        admin?.rfc?.toLowerCase().includes(searchCompAdmin.toLowerCase()),
    );
    return filteredOptions;
  }, [compAdmins?.compAdmins, searchCompAdmin]);

  return (
    <VirtualizedAutocompleteMemo
      onClose={onClose}
      options={filteredOptions}
      total={filteredOptions.length}
      handleLoadMore={() => null}
      onPaste={() => null}
      onChange={onChangeInput}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      placeholder={placeholder}
      handleSelect={onSelectSigner}
      origin={origin}
      selectedItems={selectedItems}
      disabled={disabled}
      helperText={helperText}
    />
  );
};
