import React, { Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { useTheme } from '@mui/material/styles';

type SoraCalendarYearNavigationProps = {
  currentYear: number;
  setCurrentYear: Dispatch<SetStateAction<number>>;
};

export const SoraCalendarYearNavigation: React.FC<
  SoraCalendarYearNavigationProps
> = ({ currentYear, setCurrentYear }) => {
  const theme = useTheme();
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={2}
        sx={{
          borderBottom: `1px solid ${theme.newPalette.grey.grey300}`,
        }}
      >
        <Button
          color={'default'}
          onClick={() => setCurrentYear((prev) => prev - 1)}
        >
          <Icon icon={'arrow_left_s_line'} />
        </Button>
        <Typography>{currentYear}</Typography>
        <Button
          color={'default'}
          onClick={() => setCurrentYear((prev) => prev + 1)}
        >
          <Icon icon={'arrow_right_s_line'} />
        </Button>
      </Stack>
    </>
  );
};
