import { UseFormReturn } from 'react-hook-form';
import { Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { EmployeesFinder } from '../../../../../../../businessComponents/EmployeesFinder';
import {
  DeleteActions,
  RowBaseItem,
  TableComponent,
} from '../AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { useTheme } from '@mui/material/styles';
import {
  AutocompleteOption,
  Employee,
} from '../../../../../../../businessComponents/EmployeesFinder/EmployeesFinder';
import { COLUMNS_TABLE_SIGNERS } from '../AddAuthorizersDialog/setEmployeesSlide/TableColumns';
import { uuidv4 } from '../../../../../../../utils/utils';
import {
  Checkbox,
  FormControlLabel,
  ConfirmDialog,
} from '../../../../../../../newComponents';
import { SignersTabs } from '../../../../../../RHPod/Documents/PdfDocuments/Signers/SignersTabs';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DELETE_AUTHORIZERS_TEAM_EMPLOYEES,
  GET_EMPLOYEES_BY_AUTHORIZERS_TEAM,
} from '../../../Holidays.gql';
import { UpdateAuthorizersTeamDialogFormType } from './UpdateAuthorizersTeamDialog';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../../cache.reactiveVars';

type Props = {
  form: UseFormReturn<UpdateAuthorizersTeamDialogFormType>;
};
export const UATDEmployeesForm: React.FC<Props> = ({ form }) => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isOpenDeleteDialog, setIsOpenHolidayDialog] = useState(false);
  const deleteItemRef = useRef<null | RowBaseItem[]>(null);
  const palette = theme.newPalette;
  const { watch } = form;
  const preselectedTeam = watch('team');

  const [getEmployeesByAuthorizersTeam] = useLazyQuery(
    GET_EMPLOYEES_BY_AUTHORIZERS_TEAM,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const employees = data?.getEmployeesByAuthorizersTeam?.employees || [];
        const totalLoaded = data?.getEmployeesByAuthorizersTeam?.total || 0;
        setTotal(totalLoaded);
        const employeesAsRows = employees.map((employee) => ({
          ...employee,
          fullName: employee.name,
          type: 'Employee',
          alreadyAssigned: true,
        }));
        setRows(employeesAsRows);
        form.setValue('preloadedEmployees', employeesAsRows);
      },
    },
  );

  const [deleteAuthorizersTeamEmployees] = useMutation(
    DELETE_AUTHORIZERS_TEAM_EMPLOYEES,
  );

  const [rows, setRows] = React.useState<RowBaseItem[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<RowBaseItem[]>([]);
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);
  const [sendAllEmployees, setSendAllEmployees] = React.useState(false);

  const [errorRows, setErrorRows] = useState([]);
  const [tableTab, setTableTab] = useState<'success' | 'error'>('success');

  const modifiedColumns = COLUMNS_TABLE_SIGNERS.map((column) => ({
    ...column,
    // defaultSelectedEmployee,
  }));

  const addRow = (row: AutocompleteOption) => {
    const rowsWithoutAllEmployees = rows.filter(
      (row) => row.type !== 'ALL_EMPLOYEES',
    );
    setSendAllEmployees(false);
    setRows([...rowsWithoutAllEmployees, row]);
  };

  const handleCheckAllEmployees = (checked: boolean) => {
    setIsCheckedAll(checked);
    if (checked) {
      setSelectedRows(tableTab === 'success' ? rows : errorRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleChangeSelectAllEmployees = () => {
    setSendAllEmployees(!sendAllEmployees);
    if (sendAllEmployees) {
      setRows([]);
    } else {
      setRows([
        {
          _id: uuidv4(),
          fullName: 'Todos los empleados',
          profilePicture: null,
          workCenterName: '',
          type: 'ALL_EMPLOYEES',
          employeeCount: 99, // todo provide this value with total company employees
        },
      ]);
    }
  };

  const deleteRows = (action: DeleteActions, row: RowBaseItem) => {
    if (tableTab === 'success') {
      if (action === 'massive') {
        deleteItemRef.current = selectedRows;
      } else {
        deleteItemRef.current = [row];
      }
      setIsOpenHolidayDialog(true);
    } else {
      if (action === 'massive') {
        const selectedRowsIds = selectedRows.map((r) => r.user);
        setErrorRows(
          errorRows.filter((r) => !selectedRowsIds.includes(r.user)),
        );
        setSelectedRows([]);
      } else {
        setErrorRows(errorRows.filter((r) => r.user !== row.user));
      }
    }
  };

  const deleteEmployeesInBackEnd = async () => {
    const items = deleteItemRef.current;
    try {
      globalBackdropVar({
        clickable: false,
        open: true,
        text: 'Eliminando empleados...',
      });
      const { data } = await deleteAuthorizersTeamEmployees({
        variables: {
          input: {
            authorizersTeamId: preselectedTeam._id,
            employeesIds: items.map((item) => item._id),
          },
        },
      });
      if (data?.deleteAuthorizersTeamEmployees?.__typename !== 'Success') {
        globalSnackbarVar({
          show: true,
          message:
            data?.deleteAuthorizersTeamEmployees?.message ||
            'Hubo un error al eliminar el empleado. Contacte a Sora',
          severity: 'error',
        });
      }
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Hubo un error al eliminar el empleado. Contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
    // removing from rows
    setRows(rows.filter((r) => !items.includes(r)));
    setSelectedRows(selectedRows.filter((r) => !items.includes(r)));
  };

  const handleOnSelectedRowsOnPaste = (foundRows) => {
    const uniqueRows = foundRows
      .filter((employee) => {
        return !rows.some((prevEmployee) => prevEmployee._id === employee._id);
      })
      .map((row) => ({ ...row, type: 'Employee' })); // this because the row is coming from the paste
    setRows((prev) => prev.concat(uniqueRows));
  };

  const handlePasterErrors = (errors) => {
    const uniqueErrors = errors.filter((employee) => {
      return !errorRows.some(
        (prevEmployee) => prevEmployee.user === employee.user,
      );
    });
    setErrorRows(uniqueErrors);
  };

  useEffect(() => {
    form.setValue(
      'assignedTo',
      rows.filter((row) => !row.alreadyAssigned),
    );
  }, [form, rows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [tableTab]);

  useEffect(() => {
    if (errorRows.length === 0) {
      setTableTab('success');
    }
    form.setValue('assignedToErrors', errorRows);
  }, [errorRows, form]);

  useEffect(() => {
    if (preselectedTeam) {
      getEmployeesByAuthorizersTeam({
        variables: { input: { authorizersTeamId: preselectedTeam._id, page } },
      });
    }
  }, [getEmployeesByAuthorizersTeam, preselectedTeam, page]);

  const handleLoadMore = () => {
    const employeesLength = rows.length;
    if (employeesLength < total) {
      setPage(page + 1);
      getEmployeesByAuthorizersTeam({
        variables: { input: { authorizersTeamId: preselectedTeam._id, page } },
      });
    }
  };

  return (
    <Stack mt={2} gap={2}>
      <EmployeesFinder
        findBy={['EMPLOYEES', 'WORKCENTERS', 'WORKTITLES', 'GROUPS']}
        onSelectRow={(row) => {
          addRow(row);
        }}
        selectedRows={rows}
        isEmployeeSelected={(employee: Employee) =>
          rows.some((row) => row._id === employee._id)
        }
        onSelectedRowsOnPaste={handleOnSelectedRowsOnPaste}
        handlePasterErrors={handlePasterErrors}
      />
      <Stack justifyContent={'center'} alignItems={'center'}>
        <FormControlLabel
          value="selectAllEmployees"
          control={<Checkbox color="primary" checked={sendAllEmployees} />}
          label="Asignar a todos los empleados"
          labelPlacement="end"
          onChange={handleChangeSelectAllEmployees}
          style={{ marginLeft: 0, marginTop: 10 }}
        />
      </Stack>
      <SignersTabs
        signersList={rows}
        signersError={errorRows}
        tab={tableTab}
        handleChange={(e, v) => setTableTab(v)}
      />

      <TableComponent
        width="100%"
        height={300}
        headerBgColor={palette.grey.transparent24}
        headerTextColor={palette.text.secondary}
        rows={tableTab === 'success' ? rows : errorRows}
        selectedRows={selectedRows}
        columns={modifiedColumns}
        handleSelectAllCheckbox={handleCheckAllEmployees}
        handleCheckboxChange={(checked, row) => {
          if (checked) {
            setSelectedRows([...selectedRows, row]);
          } else {
            setSelectedRows(
              selectedRows.filter((selectedRow) => selectedRow !== row),
            );
          }
        }}
        areAllChecked={isCheckedAll}
        handleLoadMore={handleLoadMore}
        loadedRowCount={rows.length}
        totalRowsCount={total}
        deleteRows={deleteRows}
      />
      <ConfirmDialog
        title={'Eliminar empleados de este equipo de autorizadores'}
        open={isOpenDeleteDialog}
        icon={'delete_bin_line'}
        confirmLabel={'Eliminar'}
        ConfirmButtonProps={{
          color: 'error',
        }}
        cancelLabel={'Cancelar'}
        onClose={() => setIsOpenHolidayDialog(false)}
        onConfirm={async () => {
          await deleteEmployeesInBackEnd();
        }}
        onCancel={() => setIsOpenHolidayDialog(false)}
      />
    </Stack>
  );
};
