import { UseFormReturn } from 'react-hook-form';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EmployeesFinder } from '../../../../../../../businessComponents/EmployeesFinder';
import {
  DeleteActions,
  RowBaseItem,
  TableComponent,
} from './setEmployeesSlide/TableComponent';
import { useTheme } from '@mui/material/styles';
import {
  AutocompleteOption,
  Employee,
} from '../../../../../../../businessComponents/EmployeesFinder/EmployeesFinder';
import { COLUMNS_TABLE_SIGNERS } from './setEmployeesSlide/TableColumns';
import { uuidv4 } from '../../../../../../../utils/utils';
import { Checkbox, FormControlLabel } from '../../../../../../../newComponents';
import { SignersTabs } from '../../../../../../RHPod/Documents/PdfDocuments/Signers/SignersTabs';
import { CreateHolidayAuthorizationTeamDialogFormType } from './CreateHolidayAuthorizationTeamDialog';
import { useLazyQuery } from '@apollo/client';
import { GET_EMPLOYEES_BY_AUTHORIZERS_TEAM } from '../../../Holidays.gql';
import { globalSnackbarVar } from '../../../../../../../cache.reactiveVars';

type Props = {
  form: UseFormReturn<CreateHolidayAuthorizationTeamDialogFormType>;
};
export const CHAGEmployeesForm: React.FC<Props> = ({ form }) => {
  const theme = useTheme();
  const palette = theme.newPalette;
  const { watch } = form;
  const preselectedTeam = watch('preselectedTeam');
  const authorizers = watch('authorizers');

  const [getEmployeesByAuthorizersTeam] = useLazyQuery(
    GET_EMPLOYEES_BY_AUTHORIZERS_TEAM,
    {
      onCompleted: (data) => {
        const employees = data?.getEmployeesByAuthorizersTeam?.employees || [];
        const employeesAsRows = employees.map((employee) => ({
          ...employee,
          fullName: employee.name,
          type: 'Employee',
        }));
        setRows(employeesAsRows);
      },
    },
  );

  const [rows, setRows] = React.useState<RowBaseItem[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<RowBaseItem[]>([]);
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);
  const [sendAllEmployees, setSendAllEmployees] = React.useState(false);

  const [errorRows, setErrorRows] = useState([]);
  const [tableTab, setTableTab] = useState<'success' | 'error'>('success');

  const modifiedColumns = COLUMNS_TABLE_SIGNERS.map((column) => ({
    ...column,
    // defaultSelectedEmployee,
  }));

  const addRow = (row: AutocompleteOption) => {
    const rowsWithoutAllEmployees = rows.filter(
      (row) => row.type !== 'ALL_EMPLOYEES',
    );
    setSendAllEmployees(false);
    if (row.type === 'Employee') {
      const employee = row as Employee;
      if (authorizers.some((authorizer) => authorizer._id === employee._id)) {
        return globalSnackbarVar({
          show: true,
          message: 'El empleado ya es un autorizador',
          severity: 'warning',
        });
      }
    }
    setRows([...rowsWithoutAllEmployees, row]);
  };

  const handleCheckAllEmployees = (checked: boolean) => {
    setIsCheckedAll(checked);
    if (checked) {
      setSelectedRows(tableTab === 'success' ? rows : errorRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleChangeSelectAllEmployees = () => {
    setSendAllEmployees(!sendAllEmployees);
    if (sendAllEmployees) {
      setRows([]);
    } else {
      setRows([
        {
          _id: uuidv4(),
          fullName: 'Todos los empleados',
          profilePicture: null,
          workCenterName: '',
          type: 'ALL_EMPLOYEES',
        },
      ]);
    }
  };

  const deleteRows = (action: DeleteActions, row: RowBaseItem) => {
    if (action === 'massive') {
      if (tableTab === 'success') {
        const selectedRowsIds = selectedRows.map((r) => r._id);
        setRows(rows.filter((r) => !selectedRowsIds.includes(r._id)));
      } else {
        const selectedRowsIds = selectedRows.map((r) => r.user);
        setErrorRows(
          errorRows.filter((r) => !selectedRowsIds.includes(r.user)),
        );
      }
      setSelectedRows([]);
    } else {
      if (tableTab === 'success') {
        setRows(rows.filter((r) => r._id !== row._id));
      } else {
        setErrorRows(errorRows.filter((r) => r.user !== row.user));
      }
    }
  };

  const handleOnSelectedRowsOnPaste = (foundRows) => {
    const uniqueRows = foundRows
      .filter((employee) => {
        return !rows.some((prevEmployee) => prevEmployee._id === employee._id);
      })
      .map((row) => ({ ...row, type: 'Employee' })); // this because the row is coming from the paste
    setRows((prev) => prev.concat(uniqueRows));
  };

  const handlePasterErrors = (errors) => {
    const uniqueErrors = errors.filter((employee) => {
      return !errorRows.some(
        (prevEmployee) => prevEmployee.user === employee.user,
      );
    });
    setErrorRows(uniqueErrors);
  };

  useEffect(() => {
    form.setValue('assignedTo', rows);
  }, [form, rows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [tableTab]);

  useEffect(() => {
    if (errorRows.length === 0) {
      setTableTab('success');
    }
    form.setValue('assignedToErrors', errorRows);
  }, [errorRows, form]);

  useEffect(() => {
    if (preselectedTeam) {
      getEmployeesByAuthorizersTeam({
        variables: { input: { authorizersTeamId: preselectedTeam._id } },
      });
    }
  }, [getEmployeesByAuthorizersTeam, preselectedTeam]);

  return (
    <Stack mt={2} gap={2}>
      <EmployeesFinder
        findBy={['EMPLOYEES', 'WORKCENTERS', 'WORKTITLES', 'GROUPS']}
        onSelectRow={(row) => {
          addRow(row);
        }}
        selectedRows={rows}
        isEmployeeSelected={(employee: Employee) =>
          rows.some((row) => row._id === employee._id)
        }
        onSelectedRowsOnPaste={handleOnSelectedRowsOnPaste}
        handlePasterErrors={handlePasterErrors}
      />
      <Stack justifyContent={'center'} alignItems={'center'}>
        <FormControlLabel
          value="selectAllEmployees"
          control={<Checkbox color="primary" checked={sendAllEmployees} />}
          label="Asignar a todos los empleados"
          labelPlacement="end"
          onChange={handleChangeSelectAllEmployees}
          style={{ marginLeft: 0, marginTop: 10 }}
        />
      </Stack>
      <SignersTabs
        signersList={rows}
        signersError={errorRows}
        tab={tableTab}
        handleChange={(e, v) => setTableTab(v)}
      />

      <TableComponent
        width="100%"
        height={300}
        headerBgColor={palette.grey.transparent24}
        headerTextColor={palette.text.secondary}
        rows={tableTab === 'success' ? rows : errorRows}
        selectedRows={selectedRows}
        columns={modifiedColumns}
        handleSelectAllCheckbox={handleCheckAllEmployees}
        handleCheckboxChange={(checked, row) => {
          if (checked) {
            setSelectedRows([...selectedRows, row]);
          } else {
            setSelectedRows(
              selectedRows.filter((selectedRow) => selectedRow !== row),
            );
          }
        }}
        areAllChecked={isCheckedAll}
        handleLoadMore={() => {}}
        loadedRowCount={0}
        deleteRows={deleteRows}
        totalRowsCount={tableTab === 'success' ? rows.length : errorRows.length}
      />
    </Stack>
  );
};
