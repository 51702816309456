import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  FormTriggerFieldsType,
  Steps,
} from '../AddAuthorizersDialog/CreateHolidayAuthorizationTeamDialog.types';
import { DialogActions, DialogContent } from '@mui/material';
import { Button } from '../../../../../../../newComponents';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../../cache.reactiveVars';
import { UpdateAuthorizersTeamDialogFormType } from './UpdateAuthorizersTeamDialog';
import { AuthorizersTeam } from '../DisplayAuthorizersTeams.types';
import { createHolidayFormErrorMessages } from '../AddAuthorizersDialog/CHAGForm';
import { UATDGeneralForm } from './UATDGeneralForm';
import { UATDAuthorizersForm } from './UATDAuthorizersForm';
import { UATDEmployeesForm } from './UATDEmployeesForm';
import {
  GET_HOLIDAY_AUTHORIZERS_TEAMS,
  UPDATE_AUTHORIZERS_TEAM,
} from '../../../Holidays.gql';
import { useMutation } from '@apollo/client';

const FormTriggerFields: FormTriggerFieldsType = {
  [Steps.General]: ['name', 'description'],
  [Steps.Authorizers]: ['authorizers'],
  [Steps.Employees]: ['name'],
};

type Props = {
  form: UseFormReturn<UpdateAuthorizersTeamDialogFormType>;
  step: Steps;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  onClose: () => void;
  preselectedTeam?: AuthorizersTeam;
};

export const UATDForm: React.FC<Props> = ({
  form,
  step,
  setStep,
  onClose,
  preselectedTeam,
}) => {
  const {
    handleSubmit,
    trigger,
    formState: { errors },
  } = form;
  const [updateAuthorizersTeam] = useMutation(UPDATE_AUTHORIZERS_TEAM, {
    refetchQueries: [
      {
        query: GET_HOLIDAY_AUTHORIZERS_TEAMS,
      },
    ],
  });
  const nextButtonLabel = 'Actualizar';
  const submitButtonLabel =
    step !== Steps.Employees ? 'Continuar' : nextButtonLabel;

  const onSubmit = async () => {
    const assignedTo = form.getValues('assignedTo');
    let employeeIds = assignedTo
      .filter((a) => a.type === 'Employee')
      .map((a) => a._id);
    let groups = assignedTo
      .filter((a) => a.type === 'Group')
      .map((g) => g.name);
    let workTitles = assignedTo
      .filter((a) => a.type === 'WorkTitle')
      .map((w) => w.name);
    let workCenterIds = assignedTo
      .filter((a) => a.type === 'WorkCenter')
      .map((w) => w._id);
    let sendToAllEmployees = assignedTo.some((a) => a.type === 'ALL_EMPLOYEES');
    if (sendToAllEmployees) {
      employeeIds = [];
      groups = [];
      workTitles = [];
      workCenterIds = [];
    }
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando...',
      });
      const { data } = await updateAuthorizersTeam({
        variables: {
          input: {
            name: form.getValues('name'),
            description: form.getValues('description'),
            color: form.getValues('color'),
            authorizersIds: form.getValues('authorizers').map((a) => a._id),
            signInOrder: form.getValues('signInOrder'),
            employeeIds: employeeIds,
            groups: groups,
            workTitles: workTitles,
            workCenterIds: workCenterIds,
            sendToAllEmployees: sendToAllEmployees,
            authorizersTeamId: preselectedTeam._id,
          },
        },
      });
      if (data.updateAuthorizersTeam.__typename === 'Success') {
        globalSnackbarVar({
          message: 'Grupo de autorizadores actualizado con éxito',
          severity: 'success',
          show: true,
        });
      } else {
        console.error(data.updateAuthorizersTeam);
        globalSnackbarVar({
          message: 'Hubo un error. Contacte a soporte.',
          severity: 'error',
          show: true,
        });
      }
      onClose();
    } catch (error) {
      globalSnackbarVar({
        message: 'Hubo un error. Contacte a soporte.',
        severity: 'error',
        show: true,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const onError = (errorFields) => {
    const { assignedTo, assignedToErrors } = errorFields;

    if (assignedTo) {
      globalSnackbarVar({
        message: assignedTo.message,
        severity: 'warning',
        show: true,
      });
    }
    if (assignedToErrors) {
      globalSnackbarVar({
        message: assignedToErrors.message,
        severity: 'warning',
        show: true,
      });
    }
  };

  const handleNext = async () => {
    const stepFields = FormTriggerFields[step];
    const validation = await trigger(stepFields);
    if (!validation) {
      const errorMessages = stepFields.reduce((acc, field) => {
        if (!errors[field]) return acc;
        return `${acc} ${createHolidayFormErrorMessages[field].required}.`;
      }, '');
      return globalSnackbarVar({
        message: errorMessages,
        severity: 'warning',
        show: true,
      });
    }
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  return (
    <>
      <DialogContent>
        {step === Steps.General && <UATDGeneralForm form={form} />}
        {step === Steps.Authorizers && <UATDAuthorizersForm form={form} />}
        {step === Steps.Employees && <UATDEmployeesForm form={form} />}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={step === Steps.General ? onClose : handlePrev}
          color="primary"
        >
          {step === Steps.General ? 'Cancelar' : 'Atrás'}
        </Button>
        <Button
          onClick={
            step === Steps.Employees
              ? handleSubmit(onSubmit, onError)
              : handleNext
          }
          color="primary"
        >
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </>
  );
};
