import React, { useRef, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { HolidaysSchemeListItem } from './HolidaysSchemeListItem';
import { GET_COMPANY_SCHEMES } from '../holidaySchemes.gql';
import { useQuery } from '@apollo/client';
import { HolidayScheme } from '../holidaySchemes.types';
import { EditHolidaySchemeDialog } from './editHolidaySchemeDialog/EditHolidaySchemeDialog';

export const DisplayHolidaysScheme: React.FC = () => {
  const { data: { getCompanySchemes: { schemes = [] } = {} } = {} } =
    useQuery(GET_COMPANY_SCHEMES);
  const selectedScheme = useRef<null | HolidayScheme>(null);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const openEditDialog = (scheme: HolidayScheme) => {
    selectedScheme.current = scheme;
    setIsEditDialogOpen(true);
  };
  return (
    <>
      <Stack p={1}>
        <Grid container>
          <Grid item xs={12} md={3}>
            {schemes.map((scheme: HolidayScheme) => (
              <HolidaysSchemeListItem
                openEditDialog={openEditDialog}
                key={scheme._id}
                scheme={scheme}
              />
            ))}
          </Grid>
        </Grid>
        {selectedScheme.current && (
          <EditHolidaySchemeDialog
            open={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            scheme={selectedScheme.current}
          />
        )}
      </Stack>
    </>
  );
};
