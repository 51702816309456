import { Box } from '@mui/system';
import React from 'react';
import { PrimaryButton } from '../../../../components/Buttons/Buttons';
import { EmptySpace } from '../../../../components/EmptySpace/EmptySpace';
import { Illustrations } from '../../../../components/Illustrations/Illustrations';
import { Typographies } from '../../../../components/Typographies/Typography';
import { Wrapper } from '../../../../components/Utils/Utils';

export const PasswordCreated = ({ handleLogin }) => {
  return (
    <Wrapper height="100vh" center justifyContent="center" as="form">
      <EmptySpace
        title="¡Bien hecho!"
        message="Ya puedes usar tu nueva contraseña."
        image={Illustrations.passwordCreated}
        iconWidth="130px"
        button={
          <Box
            display="flex"
            flexDirection={['column', 'row']}
            alignItems="center"
            mt={1}
          >
            <div>
              <PrimaryButton
                style={{ ...Typographies.BodySmall }}
                onClick={handleLogin}
                width="250px"
              >
                Continuar
              </PrimaryButton>
            </div>
          </Box>
        }
      />
    </Wrapper>
  );
};
