import { DocumentNode, gql } from '@apollo/client';

export const GET_HOLIDAY_AUTHORIZERS_TEAMS: DocumentNode = gql`
  query getHolidayAuthorizersTeams {
    getHolidayAuthorizersTeams {
      __typename
      ... on GetHolidayAuthorizersTeamsResult {
        authorizerTeams {
          _id
          name
          description
          color
          signInOrder
          employeesCount
          authorizers {
            _id
            names
            lastNameP
            lastNameM
            rfc
            curp
            profilePicture
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_AUTHORIZERS_TEAM: DocumentNode = gql`
  query getEmployeesByAuthorizersTeam(
    $input: getEmployeesByAuthorizersTeamInput!
  ) {
    getEmployeesByAuthorizersTeam(input: $input) {
      __typename
      ... on getEmployeesByAuthorizersTeamResult {
        employees {
          _id
          name
          profilePicture
          type
          workCenterName
        }
        total
      }
    }
  }
`;

export const CREATE_AUTHORIZERS_TEAM: DocumentNode = gql`
  mutation createAuthorizersTeam($input: CreateAuthorizersTeamInput!) {
    createAuthorizersTeam(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;

export const UPDATE_AUTHORIZERS_TEAM: DocumentNode = gql`
  mutation updateAuthorizersTeam($input: UpdateAuthorizersTeamInput!) {
    updateAuthorizersTeam(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;

export const DELETE_AUTHORIZERS_TEAM_EMPLOYEES: DocumentNode = gql`
  mutation deleteAuthorizersTeamEmployees(
    $input: deleteAuthorizersTeamEmployeesInput!
  ) {
    deleteAuthorizersTeamEmployees(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;

export const IMPORT_AUTHORIZERS_TEAM: DocumentNode = gql`
  mutation importAuthorizersTeam($input: importAuthorizersTeamInput!) {
    importAuthorizersTeam(input: $input) {
      __typename
      ... on ImportAuthorizersTeamError {
        rows {
          team
          employeeRfc
          signInOrder
          authorizers
          line
        }
        errors {
          type
          message
          line
          value
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const RETRY_IMPORT_AUTHORIZERS_TEAM: DocumentNode = gql`
  mutation retryImportAuthorizersTeam(
    $input: RetryImportAuthorizersTeamInput!
  ) {
    retryImportAuthorizersTeam(input: $input) {
      __typename
      ... on ImportAuthorizersTeamError {
        rows {
          team
          employeeRfc
          signInOrder
          authorizers
          line
        }
        errors {
          type
          message
          line
          value
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
