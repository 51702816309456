import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Checkbox } from '../../../../../../../../newComponents/Checkbox';
import { publicSans } from '../../../../../../../../components/Typographies/Fonts';
import {
  closestCorners,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useCustomTheme } from '../../../../../../../../Hooks/useCustomTheme';
import { ListItem } from '../../../../../../../../newComponents/ListItem';
import { Label } from '../../../../../../../../newComponents/Label';
import { CSS } from '@dnd-kit/utilities';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { newPaletteType } from '../../../../../../../../theme';
import { SystemProps } from '@mui/system';

interface BaseItem {
  id: string;
}

interface DndColumn<T> {
  id: string;
  render?: (props: {
    row: T;
    handleDeleteItems: (action: DeleteActions, row: T) => void;
    selectedRows: T[];
    palette: newPaletteType;
  }) => React.ReactNode;
}

type DeleteActions = 'massive' | 'single';
type DndTableProps<T> = {
  width?: SystemProps['width'];
  columns: DndColumn<T>[];
  data: T[];
  updateDataOrder: (orderedItems: T[]) => void;
  headerBgColor: string;
  headerTextColor: string;
  selectedRows: T[];
  handleSelectAllCheckbox: (checked: boolean) => void;
  handleCheckboxChange: (checked: boolean, item: T) => void;
  showIndexOnRows: boolean;
  indexOnRowsOffset: number;
  handleDeleteItems: (action: DeleteActions, item: T) => void;
};
interface BaseItem {
  id: string;
}
type DraggableItemContainerProps<T extends BaseItem> =
  React.PropsWithChildren<T>;

const DraggableItemContainer = <T extends BaseItem>({
  id,
  children,
}: DraggableItemContainerProps<T>) => {
  const { setNodeRef, listeners, attributes, transform, transition } =
    useSortable({ id });

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      {children}
    </TableRow>
  );
};

const DraggableTableContainer: React.FC<
  React.PropsWithChildren<{ handleDragEnd: (event: DragEndEvent) => void }>
> = ({ children, handleDragEnd }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 0.01 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}
    >
      <TableContainer
        component={Paper}
        sx={{ borderRadius: '0 0 10px 10px', maxHeight: 400 }}
      >
        {children}
      </TableContainer>
    </DndContext>
  );
};

type DraggableTableRowProps<T> = {
  row: T;
  columns: DndColumn<T>[];
  selectedRows: T[];
  handleCheckboxChange: (checked: boolean, item: T) => void;
  showIndex: boolean;
  index: number;
  indexOffset?: number;
  handleDeleteItems: (action: DeleteActions, row?: T) => void;
};

const DraggableTableRow = <T extends BaseItem>({
  index,
  row,
  columns,
  selectedRows,
  handleCheckboxChange,
  showIndex,
  indexOffset = 1,
  handleDeleteItems,
}: DraggableTableRowProps<T>) => {
  const theme = useCustomTheme();
  const palette = theme.newPalette;
  const rowIsSelected = selectedRows.some((r) => r.id === row.id);
  return (
    <DraggableItemContainer id={row.id}>
      <TableCell
        sx={{
          padding: 0,
          '&.MuiTableCell-root': {
            border: 'none',
          },
          '&.MuiTableCell-root:hover': {
            background: palette.actionStates.hover,
          },
        }}
      >
        <ListItem
          disablePadding
          sx={{
            cursor: 'grab',
            '&.MuiListItem-root': {
              height: 76,
              gap: 2,
              px: 1,
              '.delete_button': {
                visibility: 'hidden',
              },
            },
            '&.MuiListItem-root:hover': {
              background: palette.actionStates.hover,
              '.delete_button': {
                visibility: 'visible',
              },
            },
          }}
        >
          <IconButton>
            <Checkbox
              color="primary"
              checked={rowIsSelected}
              onChange={(event, checked) => handleCheckboxChange(checked, row)}
            />
          </IconButton>
          {showIndex && (
            <Label
              variant={'outlined'}
              color={'default'}
              label={index + indexOffset}
            />
          )}

          {/*{row.fullName}*/}
          {columns.map((column, i) => (
            <Fragment key={i}>
              {column.render
                ? column.render({
                    row,
                    handleDeleteItems,
                    selectedRows,
                    palette,
                  })
                : row[column.id]}
            </Fragment>
          ))}
        </ListItem>
      </TableCell>
    </DraggableItemContainer>
  );
};

export const DndTable = <T extends BaseItem>({
  columns,
  data,
  headerBgColor,
  headerTextColor,
  selectedRows,
  handleSelectAllCheckbox,
  updateDataOrder,
  handleCheckboxChange,
  showIndexOnRows,
  indexOnRowsOffset,
  handleDeleteItems,
  width,
}: DndTableProps<T>) => {
  const [rows, setRows] = useState(data);
  const [orderWasUpdated, setOrderWasUpdated] = useState(false);

  const deleteItemsFromRows = useCallback(
    (row, action) => {
      handleDeleteItems(row, action);
      setRows(rows.filter((r) => r.id !== row.id));
    },
    [handleDeleteItems, rows],
  );

  useEffect(() => {
    if (orderWasUpdated) {
      const newData = data.filter((d) => !rows.find((row) => row.id === d.id));
      setRows([...rows, ...newData]);
    } else {
      setRows(data);
      updateDataOrder(data);
    }
    // we only need execute this useEffect on data change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeIndex = rows.findIndex((row) => row.id === active.id);
    const overIndex = rows.findIndex((row) => row.id === over.id);
    if (activeIndex === -1 || overIndex === -1) return;
    if (activeIndex === overIndex) return;

    const newRows = arrayMove(rows, activeIndex, overIndex);
    setRows(newRows);
    setOrderWasUpdated(true);
    updateDataOrder(newRows);
  };

  return (
    <DraggableTableContainer handleDragEnd={handleDragEnd}>
      <Table sx={{ width }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: '0px', borderBottom: '0px' }}>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{
                  px: 2,
                  py: 2,
                  background: headerBgColor,
                  height: 56,
                }}
              >
                <Checkbox
                  sx={{
                    color: headerTextColor,
                  }}
                  color="primary"
                  checked={
                    rows?.length > 0 && selectedRows?.length === rows?.length
                  }
                  name="general"
                  onChange={(e, checked) => handleSelectAllCheckbox(checked)}
                />
                <Typography
                  style={{
                    color: headerTextColor,
                    fontFamily: publicSans,
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px',
                  }}
                >
                  Nombre
                </Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SortableContext items={rows} strategy={verticalListSortingStrategy}>
            {rows.map((row, rowIndex) => (
              <DraggableTableRow
                key={row.id}
                index={rowIndex}
                row={row}
                // type="TABLE_ROW"
                columns={columns}
                selectedRows={selectedRows}
                handleCheckboxChange={handleCheckboxChange}
                showIndex={showIndexOnRows}
                indexOffset={indexOnRowsOffset}
                handleDeleteItems={deleteItemsFromRows}
              />
            ))}
          </SortableContext>
        </TableBody>
      </Table>
    </DraggableTableContainer>
  );
};
