import React, { useCallback, useState } from 'react';
import { EmptyHolidayAuthorizersPlaceholder } from '../components/EmptyHolidayAuthorizersPlaceholder';
import { useQuery } from '@apollo/client';
import { GET_HOLIDAY_AUTHORIZERS_TEAMS } from '../../Holidays.gql';
import { CreateHolidayAuthorizationTeamDialog } from '../components/AddAuthorizersDialog/CreateHolidayAuthorizationTeamDialog';
import { CircularProgress } from '../../../../../../newComponents';
import { DisplayAuthorizersTeams } from '../components/DisplayAuthorizersTeams';
import { Stack } from '@mui/material';
import { AuthorizersTeam } from '../components/DisplayAuthorizersTeams.types';
import { UpdateAuthorizersTeamDialog } from '../components/UpdateAuthorizersTeamDialog/UpdateAuthorizersTeamDialog';
import { MassiveAuthorizersTeamsImport } from '../components/MassiveAuthorizersTeamsImport/MassiveAuthorizersTeamsImport';

export const HolidayAuthorizers: React.FC = () => {
  const { data, loading } = useQuery(GET_HOLIDAY_AUTHORIZERS_TEAMS);
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [isOpenUpdateDialog, setIsOpenUpdateDialog] = useState(false);
  const [openMassiveImport, setOpenMassiveImport] = useState(false);

  const [preSelectedTeam, setPreSelectedTeam] =
    useState<null | AuthorizersTeam>(null);

  const openAddDialog = useCallback(() => {
    setIsOpenAddDialog(true);
  }, []);

  const openAddMassiveDialog = useCallback(() => {
    setOpenMassiveImport(true);
  }, []);

  const openUpdateDialog = useCallback((team: AuthorizersTeam) => {
    setPreSelectedTeam(team);
    setIsOpenUpdateDialog(true);
  }, []);

  if (loading)
    return (
      <Stack justifyContent={'center'} alignItems={'center'} py={4} px={2}>
        <CircularProgress />
      </Stack>
    );

  return (
    <>
      {data?.getHolidayAuthorizersTeams?.authorizerTeams?.length > 0 ? (
        <DisplayAuthorizersTeams
          authorizersTeams={data?.getHolidayAuthorizersTeams?.authorizerTeams}
          openAddAuthorizersDialog={openAddDialog}
          openAddMassiveAuthorizersDialog={openAddMassiveDialog}
          openUpdateAuthorizersDialog={openUpdateDialog}
        />
      ) : (
        <EmptyHolidayAuthorizersPlaceholder
          openAddAuthorizersDialog={openAddDialog}
          openAddMassiveAuthorizersDialog={openAddMassiveDialog}
        />
      )}
      <MassiveAuthorizersTeamsImport
        open={openMassiveImport}
        onClose={() => setOpenMassiveImport(false)}
      />
      <CreateHolidayAuthorizationTeamDialog
        open={isOpenAddDialog}
        onClose={() => {
          setIsOpenAddDialog(false);
        }}
      />
      <UpdateAuthorizersTeamDialog
        team={preSelectedTeam}
        onClose={() => setIsOpenUpdateDialog(false)}
        open={isOpenUpdateDialog}
      />
    </>
  );
};
