import React, { useRef, useState } from 'react';
import { Grid, Popover, Stack } from '@mui/material';
import Calendar from 'react-calendar';
import { isSameDay } from 'date-fns';
import './SoraCalendar.css';
import { CalendarEventsSideBar } from './CalendarEventsSideBar';
import { CalendarEvent } from './SoraCalendar.types';
import { useScreenSize } from '../../Hooks';
import { SoraCalendarYearNavigation } from './SoraCalendarYearNavigation';
import { useTheme } from '@mui/material/styles';
import { Typography } from '../Typography';

type SoraCalendarProps = {
  events?: CalendarEvent[];
  handleEmptyDayClick?: (date: Date) => void;
  handleDeleteEventClick?: (event: CalendarEvent) => void;
  handleEventClick?: (event: CalendarEvent) => void;
  isSupportPanel?: boolean;
  useMobileVersion?: boolean;
  hideSideBar?: boolean;
};

function getMonthsDates(year: number): Date[] {
  const dates = [];
  for (let i = 0; i < 12; i++) {
    dates.push(new Date(year, i, 1));
  }
  return dates;
}
export const formatShortWeekday = (locale: string, date: Date) =>
  new Intl.DateTimeFormat(locale, {
    weekday: 'narrow',
  }).format(date);

export const monthsLabels = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const SoraCalendar: React.FC<SoraCalendarProps> = ({
  events = [],
  handleEmptyDayClick,
  handleDeleteEventClick,
  handleEventClick,
  isSupportPanel = false,
  useMobileVersion = false,
  hideSideBar = false,
}) => {
  const { isMobile } = useScreenSize();
  const theme = useTheme();
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear(),
  );
  const monthsDates = getMonthsDates(currentYear);

  const selectedEvent = useRef<CalendarEvent | null>(null);

  //popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, date: Date) => {
    const event = events.find((event) => isSameDay(event.date, date));
    if (!event) {
      handleEmptyDayClick?.(date);
      return;
    }
    selectedEvent.current = event;
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'event-popover' : undefined;

  const tileClassName = ({ date }) => {
    const event = events.find(
      (e) => e.date.toDateString() === date.toDateString(),
    );
    return event ? `event-${event.color.replace('#', '')}` : '';
  };

  const onClickDay = (day: Date, event: React.MouseEvent<HTMLButtonElement>) =>
    handleClick(event, day);

  return (
    <div>
      <Stack
        direction={isMobile || useMobileVersion ? 'column' : 'row'}
        gap={2}
      >
        <Stack className={'calendar'} sx={{ width: '100%' }}>
          {!(isMobile || useMobileVersion) && (
            <SoraCalendarYearNavigation
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
            />
          )}
          <Grid container justifyContent={'center'}>
            <Grid
              className={'calendar_months'}
              p={2}
              item
              xs={12}
              md={9}
              sx={{
                borderRight:
                  isMobile || useMobileVersion
                    ? undefined
                    : `1px solid ${theme.customPalette.grayColors.gray_30}`,
              }}
            >
              <Grid container spacing={3}>
                {isMobile || useMobileVersion ? (
                  <Grid item xs={12}>
                    <Calendar
                      locale="es"
                      defaultValue={new Date()}
                      prev2Label={null}
                      next2Label={null}
                      maxDetail="month"
                      minDetail="year"
                      formatShortWeekday={formatShortWeekday}
                      tileClassName={tileClassName}
                      showNeighboringMonth={true}
                      onClickDay={onClickDay}
                    />
                  </Grid>
                ) : (
                  monthsDates.map((date) => (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={3}
                      key={date.toString()}
                    >
                      <Calendar
                        locale="es"
                        navigationLabel={({ date }) =>
                          monthsLabels[date.getMonth()]
                        }
                        value={date}
                        prevLabel={null}
                        prev2Label={null}
                        next2Label={null}
                        nextLabel={null}
                        maxDetail="month"
                        minDetail="month"
                        formatShortWeekday={formatShortWeekday}
                        tileClassName={tileClassName}
                        showNeighboringMonth={false}
                        onClickDay={onClickDay}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
            {!hideSideBar && (
              <Grid item xs={12} md={3}>
                <CalendarEventsSideBar
                  handleDeleteEventClick={handleDeleteEventClick}
                  handleEventClick={handleEventClick}
                  events={events}
                  isSupportPanel={isSupportPanel}
                />
              </Grid>
            )}
          </Grid>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack p={1}>
            <Typography variant="body2">
              {selectedEvent?.current?.title}
            </Typography>
          </Stack>
        </Popover>
      </Stack>
    </div>
  );
};
