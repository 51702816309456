import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Icon } from '../../components/Icons/Icons';
import { useGetTemplate } from '../../containers/RHPod/Documents/Templates/TemplateHooks';
import { TemplateRowDetail } from '../../containers/RHPod/Documents/Templates/Template.types';
import { openTemplatesListDialogVar } from '../../containers/MyCompany/Settings/Preferences/Templates/Templates.vars';
import { Stack } from '@mui/material';
import { newPaletteType } from '../../theme';

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: `${theme.newPalette.text.secondary}`,
  border: `none`,
  fontWeight: 'normal',
  textAlign: 'left',
  justifyContent: 'space-between',
}));

const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: `${theme.newPalette.text.secondary}`,
  border: `none`,
  fontWeight: 'normal',
  textAlign: 'left',
  justifyContent: 'space-between',
}));

type TemplateSelectorProps = {
  selectedTemplateId?: string;
  onSelectTemplate?: (template: TemplateRowDetail) => void;
  error?: boolean;
};

/**
 * To use this component you must put TemplatesListContainerDialog in the parent component
 * @type {React.FC<TemplateSelectorProps>}
 */
export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  selectedTemplateId,
  onSelectTemplate,
  error = false,
}) => {
  const theme = useTheme();
  const palette: newPaletteType = theme.newPalette;
  const { handleGetTemplate } = useGetTemplate();
  const [loadedTemplate, setLoadedTemplate] =
    React.useState<TemplateRowDetail | null>(null);

  const onOpenTemplate = (template: TemplateRowDetail) => {
    setLoadedTemplate(template);
    onSelectTemplate?.(template);
  };

  const openSelectorDialog = () => {
    openTemplatesListDialogVar({ open: true, onOpenTemplate });
  };

  useEffect(() => {
    if (selectedTemplateId) {
      handleGetTemplate({
        templateId: selectedTemplateId,
        onSuccess: (template) => {
          setLoadedTemplate(template);
        },
      });
    }
  }, [handleGetTemplate, selectedTemplateId]);

  return (
    <>
      <Stack
        direction={'row'}
        sx={{
          border: `1px solid ${error ? palette.error.main : palette.divider}`,
          borderRadius: 2,
        }}
        justifyContent={'space-between'}
      >
        <CustomButton
          onClick={openSelectorDialog}
          variant={'outlined'}
          color={'inherit'}
          sx={{ flexGrow: '1' }}
        >
          {loadedTemplate ? loadedTemplate.title : 'Elegir tipo de documento'}
        </CustomButton>
        <ActionButton
          variant={'outlined'}
          color={'inherit'}
          onClick={() => {
            if (!loadedTemplate) {
              return openSelectorDialog();
            }
            setLoadedTemplate(null);
            onSelectTemplate?.(null);
          }}
        >
          <Icon icon={loadedTemplate ? 'close_line' : 'arrow_down_s_line'} />
        </ActionButton>
      </Stack>
    </>
  );
};
