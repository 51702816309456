//@ts-check
import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputAdornment } from '@mui/material';
import Searchbar from '../../../../components/Inputs/Searchbar';
import DateRangeInput from '../../../../components/DateRange/DateRangeInput';
import { Icon } from '../../../../components/Icons/Icons';
import { Button } from '../../../../newComponents/Button';
import DateRangeDialog from '../../../../components/DateRange/DateRangeDialog';
import ColumnsDrawer from '../../Documents/Tables/ColumnsDrawer';
import SearchDialog from '../../Documents/Tables/SearchDialog';
import { usePermissionChecker, useScreenSize } from '../../../../Hooks';
import { gtag } from '../../../../API/instance/createInstance';
import { loggerUtil } from '../../../../utils/loggerUtil';
import { DELETE_DOCUMENTS } from '../../Documents/gql';
import { DeleteDocumentsDialog } from '../../../../components/Dialogs/DeleteDocumentsDialog';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { FilterButton } from '../../../../components/Buttons/FilterButton';
import { compareDates } from '../../../../utils/utils';
import { ReceiptsHeaderButtons } from '../ReceiptsHeader';
import { IconButton } from '../../../../newComponents/IconButton';
import { TableHeaderDefaultFiltersWrapper } from '../../../../layoutComponents/Table/TableHeaderWrappers/TableHeaderDefaultFiltersWrapper';
import { TableHeaderActionsWrapper } from '../../../../layoutComponents/Table/TableHeaderWrappers/TableHeaderActionsWrapper';
import { TableHeaderWrapper } from '../../../../layoutComponents/Table/TableHeaderWrappers/TableHeaderWrapper';

export const ReceiptsTableHeader = ({
  enabledColumns,
  setEnabledColumns,
  modifiedColumns,
  receiptsTableInput,
  hideFiltersButton = false,
  hideColumnsButton = false,
  onDeleteSuccess = () => {},
}) => {
  const { states, actions } = receiptsTableInput;
  const continuousEndDate = true;
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const onSearchClose = (values) => {
    actions.updateAdvancedFilter({
      workCenters: values.workCenters,
      rfc: values.rfc,
      advancedSearch: values.search,
    });
  };

  // COLUMNS
  const [openColumns, setOpenColumns] = useState(false);

  const [permissionToUpdateReceipts, permissionToViewReceipts] =
    usePermissionChecker([
      { permission: 'recs', action: 'update' },
      { permission: 'recs', action: 'read' },
    ]);
  const blockDownload =
    !permissionToUpdateReceipts || !permissionToViewReceipts;

  //delete receipts stuff
  const [deleteDocuments] = useMutation(DELETE_DOCUMENTS);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [comment, setComment] = useState('');
  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleConfirmDeleteDialog = async () => {
    gtag('event', 'click', {
      event_category: `Delete_receipts`,
    });
    try {
      globalBackdropVar({ open: true, text: 'Eliminando Recibo(s)' });

      await deleteDocuments({
        variables: {
          input: {
            ids: states.selectedDeletableReceipts,
            docType: 'RECEIPTS',
            actionType: '',
            comment,
          },
        },
      });

      await onDeleteSuccess();
      actions.updateSelectedReceipts([]);
      actions.updateSelectedDeletableReceipts([]);
      setComment('');
      globalSnackbarVar({
        show: true,
        message: 'Eliminación realizada con éxito',
        severity: 'success',
      });
    } catch (error) {
      loggerUtil.error(error);
      globalSnackbarVar({
        show: true,
        message: `Ocurrió un error en la eliminación, contacte a Sora`,
        severity: 'error',
      });
    } finally {
      setOpenDeleteDialog(false);
      globalBackdropVar({ open: false });
    }
  };

  const handleRemoveDateFilter = (event) => {
    event.stopPropagation();
    actions.setDateFilter(states.defaultDateFilter);
  };
  const dateFilterIsActive = useCallback(() => {
    const isDateDifferent = !compareDates(
      states.dateFilter,
      states.defaultDateFilter,
    );
    if (isDateDifferent) return true;
    return false;
  }, [states.dateFilter, states.defaultDateFilter]);

  const { xs } = useScreenSize();
  const isDateFilterActive = dateFilterIsActive();
  return (
    <>
      <TableHeaderWrapper>
        <TableHeaderDefaultFiltersWrapper>
          <DateRangeInput
            dateFilter={states.dateFilter}
            continuousEndDate={continuousEndDate}
            onClick={() => setOpenDateDialog(true)}
            label="Fecha de inicio de pago"
            size="small"
            sx={{
              minWidth: '200px',
              maxWidth: xs ? '100%' : '200px',
            }}
            InputProps={{
              sx: {
                '& fieldset': { border: 'none' },
                cursor: 'pointer',
                '& *': {
                  cursor: 'pointer',
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="calendar_line" height="20px" color="text" />
                </InputAdornment>
              ),
              endAdornment: isDateFilterActive && (
                <InputAdornment position="end">
                  <Icon
                    icon="filter_off_line"
                    height="20px"
                    color="error"
                    onClick={handleRemoveDateFilter}
                    pointer
                  />
                </InputAdornment>
              ),
            }}
          />
          <Searchbar
            placeholder={'Buscar'}
            onChange={(e) => actions.updateSearch(e.target.value)}
            name="search"
            value={states.search}
            setInputValue={actions.updateSearch}
            sx={{
              minWidth: '200px',
              flexGrow: 1,
              maxWidth: '520px',
            }}
          />
        </TableHeaderDefaultFiltersWrapper>
        <ReceiptsTableHeaderActions
          receiptsTableInput={receiptsTableInput}
          blockDownload={blockDownload}
          hideColumnsButton={hideColumnsButton}
          hideFiltersButton={hideFiltersButton}
          setOpenDeleteDialog={setOpenDeleteDialog}
          setOpenColumns={setOpenColumns}
          setOpenSearchDialog={setOpenSearchDialog}
        />
      </TableHeaderWrapper>
      <DateRangeDialog
        open={openDateDialog}
        setOpen={setOpenDateDialog}
        setDateFilter={actions.setDateFilter}
        initialSelection={states.dateFilter}
        continuousEndDate={continuousEndDate}
      />
      <ColumnsDrawer
        anchor="right"
        open={openColumns}
        enabledColumns={enabledColumns}
        setEnabledColumns={setEnabledColumns}
        columns={modifiedColumns}
        setOpenColumns={setOpenColumns}
        localStorageType="receiptsColumns"
      />
      <SearchDialog
        open={openSearchDialog}
        setOpen={setOpenSearchDialog}
        onSearchClose={onSearchClose}
        rfcFilter={states.rfc}
        searchFilter={states.advancedSearch}
        workCenterIdsFilter={states.workCenters}
        filterActive={states.filterActive}
        resetFilters={actions.resetAdvancedFilter}
      />
      <DeleteDocumentsDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        handleCommentChange={handleCommentChange}
        onConfirm={handleConfirmDeleteDialog}
        documentType={'receipts'}
        multipleFiles={states.selectedDeletableReceipts?.length > 1}
      />
    </>
  );
};

const ReceiptsTableHeaderActions = ({
  receiptsTableInput,
  blockDownload,
  hideColumnsButton,
  hideFiltersButton,
  setOpenDeleteDialog,
  setOpenColumns,
  setOpenSearchDialog,
}) => {
  const { openedFromEmployeeInfo, states } = receiptsTableInput;
  if (openedFromEmployeeInfo && !states.selectedReceipts.length) {
    return (
      <ReceiptsHeaderButtons
        receiptsTableInput={receiptsTableInput}
        hideReceiptsConfig
      />
    );
  }

  const shouldShowDeleteButton = states.selectedReceipts.length > 0;
  hideColumnsButton = hideColumnsButton || shouldShowDeleteButton;
  hideFiltersButton = hideFiltersButton || shouldShowDeleteButton;

  return (
    <TableHeaderActionsWrapper>
      {states.selectedReceipts.length > 0 && (
        <Button
          disabled={states.selectedDeletableReceipts.length === 0}
          color="error"
          variant="outlined"
          onClick={() => {
            setOpenDeleteDialog(true);
          }}
          tooltipText={
            (blockDownload && 'Necesitas permisos para esta acción') ||
            `Eliminar recibos seleccionados: ${states.selectedDeletableReceipts.length}`
          }
          tooltipArrow
          tooltipPlacement="top"
        >
          Eliminar ({states.selectedDeletableReceipts.length})
        </Button>
      )}
      {!hideFiltersButton && (
        <FilterButton
          active={states.filterActive}
          onClick={() => setOpenSearchDialog(true)}
        />
      )}
      {!hideColumnsButton && (
        <IconButton
          color="text"
          onClick={() => setOpenColumns(true)}
          icon="table_line"
          tooltipText="Editar columnas"
          tooltipArrow
          tooltipPlacement="top"
        />
      )}
    </TableHeaderActionsWrapper>
  );
};
