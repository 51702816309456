import React from 'react';
import { Stack } from '@mui/material';
import { Avatar } from '../../../../../../../newComponents/Avatar';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { UseFormReturn } from 'react-hook-form';
import { ColorSelector } from '../../../../../../../newComponents/ColorSelector';
import { ControlledInput } from '../../../../../../../components/ControlledInputs';
import { createHolidayFormErrorMessages } from '../AddAuthorizersDialog/CHAGForm';
import { UpdateAuthorizersTeamDialogFormType } from './UpdateAuthorizersTeamDialog';

type Props = {
  form: UseFormReturn<UpdateAuthorizersTeamDialogFormType>;
};
export const UATDGeneralForm: React.FC<Props> = ({ form }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = form;

  return (
    <>
      <Stack gap={2} mt={2}>
        <ControlledInput
          control={control}
          name={'name'}
          rules={{
            required: 'Este campo es requerido',
            minLength: {
              value: 3,
              message: 'El nombre debe tener al menos 3 caracteres',
            },
          }}
          muiProps={{ label: 'Nombre del flujo de autorización' }}
          errors={errors}
          errorMessages={createHolidayFormErrorMessages.name}
        />
        <ControlledInput
          control={control}
          name={'description'}
          rules={{
            required: 'Este campo es requerido',
            minLength: {
              value: 3,
              message: 'El nombre debe tener al menos 3 caracteres',
            },
          }}
          muiProps={{
            label: 'Descripción',
            fullWidth: true,
            multiline: true,
            rows: 3,
          }}
          errors={errors}
          errorMessages={createHolidayFormErrorMessages.description}
        />
        <Avatar cssColor={watch('color')}>
          <Icon icon={'organization_chart'} fill={'white'} />
        </Avatar>
        <ColorSelector
          color={watch('color')}
          onChange={(c) => setValue('color', c)}
          ommitGradients
        />
      </Stack>
    </>
  );
};
