//@ts-check
import React, { memo } from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { CompSignersSearch } from './CompSignersSearch';
import { EmployeesFinder } from '../../../../../businessComponents/EmployeesFinder';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { FormControlLabel } from '../../../../../newComponents/FormControlLabel';

/**
 * @typedef {import('../__common/VirtualizedAutocomplete').VirtualizedAutocompleteProps} VirtualizedAutocompleteProps
 * @typedef {import('../../../../../theme').CustomTheme} CustomTheme
 */

/**
 * Search signers element
 *
 * @function
 * @typedef {object} SearchSignersProps - The props for the SearchSigners component.
 * @property {(signer: import('./CompSignersSearch.types').Signer, type: import('./CompSignersSearch.types').SignerType) => void} handleAddSigner
 * @property {() => void} onCloseAutoComplete
 * @property {Function} handleOnSelectedRowsOnPaste
 * @property {Function} handlePasterErrors
 * @property {Function} handlePasteSigner - Function to paste  signers list
 * @property {import('../../../../../businessComponents/EmployeesFinder/EmployeesFinder.types').Employee[] | import('../../../../../businessComponents/EmployeesFinder/EmployeesFinder.types').WorkCenter[] | import('../../../../../businessComponents/EmployeesFinder/EmployeesFinder.types').WorkTitle[] | import('../../../../../businessComponents/EmployeesFinder/EmployeesFinder.types').Group[]} selectedItems - Array of selected items (['id']).
 * @property {boolean} userHasSelectedSaveOnlyAction - Boolean to indicate if the user has selected the save only action.
 * @property {boolean} sendAllEmployees - Boolean to select all employees.
 * @property {object} [defaultSelectedEmployee] - The employee object.
 * @property {() => void} handleChangeSelectAllEmployees - Function to save all employees
 * @property {(total: number) => void} [setTotalCompanyEmployees] - Function to save all employees
 * @param {SearchSignersProps} Props
 * @returns {React.JSX.Element} The JSX representation of the SearchSigners component.
 */

export const SearchSignersComponent = ({
  handleAddSigner,
  selectedItems,
  userHasSelectedSaveOnlyAction = false,
  sendAllEmployees,
  handleChangeSelectAllEmployees,
  defaultSelectedEmployee,
  onCloseAutoComplete,
  handleOnSelectedRowsOnPaste,
  handlePasterErrors,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectRow = (row) => {
    handleAddSigner(row, row.type);
  };

  return (
    <>
      <Stack
        gap={2}
        direction="column"
        alignItems="flex-start"
        flexWrap={sm ? 'wrap' : undefined}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          flexWrap={sm ? 'wrap' : undefined}
          sx={{ width: '100%' }}
        >
          {!Boolean(defaultSelectedEmployee) && (
            <>
              <EmployeesFinder
                findBy={['EMPLOYEES', 'WORKCENTERS', 'WORKTITLES', 'GROUPS']}
                onSelectRow={(row) => handleSelectRow(row)}
                selectedRows={selectedItems}
                onSelectedRowsOnPaste={handleOnSelectedRowsOnPaste}
                handlePasterErrors={handlePasterErrors}
                disabled={Boolean(defaultSelectedEmployee)}
                isEmployeeSelected={(row) => {
                  return selectedItems.some((item) => item._id === row._id);
                }}
              />
              <FormControlLabel
                value="option1"
                control={
                  <Checkbox color="primary" checked={sendAllEmployees} />
                }
                label="Asignar a todos los empleados"
                labelPlacement="end"
                onChange={handleChangeSelectAllEmployees}
                style={{ marginLeft: 0, marginTop: 10 }}
                disabled={Boolean(defaultSelectedEmployee)}
              />
            </>
          )}
        </Stack>

        <CompSignersSearch
          handleAddSigner={handleAddSigner}
          selectedItems={selectedItems}
          disabled={userHasSelectedSaveOnlyAction}
          helperText={
            userHasSelectedSaveOnlyAction
              ? 'No se puede asignar a firmantes de empresa al configurar documentos enviados a expediente laboral (Solo guardar)'
              : ''
          }
          onClose={onCloseAutoComplete}
        />
      </Stack>
    </>
  );
};

export const SearchSigners = memo(SearchSignersComponent);
