import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  Collapse,
  Paper,
  Stack,
  Typography,
  IconButton,
  SxProps,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import {
  ListItem,
  Checkbox,
  Button,
} from '../../../../../../../../newComponents';
import { SystemProps } from '@mui/system';
import { newPaletteType } from '../../../../../../../../theme';
import { Icon } from '../../../../../../../../components/Icons/Icons';

export type DeleteActions = 'massive' | 'single';
type RowType =
  | 'Employee'
  | 'WorkCenter'
  | 'WorkTitle'
  | 'Group'
  | 'ALL_EMPLOYEES';

export type RowBaseItem = {
  _id?: string; // worktitle and group does not have _id
  name?: string;
  fullName?: string;
  workCenterName?: string; // if row is employee the object will have this property
  type: RowType;
  employeeCount?: number;
  profilePicture?: string;
  success?: boolean;
  user?: string; // when copy and paste fails
  message?: string; // when copy and paste fails
  alreadyAssigned?: boolean; // when the row is already assigned to the team (loaded in backend)
};

export type SignersTableColumn = {
  id: string;
  label: string;
  align: 'left' | 'right' | 'center';
  disabledPadding: boolean;
  style: SxProps;
  render: (args: {
    row: RowBaseItem;
    deleteRow: (type: DeleteActions, row?: RowBaseItem) => void;
    selectedRows: RowBaseItem[];
    palette: newPaletteType;
  }) => React.JSX.Element;
};

type RowItemProps = {
  row: RowBaseItem;
  style: React.CSSProperties;
  columns: SignersTableColumn[];
  deleteRow: (type: DeleteActions, row?: RowBaseItem) => void;
  selectedRows: RowBaseItem[];
  handleCheckboxChange?: (checked: boolean, row: any) => void;
  palette: newPaletteType;
};

const RowItem: React.FC<RowItemProps> = ({
  row,
  style,
  columns,
  deleteRow,
  selectedRows,
  handleCheckboxChange,
  palette,
}) => {
  const rowIsChecked = selectedRows.some((r) => {
    if (['WorkTitle', 'Group'].includes(row.type)) return r.name === row.name;
    return r._id === row._id;
  });
  return (
    <ListItem
      key={row._id}
      disablePadding
      sx={{
        '&.MuiListItem-root': {
          height: 76,
          gap: 2,
          px: 1,
          '.delete_button': {
            visibility: 'hidden',
          },
        },
        '&.MuiListItem-root:hover': {
          background: palette.actionStates.hover,
          '.delete_button': {
            visibility: 'visible',
          },
        },
      }}
      style={style}
    >
      {handleCheckboxChange && (
        <IconButton>
          <Checkbox
            color="primary"
            checked={rowIsChecked}
            onChange={(e, checked) => handleCheckboxChange(checked, row)}
          />
        </IconButton>
      )}
      {columns.map((column, i) => (
        <Fragment key={i}>
          {column.render
            ? column.render({
                row,
                deleteRow,
                selectedRows,
                palette,
              })
            : row[column.id]}
        </Fragment>
      ))}
    </ListItem>
  );
};

type TableComponentProps = {
  width: SystemProps['width'];
  height: number;
  headerBgColor: string;
  headerTextColor: string;
  rows: RowBaseItem[];
  selectedRows: RowBaseItem[];
  columns: SignersTableColumn[];
  handleSelectAllCheckbox?: (checked: boolean) => void;
  handleCheckboxChange?: (checked: boolean, row: any) => void;
  deleteRows: (type: DeleteActions, row?: RowBaseItem) => void;
  areAllChecked: boolean;
  handleLoadMore: () => void;
  loadedRowCount: number;
  totalRowsCount: number;
};

export const TableComponent: React.FC<TableComponentProps> = ({
  width,
  height,
  headerBgColor,
  headerTextColor,
  rows = [],
  selectedRows = [],
  columns,
  handleSelectAllCheckbox,
  handleCheckboxChange,
  areAllChecked,
  handleLoadMore,
  loadedRowCount,
  totalRowsCount = 0,
  deleteRows,
}) => {
  const theme = useTheme();
  const palette = theme.newPalette;

  const onItemsRendered = ({ visibleStopIndex }: any) => {
    if (visibleStopIndex === rows.length - 1) {
      if (loadedRowCount < totalRowsCount) {
        handleLoadMore();
      }
    }
  };

  return (
    <Fragment>
      <Collapse in={Boolean(selectedRows.length > 0)}>
        <Stack
          minHeight="76px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: palette.primary.lighter,
          }}
          px={selectedRows?.length ? 2 : 3}
        >
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{
              '&.MuiButton-textPrimary': {
                padding: 0,
                pointerEvents: 'none',
              },
            }}
          >
            {selectedRows.length} empleados seleccionados
          </Button>
          <IconButton onClick={() => deleteRows('massive')}>
            <Icon
              icon="delete_bin_line"
              width="20px"
              fill={palette.primary.main}
            />
          </IconButton>
        </Stack>
      </Collapse>
      {rows?.length > 0 && (
        <Stack
          className={'tableComponentWrapper'}
          component={Paper}
          sx={{
            width,
            borderRadius: '0 0 10px 10px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            sx={{
              px: 2,
              py: 2,
              background: headerBgColor,
              height: 56,
            }}
          >
            {handleSelectAllCheckbox && (
              <Checkbox
                sx={{
                  color: headerTextColor,
                }}
                color="primary"
                checked={areAllChecked}
                name="general"
                onChange={(e, checked) => handleSelectAllCheckbox(checked)}
              />
            )}
            <Typography variant={'body2'} color={headerTextColor}>
              Nombre
            </Typography>
          </Stack>
          <Stack
            sx={{
              overflowY: 'auto',
              overflowX: 'auto',
              mb: 1,
            }}
          >
            <InfiniteLoader
              isItemLoaded={(index) => index < loadedRowCount}
              itemCount={totalRowsCount}
              threshold={10}
              loadMoreItems={handleLoadMore}
            >
              {() => (
                <FixedSizeList
                  height={
                    rows?.length ? Math.min(rows.length * 76, height) : 400
                  }
                  width={'100%'}
                  itemCount={rows.length}
                  itemSize={76}
                  itemData={rows}
                  onItemsRendered={onItemsRendered}
                  overscanCount={0}
                  itemKey={(index) =>
                    //@ts-ignore
                    rows[index]._id || rows[index].id
                  }
                >
                  {({ index, style }) => (
                    <RowItem
                      style={style}
                      row={rows[index]}
                      columns={columns}
                      deleteRow={deleteRows}
                      selectedRows={selectedRows}
                      handleCheckboxChange={handleCheckboxChange}
                      palette={palette}
                    />
                  )}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          </Stack>
        </Stack>
      )}
    </Fragment>
  );
};
