import { DocumentNode, gql } from '@apollo/client';

export const GET_HOLIDAYS_FROM_ROLES: DocumentNode = gql`
  query getHolidaysFromRoles($input: GetHolidaysFromRolesInput!) {
    getHolidaysFromRoles(input: $input) {
      __typename
      ... on GetHolidaysFromRolesResult {
        data {
          _id
          requestForYear
          daysRequested
          status
          approveEmployeeDocumentId
          holidayTakenEmployeeDocumentId
          comments
          authorizations {
            status
            comments
            updatedAt
            authorizer {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
          employee {
            _id
            rfc
            names
            lastNameP
            lastNameM
            profilePicture
          }
          workCenter {
            code
            name
          }
          authorizationTeam {
            _id
            name
            description
            color
            signInOrder
            authorizers {
              _id
              rfc
              names
              lastNameP
              lastNameM
              profilePicture
            }
          }
          timestamps {
            createdAt
            updatedAt
          }
        }
        total
      }
      ... on Error {
        message
      }
    }
  }
`;

export const GET_HOLIDAYS_FROM_ROLES_COUNTERS: DocumentNode = gql`
  query getHolidaysFromRolesCounters(
    $input: GetHolidaysFromRolesCountersInput!
  ) {
    getHolidaysFromRolesCounters(input: $input) {
      __typename
      counters {
        pending
        rejected
        inProgress
        incoming
        finished
        all
      }
    }
  }
`;

export const REJECT_HOLIDAY_REQUEST: DocumentNode = gql`
  mutation rejectHolidayRequest($input: RejectHolidayRequestInput!) {
    rejectHolidayRequest(input: $input) {
      __typename
    }
  }
`;
