import { useCallback } from 'react';
import { UPDATE_COMPANY_SIGNER_NOTIFICATIONS_CONFIG } from '../Profile.gql';
import { useApolloClient } from '@apollo/client';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import type { NotificationsConfig } from '../ProfileDialog.types';

export const useUpdateCompanySignerNotificationsConfig = () => {
  const graphqlClient = useApolloClient();

  const updateCompanySignerNotificationsConfig = useCallback(
    async (input: NotificationsConfig): Promise<boolean> => {
      try {
        const result = await graphqlClient.mutate({
          mutation: UPDATE_COMPANY_SIGNER_NOTIFICATIONS_CONFIG,
          variables: {
            input: { ...input },
          },
        });

        const mutationSuccess =
          result?.data?.updateCompanySignerNotificationsConfig?.success ||
          false;

        return mutationSuccess;
      } catch (err) {
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error al actualizar la configuración',
          severity: 'error',
        });
        return false;
      }
    },
    [graphqlClient],
  );

  return {
    updateCompanySignerNotificationsConfig,
  };
};
