import { useTheme } from '@mui/material/styles';
import { Typography } from '../../../../../../../newComponents/Typography';
import React from 'react';
import { Avatar } from '../../../../../../../newComponents/Avatar';
import { Button } from '../../../../../../../newComponents/Button';
import { Stack } from '@mui/material';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { useQuery } from '@apollo/client';
import { DOWNLOAD_ADMIN_FILE } from '../../../../../../RHPod/Employees/Employees.gql';
import { Upload } from '../../../../../../../newComponents/Upload';
import { mimeTypes } from '../../../../../../../utils/constants';

/**
 * this enum should map the type def enum in the backend
 */
// eslint-disable-next-line no-unused-vars
export enum DownloadAdminFileConstants {
  // eslint-disable-next-line no-unused-vars
  AUTHORIZERS_FORM = 'AUTHORIZERS_FORM',
  // eslint-disable-next-line no-unused-vars
  WORKCENTERUPLOADFORM = 'WORKCENTERUPLOADFORM',
  // eslint-disable-next-line no-unused-vars
  USERUPLOADFORM = 'USERUPLOADFORM',
  // eslint-disable-next-line no-unused-vars
  SALARYUPLOADFORM = 'SALARYUPLOADFORM',
  // eslint-disable-next-line no-unused-vars
  CREDITUPLOADFORM = 'CREDITUPLOADFORM',
  // eslint-disable-next-line no-unused-vars
  ADMINUPLOADFORM = 'ADMINUPLOADFORM',
  // eslint-disable-next-line no-unused-vars
  GROUPSUPDATEFORM = 'GROUPSUPDATEFORM',
}

export const DownloadImportTemplateScreen: React.FC<{
  downloadAdminFileConstant: DownloadAdminFileConstants;
  onFileUpload: (files: File[]) => void;
}> = ({ downloadAdminFileConstant, onFileUpload }) => {
  const theme = useTheme();
  const { data } = useQuery(DOWNLOAD_ADMIN_FILE, {
    variables: {
      type: downloadAdminFileConstant,
    },
  });
  return (
    <Stack flex={1}>
      <Stack gap={2} mb={3} color={theme.customPalette.grayColors.gray_50}>
        <Stack direction="row" gap={1}>
          <Typography variant="subtitle1">Instrucciones:</Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Avatar variant="circular" color="primary" size="tiny">
            1
          </Avatar>
          <Typography color="grey">
            Descarga la plantilla y llena los datos
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Avatar variant="circular" color="primary" size="tiny">
            2
          </Avatar>
          <Typography color="grey">
            {downloadAdminFileConstant ===
            DownloadAdminFileConstants.AUTHORIZERS_FORM
              ? 'Sube la plantilla llenada para importar a tus equipos de autorizadores'
              : ''}
          </Typography>
        </Stack>
        <Stack>
          <Button
            component="a"
            variant="soft"
            color="primary"
            href={data?.downloadAdminFile ?? ''}
            startIcon={<Icon icon="file_download_line" />}
          >
            Descarga la plantilla
          </Button>
        </Stack>
      </Stack>
      <Stack flex={1}>
        <Upload
          id={`${downloadAdminFileConstant}_upload`}
          variant="blockFile"
          orientation="column"
          accept={mimeTypes.spreadsheet}
          title="Importar desde archivo"
          onChange={(acceptedFiles) => {
            onFileUpload(acceptedFiles);
          }}
          single
          sx={{
            minHeight: '230px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.newPalette.grey.grey200,
            flex: 1,
          }}
          content={
            <Stack pt={1}>
              <Icon icon={'upload_cloud_3_line'} color={'grey'} />
              <Typography
                variant={'body2'}
                color={theme.newPalette.text.secondary}
                textAlign="center"
              >
                Subir archivo
              </Typography>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};
