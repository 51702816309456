import { HolidayScheme } from '../../holidaySchemes.types';
import {
  Assignee,
  AssigneeError,
} from '../../../AuthorizersTeams/components/AddAuthorizersDialog/CreateHolidayAuthorizationTeamDialog.types';
import { RowBaseItem } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';

export type EditHolidaySchemeDialogFormType = HolidayScheme & {
  selectedScheme: HolidayScheme;
  assignedTo: Assignee[];
  assignedToErrors: AssigneeError[];
  preloadedEmployees: RowBaseItem[];
};

export enum EditHolidaySchemeSteps {
  // eslint-disable-next-line no-unused-vars
  DaysGranted = 'DaysGranted',
  // eslint-disable-next-line no-unused-vars
  Employees = 'Employees',
}
