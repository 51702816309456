import React from 'react';
import {
  EditHolidaySchemeDialogFormType,
  EditHolidaySchemeSteps,
} from './EditHolidaySchemeDialog.types';
import { UseFormReturn } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions } from '@mui/material';
import { Button } from '../../../../../../../newComponents';
import { EHSEditScheme } from './EHSEditScheme';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../../cache.reactiveVars';
import { EHSEmployees } from './EHSEmployees';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  GET_COMPANY_SCHEMES,
  UPDATE_HOLIDAY_SCHEME,
} from '../../holidaySchemes.gql';

type EHSFormProps = {
  form: UseFormReturn<EditHolidaySchemeDialogFormType>;
  step: EditHolidaySchemeSteps;
  setStep: (step: EditHolidaySchemeSteps) => void;
  onClose: () => void;
  handlePrev: () => void;
};
export const EHSForm: React.FC<EHSFormProps> = ({
  form,
  step,
  setStep,
  onClose,
  handlePrev,
}) => {
  const client = useApolloClient();
  const [updateHolidayScheme] = useMutation(UPDATE_HOLIDAY_SCHEME);
  const handleNext = async () => {
    if (step === EditHolidaySchemeSteps.DaysGranted) {
      const approveTemplateId = form.getValues('approveTemplateId');
      if (!approveTemplateId) {
        form.setError('approveTemplateId', {
          type: 'required',
          message: 'Este campo es requerido',
        });
        return globalSnackbarVar({
          message: 'Selecciona una plantilla para la solicitud de vacaciones',
          show: true,
          severity: 'error',
        });
      }
      setStep(EditHolidaySchemeSteps.Employees);
    } else {
      await onSubmit();
    }
  };

  const onSubmit = async () => {
    const scheme = form.getValues('selectedScheme');
    const assignedTo = form.getValues('assignedTo');
    let employeeIds = assignedTo
      .filter((a) => a.type === 'Employee')
      .map((a) => a._id);
    let groups = assignedTo
      .filter((a) => a.type === 'Group')
      .map((g) => g.name);
    let workTitles = assignedTo
      .filter((a) => a.type === 'WorkTitle')
      .map((w) => w.name);
    let workCenterIds = assignedTo
      .filter((a) => a.type === 'WorkCenter')
      .map((w) => w._id);
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando...',
      });
      const { data } = await updateHolidayScheme({
        variables: {
          input: {
            employeesIds: employeeIds,
            groups: groups,
            workTitles: workTitles,
            workCenterIds: workCenterIds,
            holidaySchemeId: scheme._id,
            holidayTakenTemplateId: form.getValues('holidayTakenTemplateId'),
            approveTemplateId: form.getValues('approveTemplateId'),
          },
        },
      });
      if (data.updateHolidayScheme.__typename === 'Success') {
        globalSnackbarVar({
          message: 'Esquema de vacaciones actualizado con éxito',
          severity: 'success',
          show: true,
        });
      } else {
        console.error(data.updateHolidayScheme);
        globalSnackbarVar({
          message: 'Hubo un error. Contacte a soporte.',
          severity: 'error',
          show: true,
        });
      }
      onClose();
      client.refetchQueries({ include: [GET_COMPANY_SCHEMES] });
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        message: 'Hubo un error. Contacte a soporte.',
        severity: 'error',
        show: true,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return (
    <>
      <DialogContent>
        {step === EditHolidaySchemeSteps.DaysGranted ? (
          <EHSEditScheme form={form} />
        ) : (
          <EHSEmployees form={form} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            step === EditHolidaySchemeSteps.Employees ? handlePrev : onClose
          }
          color="primary"
        >
          {step === EditHolidaySchemeSteps.Employees ? 'Atrás' : 'Cancelar'}
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={handleNext}>
          {step === 'DaysGranted' ? 'Siguiente' : 'Guardar'}
        </Button>
      </DialogActions>
    </>
  );
};
