// @ts-check
import { ListItemText as MuiListItemText } from '@mui/material';

/**
 * @typedef {import('@mui/material/ListItemText').ListItemTextProps} ListItemTextProps
 * @type {React.FC<ListItemTextProps>}
 */
export const ListItemText = (props) => {
  // @ts-ignore
  return <MuiListItemText {...props} />;
};
