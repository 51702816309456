import { Stack, useMediaQuery } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { OrderableAuthorizer } from './CreateHolidayAuthorizationTeamDialog.types';
import { CompSignersSearch } from '../../../../../../RHPod/Documents/PdfDocuments/Signers/CompSignersSearch';
import { ControlledSwitch } from '../../../../../../../components/ControlledInputs/ControlledSwitch/ControlledSwitch';
import { useTheme } from '@mui/material/styles';
import { TableCardHeader } from './setAuthorizersSlide/TableCardHeader';
import {
  DeleteActionType,
  TableComponent,
} from './setAuthorizersSlide/TableComponent';
import { COLUMNS_TABLE_AUTHORIZERS } from './setAuthorizersSlide/TableColumns';
import { DndTable } from './setAuthorizersSlide/DndTable';
import { CreateHolidayAuthorizationTeamDialogFormType } from './CreateHolidayAuthorizationTeamDialog';
import { globalSnackbarVar } from '../../../../../../../cache.reactiveVars';

type Props = {
  form: UseFormReturn<CreateHolidayAuthorizationTeamDialogFormType>;
};

export const CHAGAuthorizersForm: React.FC<Props> = ({ form }) => {
  const theme = useTheme();
  const palette = theme.newPalette;
  const md = useMediaQuery(useTheme().breakpoints.down('md'));
  const { watch, setValue, getValues, control } = form;
  // const authorizers = watch('authorizers');
  const signInOrder = watch('signInOrder');
  const assignedTo = watch('assignedTo');

  const [orderedAuthorizers, setOrderedAuthorizers] = useState<
    OrderableAuthorizer[]
  >(
    getValues('authorizers').map((authorizer) => ({
      ...authorizer,
      id: authorizer._id,
    })),
  );

  const [selectedAuthorizers, setSelectedAuthorizers] = useState<
    OrderableAuthorizer[]
  >([]);

  const addAuthorizerToState = (a: OrderableAuthorizer) => {
    if (assignedTo.some((assignee) => assignee._id === a._id)) {
      return globalSnackbarVar({
        show: true,
        message: 'El autorizador ya está asignado a la lista de empleados',
        severity: 'warning',
      });
    }
    setOrderedAuthorizers((prev) => [
      ...prev,
      {
        ...a,
        id: a._id,
      },
    ]);
  };

  const removeAuthorizerFromState = (
    action: DeleteActionType,
    a?: OrderableAuthorizer,
  ) => {
    if (action === 'massive') {
      setOrderedAuthorizers([]);
      setSelectedAuthorizers([]);
    } else {
      setOrderedAuthorizers(orderedAuthorizers.filter((o) => o._id !== a._id));
      setSelectedAuthorizers(
        selectedAuthorizers.filter((o) => o._id !== a._id),
      );
    }
  };

  useEffect(() => {
    setValue('authorizers', orderedAuthorizers);
  }, [orderedAuthorizers, setValue]);

  return (
    <>
      <Stack gap={2} mt={2}>
        <CompSignersSearch
          onClose={() => {}}
          handleAddSigner={(signer) => {
            addAuthorizerToState(signer);
          }}
          searchPermission="holidays"
          selectedItems={orderedAuthorizers}
          placeholder={'Buscar autorizadores'}
          noOptionsText={'No se encontraron autorizadores...'}
          loadingText={'Cargando autorizadores...'}
          origin={'authorizers'}
        />
        <Stack justifyContent={'center'} alignItems={'center'}>
          <ControlledSwitch
            control={control}
            name="signInOrder"
            description={''}
            muiProps={{
              labelProps: {
                label: 'Activar orden de firmado',
                labelPlacement: 'end',
              },
            }}
          />
        </Stack>

        <Stack
          className="tableAuthorizers"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <TableCardHeader
            rows={orderedAuthorizers}
            width={md ? '100%' : 480}
            cardTitle={'Autorizadores'}
            cardSubHeader={'Todos autorizan de forma aleatoria'}
            headerBgColor={palette.grey.transparent24}
            signInOrder={signInOrder}
          />
          {signInOrder ? (
            <Stack justifyContent={'center'} alignItems={'center'}>
              <DndTable<OrderableAuthorizer>
                width={md ? '100%' : 480}
                columns={COLUMNS_TABLE_AUTHORIZERS}
                data={orderedAuthorizers}
                updateDataOrder={(rows) => {
                  setOrderedAuthorizers(rows);
                }}
                headerBgColor={palette.grey.transparent24}
                headerTextColor={palette.text.secondary}
                selectedRows={selectedAuthorizers}
                handleSelectAllCheckbox={(checked) => {
                  setSelectedAuthorizers(checked ? orderedAuthorizers : []);
                }}
                handleCheckboxChange={(checked, row) => {
                  if (checked) {
                    setSelectedAuthorizers((prev) => [
                      ...prev,
                      {
                        ...row,
                        id: row._id,
                      },
                    ]);
                  } else {
                    setSelectedAuthorizers(
                      selectedAuthorizers.filter(
                        (selected) => selected._id !== row._id,
                      ),
                    );
                  }
                }}
                showIndexOnRows={true}
                indexOnRowsOffset={1}
                handleDeleteItems={removeAuthorizerFromState}
              />
            </Stack>
          ) : (
            <TableComponent
              width={md ? '100%' : 480}
              height={300}
              headerBgColor={palette.grey.transparent24}
              headerTextColor={palette.text.secondary}
              rows={orderedAuthorizers}
              columns={COLUMNS_TABLE_AUTHORIZERS}
              handleCheckAll={(checked) => {
                setSelectedAuthorizers(checked ? orderedAuthorizers : []);
              }}
              selectedRows={selectedAuthorizers}
              handleCheckboxChange={(event, checked, authorizer) => {
                if (checked) {
                  setSelectedAuthorizers((prev) => [
                    ...prev,
                    {
                      ...authorizer,
                      id: authorizer._id,
                    },
                  ]);
                } else {
                  setSelectedAuthorizers(
                    selectedAuthorizers.filter(
                      (selected) => selected._id !== authorizer._id,
                    ),
                  );
                }
              }}
              handleDeleteItems={removeAuthorizerFromState}
              areAllChecked={
                orderedAuthorizers.length === selectedAuthorizers.length
              }
              loadedRowCount={0}
              showIndexOnRows={false}
              loadMore={() => {}}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
