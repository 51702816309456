import React, { useMemo } from 'react';
import { Dialog as MuiDialog, Slide, Zoom } from '@mui/material';
import { CloseDialog } from './CloseDialog';
import { GetFullScreen } from '../../globalTheme';

const TransitionComponent = ({ direction, timeout, zoomTransition }) => {
  const Transition = React.forwardRef(
    /**
     * @param {import('@mui/material/transitions').TransitionProps & { children: React.ReactElement<any, any> }} props - The props passed to the Slide component.
     * @param {React.Ref<unknown>} ref - The ref passed to the Slide component for access to the DOM element.
     * @returns {React.ReactElement} A Slide component wrapped in React.forwardRef.
     */
    function Transition(props, ref) {
      if (zoomTransition) {
        return (
          <Zoom
            style={{ transformOrigin: 'top left' }}
            timeout={timeout}
            ref={ref}
            {...props}
          />
        );
      }
      return (
        <Slide direction={direction} ref={ref} {...props} timeout={timeout} />
      );
    },
  );
  return Transition;
};

const paperStyle = {
  sx: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 0,
    minHeight: '100%',
  },
};

/**
 * @param {object} props
 * @param {React.JSX.Element | Array<React.JSX.Element>} props.children
 * @param {import('@mui/material/Paper').PaperProps} [props.paperProps]
 * @param {boolean} [props.fullWidth]
 * @param {(event: any) => void} props.onClose
 * @param {import('@mui/material').Breakpoint | false} [props.maxWidth]
 * @param {boolean} props.open
 * @param {boolean} [props.slideMode]
 * @param {boolean} [props.fullScreen]
 * @param {string} [props.slideDirection]
 * @param {number} [props.slideTimeout]
 * @param {boolean} [props.onCloseXButton]
 * @param {boolean} [props.disableCloseOnBackdropClick]
 * @param {boolean} [props.disableEscapeKeyDown]
 * @param {boolean} [props.showCloseButton]
 * @param {import('@mui/material').SxProps} [props.sx]
 * @param {boolean} [props.zoomTransition]
 */
export const Dialog = ({
  children,
  paperProps = paperStyle,
  fullWidth,
  onClose,
  maxWidth = 'lg',
  open,
  slideMode = false,
  slideDirection = 'left',
  zoomTransition = false,
  slideTimeout = 400,
  onCloseXButton,
  disableCloseOnBackdropClick,
  disableEscapeKeyDown,
  showCloseButton = true,
  sx,
  ...props
}) => {
  const fullScreen = GetFullScreen();
  const style = {
    '.MuiDialog-paper': {
      borderRadius: fullScreen ? '0 !important' : '2 !important',
      borderTopRightRadius: slideMode ? '0 !important' : '2 !important',
      borderBottomRightRadius: slideMode ? '0 !important' : '2 !important',
    },
  };

  const Transition = useMemo(
    () =>
      TransitionComponent({
        direction: slideDirection,
        timeout: slideTimeout,
        zoomTransition,
      }),
    [slideDirection, slideTimeout, zoomTransition],
  );

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick' && disableCloseOnBackdropClick) return;
    onClose();
  };

  const isSlideMode = slideMode || zoomTransition;

  return (
    <MuiDialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...(isSlideMode && {
        TransitionComponent: Transition,
        ...(!zoomTransition && { PaperProps: paperProps }),
      })}
      fullScreen={fullScreen}
      onClose={handleClose}
      sx={{ ...style, ...sx }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...props}
    >
      {showCloseButton && (
        <CloseDialog handleClose={onCloseXButton ? onClose : () => {}} />
      )}
      {children}
    </MuiDialog>
  );
};
