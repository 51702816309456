import { MyHolidaysConfigType } from './MyCalendar.vars';
import {
  GetEmployeeHolidaysSummaryDataType,
  HolidayRequest,
} from './MyCalendar.types';

export function parseHolidaysRequest(
  rawHolidayRequest: object | null,
): HolidayRequest | null {
  if (!rawHolidayRequest) {
    return null;
  }

  // @ts-ignore
  return {
    ...rawHolidayRequest,
    // @ts-ignore
    daysRequested: rawHolidayRequest.daysRequested.map(
      (date: string) => new Date(date),
    ),
    // @ts-ignore
    authorizations: rawHolidayRequest.authorizations.map(
      (authorization: object) => ({
        ...authorization,
        // @ts-ignore
        updatedAt: new Date(authorization.updatedAt),
      }),
    ),
    timestamps: {
      // @ts-ignore
      createdAt: new Date(rawHolidayRequest.timestamps.createdAt),
      // @ts-ignore
      updatedAt: new Date(rawHolidayRequest.timestamps.updatedAt),
    },
  };
}

export function parseHolidaysSummary(
  getEmployeeHolidaysSummaryData: GetEmployeeHolidaysSummaryDataType,
): Omit<MyHolidaysConfigType, 'events'> {
  const {
    holidaysSummary = [],
    employmentStartDate = null,
    pendingHolidayRequest = null,
    unsignedApprovedHolidayRequest = null,
    holidaySetupError,
    rejectedHolidayRequest,
    approvedHolidayRequestToSignAsTaken,
  } = getEmployeeHolidaysSummaryData;
  return {
    holidaysSummary: holidaysSummary.map((holiday) => ({
      holidaysTaken: holiday.holidaysTaken,
      holidaysAvailable: holiday.holidaysAvailable,
      employmentStartDate: new Date(holiday.employmentStartDate),
      expired: holiday.expired,
      year: holiday.year,
      startDate: new Date(holiday.startDate),
      endDate: new Date(holiday.endDate),
    })),
    employmentStartDate: employmentStartDate
      ? new Date(employmentStartDate)
      : null,
    pendingHolidayRequest: parseHolidaysRequest(pendingHolidayRequest),
    unsignedApprovedHolidayRequest: parseHolidaysRequest(
      unsignedApprovedHolidayRequest,
    ),
    rejectedHolidayRequest: parseHolidaysRequest(rejectedHolidayRequest),
    approvedHolidayRequestToSignAsTaken: parseHolidaysRequest(
      approvedHolidayRequestToSignAsTaken,
    ),
    holidaySetupError: holidaySetupError,
  };
}
