import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { Box, InputAdornment, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TabsDialog } from '../../Dashboard/common/TabsDialog';
import { HolidaysTableTabs } from './HolidaysTable.types';
import {
  HOLIDAYS_COLUMNS,
  HOLIDAYS_TABLE_TABS,
} from './HolidaysTable.constants';
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  GET_HOLIDAYS_FROM_ROLES,
  GET_HOLIDAYS_FROM_ROLES_COUNTERS,
  REJECT_HOLIDAY_REQUEST,
} from '../Holidays.gql';
import { Icon } from '../../../../components/Icons/Icons';
import {
  Button,
  ConfirmDialog,
  DataGrid,
  IconButton,
  TextInput,
  Tooltip,
  Typography,
} from '../../../../newComponents';
import { useScreenSize } from '../../../../Hooks';
import { esES } from '@mui/x-data-grid';
import { gridAbsenceTableStyle } from '../../Absence/EmployeeAbsence.styles';
import { HolidayRequest } from '../../EmployeePortal/MyCalendar/MyCalendar.types';
import { EmptySpaceAc } from '../../../../components/Illustrations/Illustrations';
import { RejectHolidaysDialog } from './RejectHolidaysDialog';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../cache.reactiveVars';
import type { FileToView } from '../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.types';
import { openFileViewerDialogInSignableMode } from '../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import { HolidaySchemeSelector } from './HolidaySchemeSelector';

const NoRowsOverlay = () => {
  const { isMobile } = useScreenSize();
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{ ml: 2 }}
    >
      <EmptySpaceAc
        width={isMobile ? '100px' : '200px'}
        height={isMobile ? '100px' : '200px'}
      />
      <Typography variant="h5" textAlign="center">
        No se han encontrado resultados
      </Typography>
    </Stack>
  );
};

export const HolidaysTable: React.FC<{ employeeId?: string }> = ({
  employeeId,
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const client = useApolloClient();
  const user = useReactiveVar(userVar);

  const [tab, setTab] = useState<HolidaysTableTabs>(HolidaysTableTabs.PENDING);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [delayedSearch, setDelayedSearch] = useState('');
  const [timer, setTimer] = useState(null);
  const [mappedCounter, setMappedCounter] = useState<
    Record<HolidaysTableTabs, number>
  >({
    [HolidaysTableTabs.FINISHED]: 0,
    [HolidaysTableTabs.IN_PROGRESS]: 0,
    [HolidaysTableTabs.INCOMING]: 0,
    [HolidaysTableTabs.PENDING]: 0,
    [HolidaysTableTabs.REJECTED]: 0,
    [HolidaysTableTabs.ALL]: 0,
  });

  const [holidays, setHolidays] = useState<HolidayRequest[]>([]);
  const [openRejectHolidaysDialog, setOpenRejectHolidaysDialog] =
    useState(false);
  const [openConfirmHolidaysDialog, setOpenConfirmHolidaysDialog] =
    useState(false);
  const currentHolidayModifying = useRef<HolidayRequest | null>(null);

  const { loading: loadingCounters } = useQuery(
    GET_HOLIDAYS_FROM_ROLES_COUNTERS,
    {
      variables: {
        input: {
          search: delayedSearch,
          employeeId: employeeId,
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ getHolidaysFromRolesCounters }) => {
        const { counters } = getHolidaysFromRolesCounters;
        setMappedCounter({
          [HolidaysTableTabs.FINISHED]: counters.finished,
          [HolidaysTableTabs.IN_PROGRESS]: counters.inProgress,
          [HolidaysTableTabs.INCOMING]: counters.incoming,
          [HolidaysTableTabs.PENDING]: counters.pending,
          [HolidaysTableTabs.REJECTED]: counters.rejected,
          [HolidaysTableTabs.ALL]: counters.all ?? 0,
        });
      },
    },
  );

  const { data, loading, fetchMore } = useQuery(GET_HOLIDAYS_FROM_ROLES, {
    variables: {
      input: {
        page: page,
        perPage: perPage,
        search: delayedSearch,
        status: !!employeeId ? HolidaysTableTabs.ALL : tab,
        employeeId: employeeId,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const employees = data?.getHolidaysFromRoles?.data || [];
    setHolidays(
      employees.map((rawHoliday) => {
        return {
          ...rawHoliday,
          daysRequested: rawHoliday.daysRequested.map((day) => new Date(day)),
          authorizations: rawHoliday.authorizations.map((authorization) => ({
            ...authorization,
            updatedAt: new Date(authorization.updatedAt),
          })),
          timestamps: {
            createdAt: new Date(rawHoliday.timestamps.createdAt),
            updatedAt: new Date(rawHoliday.timestamps.updatedAt),
          },
        };
      }),
    );
  }, [data?.getHolidaysFromRoles?.data]);

  const [rejectHolidayRequestMutation] = useMutation(REJECT_HOLIDAY_REQUEST);

  const handlePageChange = (newPage) => {
    if (newPage < page) return setPage(newPage);
    fetchMore({
      variables: {
        input: {
          page: newPage,
          perPage: perPage,
          search: delayedSearch,
          status: !!employeeId ? HolidaysTableTabs.ALL : tab,
          employeeId: employeeId,
        },
      },
    });

    if (!loading) setPage(newPage);
  };

  const handlePerPageChage = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(0);
    fetchMore({
      variables: {
        perPage: newPerPage,
        page: 0,
      },
    });
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    if (value?.length < 3 && value !== '') return;

    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      setPage(0);
      setDelayedSearch(value);
    }, 1500);

    setTimer(newTimer);
  };

  const handleChangeTab = (
    _e: any,
    newTab: React.SetStateAction<HolidaysTableTabs>,
  ) => {
    setTab(newTab);
  };

  const clearFilters = () => {
    setSearch('');
    setDelayedSearch('');
  };

  const rejectHolidaysColumnAction = useCallback((row: HolidayRequest) => {
    setOpenRejectHolidaysDialog(true);
    currentHolidayModifying.current = row;
  }, []);

  const rejectHolidays = async (comments: string) => {
    globalBackdropVar({
      open: true,
      text: 'Rechanzando solicitud de vacaciones...',
      clickable: false,
    });
    try {
      await rejectHolidayRequestMutation({
        variables: {
          input: {
            holidayId: currentHolidayModifying.current._id,
            comments,
          },
        },
      });
      globalSnackbarVar({
        message: 'Solicitud de vacaciones rechazada',
        show: true,
        severity: 'success',
      });
      setOpenRejectHolidaysDialog(false);
      await client.refetchQueries({
        include: [GET_HOLIDAYS_FROM_ROLES, GET_HOLIDAYS_FROM_ROLES_COUNTERS],
      });
    } catch (error) {
      console.error(error);
      globalSnackbarVar({
        message: 'Hubo un error. Contacte a Sora',
        show: true,
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  const authorizeHolidaysColumnAction = useCallback((row: HolidayRequest) => {
    setOpenConfirmHolidaysDialog(true);
    currentHolidayModifying.current = row;
  }, []);

  const onConfirmDocumentSign = useCallback(async () => {
    currentHolidayModifying.current = null;
    setOpenConfirmHolidaysDialog(false);
    globalSnackbarVar({
      message: 'Solicitud de vacaciones autorizada',
      severity: 'success',
      show: true,
    });
    await client.refetchQueries({
      include: [GET_HOLIDAYS_FROM_ROLES, GET_HOLIDAYS_FROM_ROLES_COUNTERS],
    });
  }, [client]);

  const columns = useMemo(
    () =>
      HOLIDAYS_COLUMNS({
        theme,
        onRejectHolidays: rejectHolidaysColumnAction,
        onAuthorizeHolidays: authorizeHolidaysColumnAction,
        userId: user?._id,
      }),
    [
      authorizeHolidaysColumnAction,
      rejectHolidaysColumnAction,
      theme,
      user?._id,
    ],
  );

  const openSigner = () => {
    const fileId = currentHolidayModifying.current?.approveEmployeeDocumentId;
    const fileToView: FileToView = {
      id: fileId,
      title: 'Solicitud de vacaciones',
      type: 'DOCUMENT',
    };

    return openFileViewerDialogInSignableMode({
      file: fileToView,
      onSuccess: () => {
        onConfirmDocumentSign();
      },
      onNoFilesToSign: () => {
        globalSnackbarVar({
          show: true,
          message: 'No hay documentos para firmar',
          severity: 'info',
        });
      },
    });
  };

  const currTotal =
    mappedCounter[employeeId ? HolidaysTableTabs.ALL : tab] || 0;

  const slicedHolidays = holidays.slice(
    page * perPage,
    page * perPage + perPage,
  );

  return (
    <>
      <Stack
        className={'holidaysTable'}
        sx={{
          boxShadow: theme.newPalette.shadow.z12,
          width: '100%',
        }}
      >
        {!employeeId ? (
          <TabsDialog
            tab={tab}
            handleChangeTab={handleChangeTab}
            tabs={HOLIDAYS_TABLE_TABS({
              theme,
            })}
            objectToMap={mappedCounter} //counters
            hideIcon={true}
            loading={loadingCounters}
          />
        ) : null}
        <Stack
          p={3}
          sx={{
            borderLeft: `1px solid ${theme.newPalette.grey.grey200}`,
            borderRight: `1px solid ${theme.newPalette.grey.grey200}`,
          }}
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          alignItems="center"
          spacing={2}
        >
          {employeeId && <HolidaySchemeSelector />}
          <TextInput
            name="holidays_search"
            placeholder="Buscar"
            variant="outlined"
            value={search}
            onChange={(event) => handleSearchChange(event.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="search_line" />
                </InputAdornment>
              ),
            }}
            size="medium"
          />
          {!!search.length && !isMobile && (
            <Tooltip title="Borrar filtro" placement="top">
              <span>
                <IconButton
                  icon="filter_off_line"
                  color="error"
                  onClick={clearFilters}
                />
              </span>
            </Tooltip>
          )}

          {!!search.length && isMobile && (
            <Button
              variant="outlined"
              fullWidth
              color="error"
              startIcon={<Icon icon="filter_off_line" />}
              onClick={clearFilters}
            >
              Borrar filtro
            </Button>
          )}
        </Stack>
        <Box height={isMobile ? '500px' : '590px'}>
          <DataGrid
            columns={columns}
            rows={currTotal > 0 ? slicedHolidays : []}
            rowCount={currTotal}
            getRowId={(row) => row._id}
            checkboxSelection={false}
            page={page}
            pageSize={perPage}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onPageSizeChange={(newPageSize) => handlePerPageChage(newPageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            rowBuffer={10}
            paginationMode="server"
            loading={loading}
            components={{
              NoRowsOverlay: NoRowsOverlay,
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rowHeight={68}
            headerHeight={currTotal === 0 ? 0 : 56}
            hideFooter={currTotal === 0}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            hideFooterSelectedRowCount
            disableSelectionOnClick
            sx={{
              ...gridAbsenceTableStyle({ theme, isMobile }),
            }}
            getRowClassName={(params) => {
              const { row } = params;
              return row.employeeDocumentId && row.documentTitle
                ? 'cursor-pointer'
                : '';
            }}
          />
        </Box>
      </Stack>
      <RejectHolidaysDialog
        open={openRejectHolidaysDialog}
        onConfirm={(reason) => rejectHolidays(reason)}
        onClose={() => setOpenRejectHolidaysDialog(false)}
      />
      <ConfirmDialog
        open={openConfirmHolidaysDialog}
        title={'Autorizar vacaciones'}
        onConfirm={openSigner}
        onClose={() => setOpenConfirmHolidaysDialog(false)}
        confirmLabel={'Autorizar vacaciones'}
        cancelLabel={'Cancelar'}
        confirmButtonToRight={true}
        onCancel={() => setOpenConfirmHolidaysDialog(false)}
      >
        <Stack gap={2}>
          <Typography variant="body2" color={'text.secondary'}>
            Una vez autorizadas el empleado deberá solicitar la carta de
            solicitud de vacaciones.
          </Typography>
          <Typography variant="body2" color={'text.secondary'}>
            ¿Estás seguro de que quieres autorizar estas vacaciones?
          </Typography>
        </Stack>
      </ConfirmDialog>
    </>
  );
};
