import React from 'react';
import {
  HolidayRequest,
  HolidayRequestStatus,
} from '../../EmployeePortal/MyCalendar/MyCalendar.types';
import { IconOptions } from '../../../../components/Icons/IconTypes';
import { LabelColor } from '../../../../newComponents/Label/Label.types';
import { Box } from '@mui/material';
import { Tooltip } from '../../../../newComponents';
import { Icon } from '../../../../components/Icons/Icons';

const iconOptions: Record<HolidayRequestStatus, IconOptions> = {
  [HolidayRequestStatus.PENDING]: 'time_fill_line' as IconOptions,
  [HolidayRequestStatus.APPROVED]: 'check_line' as IconOptions,
  [HolidayRequestStatus.REJECTED]: 'close_circle_fill' as IconOptions,
  [HolidayRequestStatus.TAKEN]: 'check_line' as IconOptions,
  [HolidayRequestStatus.CANCELLED]: 'close_line' as IconOptions,
};

const colorOptions: Record<HolidayRequestStatus, LabelColor> = {
  [HolidayRequestStatus.PENDING]: 'warning' as LabelColor,
  [HolidayRequestStatus.APPROVED]: 'success' as LabelColor,
  [HolidayRequestStatus.REJECTED]: 'error' as LabelColor,
  [HolidayRequestStatus.TAKEN]: 'info' as LabelColor,
  [HolidayRequestStatus.CANCELLED]: 'default' as LabelColor,
};

const tooltipTitleOptions: Record<HolidayRequestStatus, string> = {
  [HolidayRequestStatus.PENDING]: 'Pendiente',
  [HolidayRequestStatus.APPROVED]: 'Aprobada',
  [HolidayRequestStatus.REJECTED]: 'Rechazada',
  [HolidayRequestStatus.TAKEN]: 'Tomadas',
  [HolidayRequestStatus.CANCELLED]: 'Cancelada',
};

type HolidayStatusTableActionProps = {
  row: HolidayRequest;
  userId: string;
};
export const HolidayStatusTableAction: React.FC<
  HolidayStatusTableActionProps
> = ({ row, userId }) => {
  const status: HolidayRequestStatus = row?.status;
  const userHasAuthorized = row.authorizations.some(
    (auth) => auth.authorizer._id === userId && auth.status === 'APPROVED',
  );

  const icon: IconOptions = iconOptions[status];
  const color: LabelColor = colorOptions[status];
  const tooltipTitle: string = tooltipTitleOptions[status];

  if (status === 'PENDING' && userHasAuthorized) {
    return (
      <Box>
        <Tooltip
          title={'Esperando a los demás autorizadores'}
          placement={'top'}
        >
          <span>
            <Icon icon={'time_line'} color={'success'} />
          </span>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box>
      <Tooltip title={tooltipTitle} placement={'top'}>
        <span>
          <Icon icon={icon} color={color} />
        </span>
      </Tooltip>
    </Box>
  );
};
