// @ts-check
import { DialogTitle } from '@mui/material';
import React from 'react';
import { Icon } from '../Icons/Icons';
import { GetFullScreen } from '../../globalTheme';
import { useTheme } from '@mui/material/styles';

/**
 * @typedef {import('../../theme').CustomTheme} CustomTheme
 */

/**
 * @param {object} props
 * @param {object} [props.props]
 * @param {(event?: any) => void} props.handleClose
 */
export const CloseDialog = ({ handleClose, props }) => {
  const fullScreen = GetFullScreen();
  /** @type {CustomTheme} */
  const theme = useTheme();
  return (
    fullScreen && (
      <DialogTitle
        sx={{
          position: 'sticky',
          width: '100%',
          height: '50px',
          backgroundColor: 'inherit',
          zIndex: 5,
          top: 0,
        }}
      >
        <Icon
          fill={theme.customPalette.grayColors.gray_40}
          icon="close_line"
          height="24px"
          style={{ position: 'absolute', right: '25px' }}
          pointer
          onClick={handleClose}
          {...props}
        />
      </DialogTitle>
    )
  );
};
