import React from 'react';
import { Stack } from '@mui/material';
import { EmptySpaceAc } from '../../../../../../components/Illustrations/Illustrations';
import { useScreenSize } from '../../../../../../Hooks';
import { Button, Typography } from '../../../../../../newComponents';
import { useTheme } from '@mui/material/styles';

type EmptyHolidayAuthorizersPlaceholderProps = {
  openAddAuthorizersDialog: () => void;
  openAddMassiveAuthorizersDialog: () => void;
};

export const EmptyHolidayAuthorizersPlaceholder: React.FC<
  EmptyHolidayAuthorizersPlaceholderProps
> = ({ openAddAuthorizersDialog, openAddMassiveAuthorizersDialog }) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      <EmptySpaceAc
        width={isMobile ? '100px' : '200px'}
        height={isMobile ? '100px' : '200px'}
      />
      <Typography variant={'h3'} textAlign={'center'} mt={4}>
        No tienes autorizadores
      </Typography>
      <Typography
        variant={'body1'}
        textAlign={'center'}
        mt={2}
        maxWidth={'400px'}
        color={theme.newPalette.text.secondary}
      >
        Define a tus autorizadores, asignales empleados y configura el orden de
        autorización de las vacaciones.
      </Typography>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        my={2}
      >
        <Button
          onClick={openAddMassiveAuthorizersDialog}
          color={'default'}
          variant={'outlined'}
        >
          Importar
        </Button>
        <Button
          onClick={openAddAuthorizersDialog}
          color={'primary'}
          variant={'contained'}
        >
          Configurar autorizadores
        </Button>
      </Stack>
    </Stack>
  );
};
