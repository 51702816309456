import { useReactiveVar } from '@apollo/client';
import { logoutDialogVar, resetLogoutDialog } from './LogoutDialog.vars';
import { ConfirmDialog } from '../../../newComponents/Dialog';
import { Typography } from '../../../newComponents/Typography';
import logout from '../../../utils/logout';

export const LogoutDialog = () => {
  const { open } = useReactiveVar(logoutDialogVar);

  const handleClose = () => {
    resetLogoutDialog();
  };

  return (
    <ConfirmDialog
      open={open}
      title="Cerrar sesión"
      onConfirm={() => {
        logout();
      }}
      onCancel={handleClose}
      onClose={handleClose}
      handleCloseIconButton={true}
      cancelLabel="Cancelar"
      confirmLabel="Cerrar sesión"
      confirmButtonToRight
      DialogProps={{ fullWidth: true, fullScreen: false }}
    >
      <Typography variant="body1">¿Quieres cerrar sesión?</Typography>
      <Typography variant="body2">
        Podrás continuar con el proceso en otro momento.
      </Typography>
    </ConfirmDialog>
  );
};
