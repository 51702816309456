import { makeVar } from '@apollo/client';
import type { RequestedEmployeeDocumentsDialogVar } from './RequestedEmployeeDocumentsDialog.types';

const requestedEmployeeDocumentsDialogDefault: RequestedEmployeeDocumentsDialogVar =
  {
    open: false,
    stepNumberLabel: '',
    requestedEmployeeDocuments: [],
    onFinish: () => {},
    onClose: () => {},
  };

export const requestedEmployeeDocumentsDialogVar = makeVar(
  requestedEmployeeDocumentsDialogDefault,
);

export const resetRequestedEmployeeDocumentsDialog = () => {
  requestedEmployeeDocumentsDialogVar(requestedEmployeeDocumentsDialogDefault);
};

export const setRequestedEmployeeDocumentsDialogVar = (
  input: Partial<RequestedEmployeeDocumentsDialogVar>,
) => {
  requestedEmployeeDocumentsDialogVar({
    ...requestedEmployeeDocumentsDialogVar(),
    ...input,
  });
};
