import React, { useEffect, useState } from 'react';
import { DialogTitle, IconButton, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { Typography, Dialog } from '../../../../../../../newComponents';
import { useTheme } from '@mui/material/styles';
import { CHAGForm } from './CHAGForm';
import {
  AuthorizationTeam,
  Steps,
} from './CreateHolidayAuthorizationTeamDialog.types';
import { AuthorizersTeam } from '../DisplayAuthorizersTeams.types';

interface CreateHolidayAuthorizationGroupDialogProps {
  open: boolean;
  onClose: () => void;
  preselectedTeam?: AuthorizersTeam;
}

export type CreateHolidayAuthorizationTeamDialogFormType = AuthorizationTeam & {
  preselectedTeam: AuthorizersTeam;
};

export const CreateHolidayAuthorizationTeamDialog: React.FC<
  CreateHolidayAuthorizationGroupDialogProps
> = ({ open, onClose, preselectedTeam }) => {
  const theme = useTheme();
  const form = useForm<CreateHolidayAuthorizationTeamDialogFormType>({
    defaultValues: {
      preselectedTeam: preselectedTeam || null,
      name: preselectedTeam?.name || '',
      description: preselectedTeam?.description || '',
      color: preselectedTeam?.color || '#2648E8',
      authorizers: preselectedTeam?.authorizers || [],
      signInOrder: preselectedTeam?.signInOrder || false,
      assignedTo: [],
      assignedToErrors: [],
    },
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { register } = form;
  const [step, setStep] = useState(Steps.General);

  useEffect(() => {
    register('authorizers', {
      required: 'Registra al menos un autorizador',
      validate: (value) =>
        value.length > 0 || 'Debes agregar al menos un autorizador',
    });

    register('assignedTo', {
      required: 'Registra al menos un empleado',
      validate: (value) =>
        value.length > 0 || 'Debes agregar al menos un empleado',
    });

    register('assignedToErrors', {
      validate: (value) =>
        value.length === 0 ||
        'Algunos empleados no se pudieron agregar. Revisa antes de continuar.',
    });
  }, [register]);

  useEffect(() => {
    if (preselectedTeam) {
      setStep(Steps.General);
      form.setValue('preselectedTeam', preselectedTeam);
      form.setValue('name', preselectedTeam.name);
      form.setValue('description', preselectedTeam.description);
      form.setValue('color', preselectedTeam.color);
      form.setValue('signInOrder', preselectedTeam.signInOrder);
      form.setValue(
        'authorizers',
        preselectedTeam.authorizers.map((a) => ({
          _id: a._id,
          names: a.names,
          lastNameP: a.lastNameP,
          lastNameM: a.lastNameM,
          rfc: a.rfc,
          curp: a.curp,
          profilePicture: a.profilePicture,
        })),
      );
      form.setValue('assignedTo', []);
    }
  }, [form, preselectedTeam]);

  useEffect(() => {
    if (!open) {
      form.reset();
      setStep(Steps.General);
    }
  }, [form, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} slideMode fullWidth maxWidth={'sm'}>
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${theme.newPalette.divider}`,
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <IconButton onClick={onClose}>
              <Icon
                fill={theme.customPalette.textColor.text_black}
                icon="close_line"
                height="20px"
              />
            </IconButton>
            <Typography variant="h6">
              {preselectedTeam ? 'Editar' : 'Agregar'} grupo de autorizadores
            </Typography>
          </Stack>
        </DialogTitle>
        <CHAGForm
          onClose={onClose}
          form={form}
          step={step}
          setStep={setStep}
          preselectedTeam={preselectedTeam}
        />
      </Dialog>
    </>
  );
};
