import { useLazyQuery } from '@apollo/client';
import { GET_USER_ONBOARDING_STEPS } from '../UserOnboardingDialog.gql';
import { useStartIdentityVerification } from '../../IdentityVerificationDialog/IdentityVerificationDialogHooks';
import { setElectronicSignatureCreationDialogVar } from '../../ElectronicSignatureCreationDialog/ElectronicSignatureCreationDialog.vars';
import { useStartSigningOnboardingDocuments } from '../../SignOnboardingDocumentsDialog/SignOnboardingDocumentsHooks';
import { setCompletePersonalInformationDialogVar } from '../../CompletePersonalInformationDialog/CompletePersonalInformationDialog.vars';
import { isVerificationTokenPresent } from '../../IdentityVerificationDialog/IdentityVerificationDialog.helpers';
import type { OnboardingStep } from '../UserOnboardingDialog.types';
import {
  setRequestedEmployeeDocumentsDialogVar,
  resetRequestedEmployeeDocumentsDialog,
} from '../../RequestedEmployeeDocumentsDialog/RequestedEmployeeDocumentsDialog.vars';
import {
  IDENTITY_VERIFICATION_SUB_STEPS,
  CREATE_ELECTRONIC_SIGNATURE_SUB_STEPS,
  ONBOARDING_DOCUMENTS_DESCRIPTIONS,
} from '../UserOnboardingDialog.constants';
import {
  setUserOnboardingDialogVar,
  userOnboardingDialogVar,
} from '../UserOnboardingDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useStartUserOnboarding() {
  const [getUserOnboardingSteps] = useLazyQuery(GET_USER_ONBOARDING_STEPS);
  const { handleStartIdentityVerification } = useStartIdentityVerification();
  const { handleStartSigningOnboardingDocuments } =
    useStartSigningOnboardingDocuments();

  const updateCurrentStep = () => {
    const { currentStep } = userOnboardingDialogVar();
    setUserOnboardingDialogVar({ currentStep: currentStep + 1 });
  };

  const hideCurrentDialog = () => {
    setUserOnboardingDialogVar({ open: false });
  };

  const showCurrentDialog = () => {
    setUserOnboardingDialogVar({ open: true });
  };

  const handleStartUserOnboarding = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Verificando registro...',
      });

      const { data } = await getUserOnboardingSteps();
      const onboardingStepsData = data.getUserOnboardingSteps;

      const {
        onboardingStatus,
        createElectronicSignature,
        signOnboardingDocuments,
        uploadRequestedDocuments,
        completeRequestedInformation,
      } = onboardingStepsData;

      const { isVisible, isCompleted } = onboardingStatus;
      if (!isVisible || isCompleted) {
        // requested information is required even if the onboarding is completed
        if (!completeRequestedInformation.completed) {
          setUserOnboardingDialogVar({ onboardingCompleted: false });
          return setCompletePersonalInformationDialogVar({
            open: true,
            showLogoutDialogAtClose: true,
            onFinish: () => {
              setUserOnboardingDialogVar({ onboardingCompleted: true });
            },
          });
        }

        return setUserOnboardingDialogVar({ onboardingCompleted: true });
      }

      let currentStep = 4;
      let onboardingSteps: OnboardingStep[] = [];

      const COMPLETE_EMPLOYMENT_RECORD_SUB_STEPS = [];
      let handleCompleteEmploymentRecord = () => {};

      // complete employment record
      if (!uploadRequestedDocuments.completed) {
        COMPLETE_EMPLOYMENT_RECORD_SUB_STEPS.push({
          label: 'Cargar documentos para completar tu expediente',
          description: '',
        });

        handleCompleteEmploymentRecord = () => {
          hideCurrentDialog();
          setRequestedEmployeeDocumentsDialogVar({
            open: true,
            stepNumberLabel: '4',
            onClose: showCurrentDialog,
            onFinish: () => {
              resetRequestedEmployeeDocumentsDialog();
              showCurrentDialog();
              updateCurrentStep();
            },
          });
        };
      }

      if (!completeRequestedInformation.completed) {
        COMPLETE_EMPLOYMENT_RECORD_SUB_STEPS.push({
          label: 'Completar tu información personal',
          description: '',
        });

        if (COMPLETE_EMPLOYMENT_RECORD_SUB_STEPS.length === 1) {
          handleCompleteEmploymentRecord = () => {
            hideCurrentDialog();
            setCompletePersonalInformationDialogVar({
              open: true,
              stepNumberLabel: '4',
              onClose: showCurrentDialog,
              onFinish: () => {
                showCurrentDialog();
                updateCurrentStep();
              },
            });
          };
        } else {
          handleCompleteEmploymentRecord = () => {
            hideCurrentDialog();
            setRequestedEmployeeDocumentsDialogVar({
              open: true,
              stepNumberLabel: '4',
              onClose: showCurrentDialog,
              onFinish: () => {
                resetRequestedEmployeeDocumentsDialog();
                setCompletePersonalInformationDialogVar({
                  open: true,
                  stepNumberLabel: '4',
                  onClose: showCurrentDialog,
                  onFinish: () => {
                    showCurrentDialog();
                    updateCurrentStep();
                  },
                });
              },
            });
          };
        }
      }

      if (COMPLETE_EMPLOYMENT_RECORD_SUB_STEPS.length > 0) {
        onboardingSteps.push({
          type: 'COMPLETE_EMPLOYMENT_RECORD',
          label: 'Completar tu expediente',
          subLabel: 'Necesitas:',
          subSteps: COMPLETE_EMPLOYMENT_RECORD_SUB_STEPS,
          actionLabel: 'Completar expediente',
          animation: 'completeEmploymentRecord',
          action: handleCompleteEmploymentRecord,
        });
        currentStep = 3;
      }

      // sign onboarding documents
      const SIGN_ONBOARDING_DOCUMENTS_SUB_STEPS =
        signOnboardingDocuments.pendingDocuments.map((doc) => {
          const description =
            ONBOARDING_DOCUMENTS_DESCRIPTIONS[doc.constantType];
          return {
            label: doc.title,
            description,
          };
        });

      onboardingSteps = [
        {
          type: 'SIGN_ONBOARDING_DOCUMENTS',
          label: 'Consentimiento de uso de sora',
          subLabel: 'Debes leer y firmar los siguientes documentos:',
          subSteps: SIGN_ONBOARDING_DOCUMENTS_SUB_STEPS,
          actionLabel: 'Firmar documentos',
          animation: 'signOnboardingDocuments',
          action: () => {
            handleStartSigningOnboardingDocuments({
              onClose: showCurrentDialog,
              onStarted: hideCurrentDialog,
              onFinish: () => {
                showCurrentDialog();
                updateCurrentStep();
              },
            });
          },
        },
        ...onboardingSteps,
      ];

      if (!signOnboardingDocuments.completed) currentStep = 2;

      // create electronic signature
      onboardingSteps = [
        {
          type: 'CREATE_ELECTRONIC_SIGNATURE',
          label: 'Crear tu NIP de firma electrónica',
          subLabel: 'Necesitas:',
          subSteps: CREATE_ELECTRONIC_SIGNATURE_SUB_STEPS,
          actionLabel: 'Crear NIP de firma electrónica',
          animation: 'createElectronicSignature',
          action: () => {
            hideCurrentDialog();
            setElectronicSignatureCreationDialogVar({
              open: true,
              stepNumberLabel: '2',
              onClose: showCurrentDialog,
              onCreationSuccess: () => {
                showCurrentDialog();
                updateCurrentStep();
              },
            });
          },
        },
        ...onboardingSteps,
      ];

      if (!createElectronicSignature.completed) currentStep = 1;

      onboardingSteps = [
        {
          type: 'IDENTITY_VERIFICATION',
          label: 'Verifica tu identidad',
          subLabel: 'Necesitas:',
          subSteps: IDENTITY_VERIFICATION_SUB_STEPS,
          actionLabel: 'Verificar identidad',
          animation: 'identityVerification',
          action: () => {
            handleStartIdentityVerification({
              stepNumberLabel: '1',
              onClose: showCurrentDialog,
              onStarted: hideCurrentDialog,
              onFinish: () => {
                showCurrentDialog();
                updateCurrentStep();
              },
            });
          },
        },
        ...onboardingSteps,
      ];

      // verify identity
      const isRemoteUser = createElectronicSignature.verificationTokenRequired;
      if (
        isRemoteUser &&
        !createElectronicSignature.completed &&
        !isVerificationTokenPresent()
      ) {
        currentStep = 0;
      }

      setUserOnboardingDialogVar({
        open: true,
        steps: onboardingSteps,
        showWelcome: !createElectronicSignature.completed,
        onboardingCompleted: false,
        currentStep,
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleStartUserOnboarding };
}
