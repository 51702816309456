import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentRequestedStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
  isEmployee?: boolean;
};

export const DocumentRequestedStatusChip = (
  props: DocumentRequestedStatusChipProps,
) => {
  const { chipProps, iconProps, isEmployee = true } = props;

  return (
    <Tooltip
      title={
        isEmployee
          ? 'Debes cargar el documento solicitado'
          : 'El empleado no ha cargado el documento solicitado'
      }
      placement="top"
      arrow
    >
      <Chip
        icon={
          <Icon
            icon="upload_2_line"
            width="24px"
            height="24px"
            {...iconProps}
          />
        }
        color="warning"
        label="Solicitado al empleado"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
