//@ts-check
import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { filledStyle, outlinedStyle, softStyle } from './labelStyles';
import { publicSans } from '../../components/Typographies/Fonts';
import { Icon } from '../../components/Icons/Icons';

const StyledLabel = styled('div')(({ theme }) => {
  // @ts-ignore
  const palette = theme.newPalette;
  return {
    '&.label-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 8px 2px 8px',
      borderRadius: '6px',
      gap: '6px',
      height: '24px',
      //styleName: Components/Label;
      fontFamily: publicSans,
      fontSize: '12px',
      fontWeight: '700',
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'center',
      ...filledStyle(palette),
      ...outlinedStyle(palette),
      ...softStyle(palette),
    },
  };
});

/**
 * @param {import('./Label.types').LabelProps} props
 */
export const Label = ({
  startIcon,
  endIcon,
  variant = 'filled',
  color = 'primary',
  disabled = false,
  label,
  textStyles = {},
  ...props
}) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const colorClassname = colorClassNames[color];
  const variantClassname = variantClassNames[variant];
  const colorExists = colorClassname !== undefined;

  return (
    <StyledLabel
      className={`label-root ${variantClassname} ${colorClassname}`}
      {...props}
      style={{ ...(!colorExists && { color }) }}
    >
      {startIcon && (
        <Stack className="label-icon">
          <Icon
            icon={startIcon}
            height="16px"
            fill={colorExists ? '' : color}
          />
        </Stack>
      )}
      <span
        className="label-label"
        style={
          disabled
            ? { color: theme.newPalette.text.disabled, ...textStyles }
            : { ...textStyles }
        }
      >
        {label}
      </span>
      {endIcon && (
        <Stack className="label-icon">
          <Icon
            icon={endIcon}
            height="16px"
            fill={colorExists ? '' : color}
            disabled={disabled}
          />
        </Stack>
      )}
    </StyledLabel>
  );
};

const colorClassNames = {
  default: 'label-colorDefault',
  primary: 'label-colorPrimary',
  secondary: 'label-colorSecondary',
  info: 'label-colorInfo',
  success: 'label-colorSuccess',
  warning: 'label-colorWarning',
  error: 'label-colorError',
};
const variantClassNames = {
  filled: 'label-filled',
  outlined: 'label-outlined',
  soft: 'label-soft',
};
