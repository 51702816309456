// @ts-check
import { ListItemIcon as MuiListItemIcon } from '@mui/material';

/**
 * @typedef {import('@mui/material/ListItemIcon').ListItemIconProps} ListItemIconProps
 * @param {ListItemIconProps} props
 */
export const ListItemIcon = (props) => {
  // @ts-ignore
  return <MuiListItemIcon {...props} />;
};
