import { gql } from '@apollo/client';

export const CREATE_HOLIDAY = gql`
  mutation createHoliday($input: CreateHolidayInput!) {
    createHoliday(input: $input) {
      __typename
    }
  }
`;

export const UPDATE_HOLIDAY = gql`
  mutation updateHoliday($input: UpdateHolidayInput!) {
    updateHoliday(input: $input) {
      __typename
    }
  }
`;

export const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($input: DeleteHolidayInput!) {
    deleteHoliday(input: $input) {
      __typename
    }
  }
`;

export const GET_COMPANY_HOLIDAYS = gql`
  query getCompanyHolidays($input: GetCompanyHolidaysInput!) {
    getCompanyHolidays(input: $input) {
      __typename
      ... on HolidaysResult {
        holidays {
          _id
          date
          title
          type
          displayTitle
          creationType
          color
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
