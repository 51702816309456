//@ts-check
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { openFileViewerDialogInVisibleOnlyMode } from '../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import React, { useEffect, useState } from 'react';
import { OpenValidationPageDialog } from '../../../ValidateXml/OpenValidationPageDialog';
import { staticDrawerColumnsReceipts } from '../../Documents/utils';
import { receiptsTableColumns } from './ReceiptsTableColumns';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userPermissionsVar,
} from '../../../../cache.reactiveVars';
import { loggerUtil } from '../../../../utils/loggerUtil';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import {
  DOWNLOAD_SIGNED_XML_RECEIPT,
  DOWNLOAD_USER_FILE,
} from '../../Documents/gql';
import { ReceiptsTableHeader } from './ReceiptsTableHeader';
import { SoraGrid } from '../../../../components/Datagrid/Datagrid';
import { GET_RECEIPTS_BY_STATUS } from '../receipts.gql';

/**
 * @param {object} props
 * @param {import('../hooks/useReceiptsTableInput').ReceiptsTableInput} props.receiptsTableInput
 * @param {boolean} props.hideFiltersButton
 * @param {boolean} props.hideColumnsButton
 */
export const ReceiptsTable = ({
  receiptsTableInput,
  hideFiltersButton,
  hideColumnsButton,
}) => {
  const { states, actions, companyIds, sort, filter, advancedFilters } =
    receiptsTableInput;
  /** @type {import('../../../../theme').CustomTheme} */
  const theme = useTheme();
  const userPermissions = useReactiveVar(userPermissionsVar);
  const [showOpenValidationPageDialog, setShowOpenValidationPageDialog] =
    useState(false);

  const { receiptsColumns } = localStorage;
  const drawerColumns = receiptsColumns ? JSON.parse(receiptsColumns) : null;
  const [enabledColumns, setEnabledColumns] = useState(
    drawerColumns || staticDrawerColumnsReceipts,
  );
  /**
   * @type {[ number[], React.Dispatch<React.SetStateAction<number[]>> ]}
   */
  const [visitedPages, setVisitedPages] = useState(
    /** @type {number[]} */ ([]),
  );

  const getReceiptsInput = {
    companyIds: companyIds,
    filter: filter,
    advancedFilter: advancedFilters,
    sort: {
      field: sort.field.toString(),
      order: sort.order.toString(),
    },
    pagination: {
      page: 0,
      perPage: 10,
    },
  };

  const {
    refetch,
    fetchMore,
    loading,
    data: { GetReceiptsByStatus: { receipts = [] } = {} } = {},
  } = useQuery(GET_RECEIPTS_BY_STATUS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: getReceiptsInput,
    },
  });

  useEffect(() => {
    const receiptsSelected = receipts.filter((receipt) =>
      states.selectedReceipts.includes(receipt.id),
    );
    if (states.selectedReceipts.length) {
      const filteredTasks = receiptsSelected.filter((task) => task.signDate);
      actions.updateSelectedSignedReceiptsLength(filteredTasks.length);
    } else {
      actions.updateSelectedSignedReceiptsLength(0);
    }
  }, [receipts, actions, states.selectedReceipts]);

  const handlePageChange = async (newPage) => {
    if (newPage < states.page) {
      return actions.updatePage(newPage);
    }
    setVisitedPages([...new Set([...visitedPages, newPage])]);
    if (visitedPages.includes(newPage)) {
      return actions.updatePage(newPage);
    }

    fetchMore({
      variables: {
        input: {
          companyIds: companyIds,
          filter: filter,
          advancedFilter: advancedFilters,
          sort: {
            field: sort.field.toString(),
            order: sort.order.toString(),
          },
          pagination: {
            page: newPage,
            perPage: states.perPage,
          },
        },
      },
    });

    if (!loading) actions.updatePage(newPage);
  };

  const handlePageSizeChange = (pageSize) => {
    setVisitedPages([]);
    actions.updatePage(0);
    actions.updatePerPage(pageSize);
    refetch({
      input: {
        companyIds: companyIds,
        filter: filter,
        advancedFilter: advancedFilters,
        sort: {
          field: sort.field.toString(),
          order: sort.order.toString(),
        },
        pagination: {
          page: 0,
          perPage: pageSize,
        },
      },
    });
  };

  const onDeleteSuccess = () => {
    setVisitedPages([]);
    actions.updatePage(0);
    refetch({
      input: {
        companyIds: companyIds,
        filter: filter,
        advancedFilter: advancedFilters,
        sort: {
          field: sort.field.toString(),
          order: sort.order.toString(),
        },
        pagination: {
          page: 0,
        },
      },
    });
  };

  useEffect(() => {
    actions.updateRefetchReceiptsFunction(refetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const updateSortModel = ({ field, sort }) => {
    setVisitedPages([]);
    actions.updatePage(0);
    actions.updateField(field.toUpperCase());
    actions.updateOrder(sort.toUpperCase());
    refetch({
      input: {
        companyIds: companyIds,
        filter: filter,
        advancedFilter: advancedFilters,
        sort: {
          field: field.toUpperCase(),
          order: sort.toUpperCase(),
        },
        pagination: {
          page: 0,
          perPage: states.perPage,
        },
      },
    });
  };

  const receiptVisualizer = ({
    employeeId: userId,
    id: docId,
    type,
    queueStatus,
    signDate,
  }) => {
    queueStatus !== 'pending' &&
      openFileViewerDialogInVisibleOnlyMode({
        userId,
        isAdmin: true,
        file: { id: docId, title: '', type: 'RECEIPT' },
      });
  };
  const [downloadFile] = useLazyQuery(DOWNLOAD_USER_FILE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ downloadUserFile }) => {
      window.open(downloadUserFile, '_self');
    },
  });

  const receiptDownload = ({ employeeId: userId, id: searchId }) => {
    downloadFile({
      variables: {
        type: 'RECEIPT',
        userId,
        searchId,
        asAttachment: true,
      },
    });
  };

  const [getSignedXml] = useLazyQuery(DOWNLOAD_SIGNED_XML_RECEIPT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const downloadSignedXml = async ({ id: receiptId }) => {
    try {
      globalBackdropVar({ open: true });
      const result = await getSignedXml({
        variables: {
          receiptId,
        },
      });

      const data = result?.data?.downloadSignedReceiptXmlFormat;
      if (data?.__typename !== 'SignedReceiptXmlFormatResult') {
        return globalSnackbarVar({
          show: true,
          message: data?.message,
          severity: 'error',
        });
      }

      window.open(data?.url, '_self');
      setShowOpenValidationPageDialog(true);
    } catch (error) {
      loggerUtil.error(error);
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const xmlDownload = async ({ employeeId: userId, id: searchId }) => {
    downloadFile({
      variables: {
        type: 'XMLRECEIPT',
        userId,
        searchId,
        asAttachment: true,
      },
    });
  };

  const handleReceiptSelection = (receiptIds) => {
    const deletableReceipts = receiptIds.filter((receiptId) => {
      const currentReceipt = receipts.find(
        (receipt) => receipt.id === receiptId,
      );
      if (currentReceipt?.employeeSignature?.employeeId) {
        return false;
      }
      return true;
    });

    actions.updateSelectedDeletableReceipts(deletableReceipts);
    actions.updateSelectedReceipts(receiptIds);
  };

  const modifiedColumns = receiptsTableColumns.map((column) => {
    return {
      ...column,
      hide: !column.unhideable && !enabledColumns[column.field],
      xmlDownload: xmlDownload,
      downloadSignedXml,
      receiptDownload: receiptDownload,
      receiptVisualizer: receiptVisualizer,
      customPalette: theme.customPalette,
      userPermissions,
    };
  });

  const startIndex = states.page * states.perPage;
  const endIndex = startIndex + states.perPage;
  const currentRows = receipts.slice(startIndex, endIndex);

  return (
    <Box display="flex" flexGrow="1">
      {/*DIALOGS*/}
      <OpenValidationPageDialog
        open={showOpenValidationPageDialog}
        setOpen={setShowOpenValidationPageDialog}
      />
      {/*END DIALOGS*/}
      <Stack sx={{ width: '100%' }}>
        <ReceiptsTableHeader
          enabledColumns={enabledColumns}
          modifiedColumns={modifiedColumns}
          setEnabledColumns={setEnabledColumns}
          receiptsTableInput={receiptsTableInput}
          hideFiltersButton={hideFiltersButton}
          hideColumnsButton={hideColumnsButton}
          onDeleteSuccess={onDeleteSuccess}
        />
        <SoraGrid
          rows={currentRows ?? []}
          columns={modifiedColumns}
          rowCount={states.counters[states.signStatus] ?? 0}
          page={states.page}
          loading={loading}
          headerRadius="0"
          borderRadius="0"
          headerColor={theme.newPalette.primary.transparent8}
          headerTextColor={theme.newPalette.primary.main}
          hideFooterPagination={loading}
          onSelectionModelChange={handleReceiptSelection}
          selectionModel={states.selectedReceipts}
          onSortModelChange={([model]) => {
            model && updateSortModel(model);
          }}
          sortingMode="server"
          pageSize={states.perPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50]}
          paginationMode="server"
          disableSelectionOnClick
          keepNonExistentRowsSelected
          hideConfig
        />
      </Stack>
    </Box>
  );
};

export default ReceiptsTable;
