import { makeVar } from '@apollo/client';
import type { LogoutDialogVar } from './LogoutDialog.types';

const logoutDialogDefault: LogoutDialogVar = {
  open: false,
};

export const logoutDialogVar = makeVar(logoutDialogDefault);

export const resetLogoutDialog = () => {
  logoutDialogVar(logoutDialogDefault);
};

export const setLogoutDialogVar = (input: Partial<LogoutDialogVar>) => {
  logoutDialogVar({
    ...logoutDialogVar(),
    ...input,
  });
};
