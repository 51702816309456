import React, { useEffect, useState } from 'react';
import { Dialog } from '../../../../../newComponents/Dialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { TextInput } from '../../../../../newComponents/TextInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ColorSelector } from '../../../../../newComponents/ColorSelector';
import { esES } from '@mui/x-date-pickers/locales';
import { es } from 'date-fns/locale';
import { useTheme } from '@mui/material/styles';
import { Icon } from '../../../../../components/Icons/Icons';
import { DocumentNode, useApolloClient, useMutation } from '@apollo/client';
import {
  CREATE_HOLIDAY,
  GET_COMPANY_HOLIDAYS,
  UPDATE_HOLIDAY,
} from '../Calendar.gql';
import { CalendarEvent } from '../../../../../newComponents/SoraCalendar/SoraCalendar.types';
import { globalBackdropVar } from '../../../../../cache.reactiveVars';
import {
  CREATE_OFFICIAL_HOLIDAY,
  GET_OFFICIAL_HOLIDAYS,
  UPDATE_OFFICIAL_HOLIDAY,
} from '../../../../Landing/SupportPanel/OfficialHolidays/OfficialHolidays.gql';

type AddHolidayDialogProps = {
  open: boolean;
  onClose: () => void;
  preselectedDate?: Date;
  preselectedEvent?: CalendarEvent;
  isSupportPanel?: boolean;
};

type Mutations = {
  create: DocumentNode;
  update: DocumentNode;
  get: DocumentNode;
};

const adminMutations: Mutations = {
  create: CREATE_HOLIDAY,
  update: UPDATE_HOLIDAY,
  get: GET_COMPANY_HOLIDAYS,
};

const soraAdminMutations: Mutations = {
  create: CREATE_OFFICIAL_HOLIDAY,
  update: UPDATE_OFFICIAL_HOLIDAY,
  get: GET_OFFICIAL_HOLIDAYS,
};

export const AddHolidayDialog: React.FC<AddHolidayDialogProps> = ({
  open,
  onClose,
  preselectedDate,
  preselectedEvent,
  isSupportPanel = false,
}) => {
  const mutations = isSupportPanel ? soraAdminMutations : adminMutations;
  const theme = useTheme();
  const client = useApolloClient();
  const [createHolidayMutation] = useMutation(mutations.create, {
    onCompleted: () => {
      client.refetchQueries({ include: [mutations.get] });
    },
  });
  const [updateHolidayMutation] = useMutation(mutations.update, {
    onCompleted: () => {
      client.refetchQueries({ include: [mutations.get] });
    },
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    preselectedDate ? preselectedDate : null,
  );
  const [holidayName, setHolidayName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [color, setColor] = useState<null | string>('#2648E8');

  useEffect(() => {
    if (preselectedDate) {
      setSelectedDate(preselectedDate);
    } else {
      setSelectedDate(null);
    }
  }, [preselectedDate]);

  useEffect(() => {
    if (preselectedEvent) {
      setSelectedDate(preselectedEvent.date);
      setHolidayName(preselectedEvent.title);
      setColor(preselectedEvent.color);
    } else {
      setSelectedDate(null);
      setHolidayName('');
      setColor('#2648E8');
    }
  }, [preselectedEvent]);

  const handleAddHoliday = async () => {
    if (!selectedDate || !holidayName) {
      setError('Debes seleccionar una fecha y un nombre');
      return;
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Agregar festivo',
      });
      await createHolidayMutation({
        variables: {
          input: {
            title: holidayName,
            date: selectedDate,
            color: color,
          },
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  const handleUpdateHoliday = async () => {
    if (!preselectedEvent) {
      setError('No se puede actualizar el festivo seleccionado');
      return onClose();
    }

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando festivo...',
      });
      await updateHolidayMutation({
        variables: {
          input: {
            _id: preselectedEvent._id,
            title: holidayName,
            date: selectedDate,
            color: color,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      globalBackdropVar({
        open: false,
      });
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} slideMode fullWidth maxWidth={'xs'}>
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.newPalette.divider}`,
        }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <IconButton onClick={onClose}>
            <Icon
              fill={theme.customPalette.textColor.text_black}
              icon="close_line"
              height="20px"
            />
          </IconButton>
          <Typography variant="h6">
            {preselectedEvent ? 'Editar' : 'Agregar'} festivo
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={2}>
          <TextInput
            label={'Nombre del festivo'}
            value={holidayName}
            onChange={(e) => setHolidayName(e.target.value)}
            fullWidth
            error={!holidayName.length}
          />
          <LocalizationProvider
            localeText={
              esES.components.MuiLocalizationProvider.defaultProps.localeText
            }
            adapterLocale={es}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              label={'Fecha'}
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              format="d MMMM yyyy"
            />
          </LocalizationProvider>
          <Stack>
            <Typography variant={'body1'}>
              <strong>Color</strong>
            </Typography>
            <ColorSelector
              color={color}
              onChange={setColor}
              ommitGradients={true}
            />
          </Stack>
          {error && <Typography color={'error'}>{error}</Typography>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} gap={2} m={2}>
          <Button variant={'outlined'} onClick={onClose} color={'default'}>
            Cancelar
          </Button>
          {preselectedEvent ? (
            <Button
              variant={'contained'}
              onClick={handleUpdateHoliday}
              color={'primary'}
            >
              Actualizar
            </Button>
          ) : (
            <Button
              variant={'contained'}
              onClick={handleAddHoliday}
              color={'primary'}
            >
              Agregar
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
