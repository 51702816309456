import React from 'react';
import { InputAdornment } from '@mui/material';
import { Icon } from '../../../../components/Icons/Icons';
import { TextInput } from '../../../../newComponents';
import { useTheme } from '@mui/material/styles';

export const HolidaySchemeSelector: React.FC = () => {
  const theme = useTheme();
  return (
    <TextInput
      size={'medium'}
      name="holidays_scheme"
      placeholder="Esquema actual"
      label="Esquema actual"
      value="Vacaciones de ley"
      sx={{ width: '300px' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <Icon icon="information_line" fill={theme.newPalette.divider} />
          </InputAdornment>
        ),
        readOnly: true,
      }}
    />
  );
};
