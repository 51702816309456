import { makeVar } from '@apollo/client';
import type { SignOnboardingDocumentsDialogVar } from './SignOnboardingDocumentsDialog.types';

const signOnboardingDocumentsDialogDefault: SignOnboardingDocumentsDialogVar = {
  open: false,
  documents: [],
  currentDocumentIndex: 0,
  onFinish: () => {},
  onClose: () => {},
};

export const signOnboardingDocumentsDialogVar = makeVar(
  signOnboardingDocumentsDialogDefault,
);

export const resetSignOnboardingDocumentsDialog = () => {
  signOnboardingDocumentsDialogVar(signOnboardingDocumentsDialogDefault);
};

export const setSignOnboardingDocumentsDialogVar = (
  input: Partial<SignOnboardingDocumentsDialogVar>,
) => {
  signOnboardingDocumentsDialogVar({
    ...signOnboardingDocumentsDialogVar(),
    ...input,
  });
};
