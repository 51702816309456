import { UserReference } from '../../../Settings/Preferences/Holidays/HolidaySchemes/holidaySchemes.types';
import React from 'react';
import { HolidaySetupErrorCode } from './MyCalendar.vars';

export type GetEmployeeHolidaysSummaryDataType = {
  holidaysSummary: Array<{
    holidaysTaken: number;
    holidaysAvailable: number;
    employmentStartDate: string;
    expired: boolean;
    year: number;
    startDate: string;
    endDate: string;
  }>;
  employmentStartDate: string;
  pendingHolidayRequest: null | object;
  unsignedApprovedHolidayRequest: null | object;
  rejectedHolidayRequest: null | object;
  approvedHolidayRequestToSignAsTaken: null | object;
  holidaySetupError?: HolidaySetupErrorCode;
};

export type EmployeeHolidaysSummary = {
  endDate: Date;
  expired: boolean;
  holidaysAvailable: number;
  holidaysTaken: number;
  startDate: Date;
  employmentStartDate: Date;
  year: number;
};

export enum HolidayRequestStatus {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  APPROVED = 'APPROVED',
  // eslint-disable-next-line no-unused-vars
  REJECTED = 'REJECTED',
  // eslint-disable-next-line no-unused-vars
  TAKEN = 'TAKEN',
  // eslint-disable-next-line no-unused-vars
  CANCELLED = 'CANCELLED',
}

export enum HolidayRequestAuthorizationStatus {
  // eslint-disable-next-line no-unused-vars
  APPROVED = 'APPROVED',
  // eslint-disable-next-line no-unused-vars
  REJECTED = 'REJECTED',
}

export type HolidayRequestAuthorization = {
  status: HolidayRequestAuthorizationStatus;
  comments: string;
  authorizer: UserReference;
  updatedAt: Date;
};

interface UserAndWorkSpaceReference {
  _id: String;
  names: String;
  lastNameP: String;
  lastNameM: String;
  rfc: String;
  curp: String;
  payrollId?: String;
  workTitle?: String;
  isDeleted: Boolean;
  currentStatus?: String;
  groups?: String[];
}

interface WorkCenterReference {
  _id: String;
  name: String;
  code: String;
}

export type Authorizer = {
  _id: string;
  rfc: string;
  names: string;
  lastNameP: string;
  lastNameM: string;
  profilePicture: string;
};

export type HolidayRequest = {
  _id: string;
  requestForYear: number;
  daysRequested: Date[];
  status: HolidayRequestStatus;
  approveEmployeeDocumentId: string;
  holidayTakenEmployeeDocumentId: string;
  comments: string;
  authorizations: HolidayRequestAuthorization[];
  employee: UserAndWorkSpaceReference & { profilePicture: string };
  workCenter: WorkCenterReference;
  authorizationTeam: {
    _id: string;
    name: string;
    description: string;
    color: React.CSSProperties['color'];
    signInOrder: boolean;
    authorizers: Authorizer[];
  };
  timestamps: {
    createdAt: Date;
    updatedAt: Date;
  };
};
