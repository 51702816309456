import React from 'react';
import { HolidayScheme } from '../holidaySchemes.types';
import { Card, CardContent, Stack } from '@mui/material';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Typography } from '../../../../../../newComponents/Typography';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { CustomTheme } from '../../../../../../theme';
import { useTheme } from '@mui/material/styles';
import { Chip } from '../../../../../../newComponents/Chip';

type HolidaysSchemeListItemProps = {
  scheme: HolidayScheme;
  openEditDialog: (scheme: HolidayScheme) => void;
};
export const HolidaysSchemeListItem: React.FC<HolidaysSchemeListItemProps> = ({
  scheme,
  openEditDialog,
}) => {
  const theme: CustomTheme = useTheme();
  return (
    <>
      <Stack>
        <Card
          sx={{
            border: `1px solid ${theme.newPalette.divider}`,
            borderRadius: '1em',
          }}
        >
          <CardContent>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              className={'HolidaysSchemeListItem_header'}
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack
                mt={1}
                justifyContent={'start'}
                alignItems={'start'}
                spacing={1}
              >
                <Icon icon={'file_list_3_line'} />
                <Typography variant={'h6'}>{scheme.title}</Typography>
              </Stack>
              <Stack>
                <IconButton
                  icon={'edit_line'}
                  sx={{
                    aspectRatio: 1,
                  }}
                  onClick={() => openEditDialog(scheme)}
                />
              </Stack>
            </Stack>
            <Stack direction={'row'} gap={2} mt={1} alignItems={'center'}>
              <Typography variant={'body2'}>Empleados:</Typography>
              <Chip
                variant={'soft'}
                color={'success'}
                label={`${scheme.employeesCount} ${scheme.employeesCount === 1 ? 'empleado' : 'empleados'}`}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
