// @ts-check
import { Menu as MuiMenu } from '@mui/material';

/**
 * @typedef {import('@mui/material/Menu').MenuProps} MenuProps
 */
/**
 * @param {MenuProps} props
 */
export const Menu = (props) => {
  // @ts-ignore
  return <MuiMenu {...props} />;
};
