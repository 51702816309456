import React, { useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { EditHolidaySchemeDialogFormType } from './EditHolidaySchemeDialog.types';
import { Stack } from '@mui/material';
import {
  AutocompleteOption,
  Employee,
} from '../../../../../../../businessComponents/EmployeesFinder/EmployeesFinder';
import { EmployeesFinder } from '../../../../../../../businessComponents/EmployeesFinder';
import {
  DeleteActions,
  RowBaseItem,
  TableComponent,
} from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { COLUMNS_TABLE_SIGNERS } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableColumns';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../../cache.reactiveVars';
import { SignersTabs } from '../../../../../../RHPod/Documents/PdfDocuments/Signers/SignersTabs';
import { ConfirmDialog } from '../../../../../../../newComponents';
import { useTheme } from '@mui/material/styles';
import {
  DELETE_EMPLOYEES_FROM_HOLIDAY_SCHEME,
  GET_EMPLOYEES_BY_HOLIDAY_SCHEME,
} from '../../holidaySchemes.gql';
import { useLazyQuery, useMutation } from '@apollo/client';
type EHSEmployeesProps = {
  form: UseFormReturn<EditHolidaySchemeDialogFormType>;
};
export const EHSEmployees: React.FC<EHSEmployeesProps> = ({ form }) => {
  const theme = useTheme();
  const palette = theme.newPalette;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const { watch } = form;
  const preselectedScheme = watch('selectedScheme');
  const [deleteEmployeesFromHolidayScheme] = useMutation(
    DELETE_EMPLOYEES_FROM_HOLIDAY_SCHEME,
  );
  const [getEmployeesByHolidayScheme] = useLazyQuery(
    GET_EMPLOYEES_BY_HOLIDAY_SCHEME,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const employees = data?.getEmployeesByHolidayScheme?.employees || [];
        const totalLoaded = data?.getEmployeesByHolidayScheme?.total || 0;
        setTotal(totalLoaded);

        const employeesAsRows = employees.map((employee) => ({
          ...employee,
          fullName: employee.name,
          type: 'Employee',
          alreadyAssigned: true,
        }));
        setRows(employeesAsRows);
        form.setValue('preloadedEmployees', employeesAsRows);
      },
    },
  );
  const [rows, setRows] = React.useState<RowBaseItem[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<RowBaseItem[]>([]);
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);

  const [errorRows, setErrorRows] = useState([]);
  const [tableTab, setTableTab] = useState<'success' | 'error'>('success');
  const [isOpenDeleteDialog, setIsOpenHolidayDialog] = useState(false);

  const deleteItemRef = useRef<null | RowBaseItem[]>(null);

  const modifiedColumns = COLUMNS_TABLE_SIGNERS.map((column) => ({
    ...column,
  }));

  const addRow = (row: AutocompleteOption) => {
    const rowsWithoutAllEmployees = rows.filter(
      (row) => row.type !== 'ALL_EMPLOYEES',
    );
    setRows([...rowsWithoutAllEmployees, row]);
  };

  const handleCheckAllEmployees = (checked: boolean) => {
    setIsCheckedAll(checked);
    if (checked) {
      setSelectedRows(tableTab === 'success' ? rows : errorRows);
    } else {
      setSelectedRows([]);
    }
  };

  const deleteRows = (action: DeleteActions, row: RowBaseItem) => {
    if (tableTab === 'success') {
      if (action === 'massive') {
        deleteItemRef.current = selectedRows;
      } else {
        deleteItemRef.current = [row];
      }
      setIsOpenHolidayDialog(true);
    } else {
      if (action === 'massive') {
        const selectedRowsIds = selectedRows.map((r) => r.user);
        setErrorRows(
          errorRows.filter((r) => !selectedRowsIds.includes(r.user)),
        );
        setSelectedRows([]);
      } else {
        setErrorRows(errorRows.filter((r) => r.user !== row.user));
      }
    }
  };

  const deleteEmployeesInBackEnd = async () => {
    const items = deleteItemRef.current;
    try {
      globalBackdropVar({
        clickable: false,
        open: true,
        text: 'Eliminando empleados...',
      });
      const selectedScheme = form.getValues('selectedScheme');
      const { data } = await deleteEmployeesFromHolidayScheme({
        variables: {
          input: {
            holidaySchemeId: selectedScheme._id,
            employeesIds: items.map((item) => item._id),
          },
        },
      });
      if (data?.deleteEmployeesFromHolidayScheme?.__typename !== 'Success') {
        globalSnackbarVar({
          show: true,
          message:
            data?.deleteEmployeesFromHolidayScheme?.message ||
            'Hubo un error al eliminar el empleado. Contacte a Sora',
          severity: 'error',
        });
      }
      setIsOpenHolidayDialog(false);
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Hubo un error al eliminar el empleado. Contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
    // removing from rows
    setRows(rows.filter((r) => !items.includes(r)));
    setSelectedRows(selectedRows.filter((r) => !items.includes(r)));
  };

  const handleOnSelectedRowsOnPaste = (foundRows) => {
    const uniqueRows = foundRows
      .filter((employee) => {
        return !rows.some((prevEmployee) => prevEmployee._id === employee._id);
      })
      .map((row) => ({ ...row, type: 'Employee' })); // this because the row is coming from the paste
    setRows((prev) => prev.concat(uniqueRows));
  };

  const handlePasterErrors = (errors) => {
    const uniqueErrors = errors.filter((employee) => {
      return !errorRows.some(
        (prevEmployee) => prevEmployee.user === employee.user,
      );
    });
    setErrorRows(uniqueErrors);
  };

  useEffect(() => {
    form.setValue(
      'assignedTo',
      rows.filter((row) => !row.alreadyAssigned),
    );
  }, [form, rows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [tableTab]);

  useEffect(() => {
    if (errorRows.length === 0) {
      setTableTab('success');
    }
    form.setValue('assignedToErrors', errorRows);
  }, [errorRows, form]);

  useEffect(() => {
    if (preselectedScheme) {
      getEmployeesByHolidayScheme({
        variables: { input: { holidaySchemeId: preselectedScheme._id, page } },
      });
    }
  }, [getEmployeesByHolidayScheme, page, preselectedScheme]);

  const handleLoadMore = () => {
    const employeesLength = rows.length;
    if (employeesLength < total) {
      setPage((prev) => {
        getEmployeesByHolidayScheme({
          variables: {
            input: { holidaySchemeId: preselectedScheme._id, page: prev + 1 },
          },
        });
        return prev + 1;
      });
    }
  };

  return (
    <Stack mt={2} gap={2}>
      <EmployeesFinder
        findBy={['EMPLOYEES', 'WORKCENTERS', 'WORKTITLES', 'GROUPS']}
        onSelectRow={(row) => {
          addRow(row);
        }}
        selectedRows={rows}
        isEmployeeSelected={(employee: Employee) =>
          rows.some((row) => row._id === employee._id)
        }
        onSelectedRowsOnPaste={handleOnSelectedRowsOnPaste}
        handlePasterErrors={handlePasterErrors}
      />
      <SignersTabs
        signersList={rows}
        signersError={errorRows}
        tab={tableTab}
        handleChange={(e, v) => setTableTab(v)}
      />
      <TableComponent
        width="100%"
        height={300}
        headerBgColor={palette.grey.transparent24}
        headerTextColor={palette.text.secondary}
        rows={tableTab === 'success' ? rows : errorRows}
        selectedRows={selectedRows}
        columns={modifiedColumns}
        handleSelectAllCheckbox={handleCheckAllEmployees}
        handleCheckboxChange={(checked, row) => {
          if (checked) {
            setSelectedRows([...selectedRows, row]);
          } else {
            setSelectedRows(
              selectedRows.filter((selectedRow) => selectedRow !== row),
            );
          }
        }}
        areAllChecked={isCheckedAll}
        handleLoadMore={tableTab === 'success' ? handleLoadMore : () => {}}
        loadedRowCount={rows.length}
        deleteRows={deleteRows}
        totalRowsCount={tableTab === 'success' ? total : errorRows.length}
      />
      <ConfirmDialog
        title={'Eliminar empleados de este esquema de vacaciones'}
        open={isOpenDeleteDialog}
        icon={'delete_bin_line'}
        confirmLabel={'Eliminar'}
        ConfirmButtonProps={{
          color: 'error',
        }}
        cancelLabel={'Cancelar'}
        onClose={() => setIsOpenHolidayDialog(false)}
        onConfirm={async () => {
          await deleteEmployeesInBackEnd();
        }}
        onCancel={() => setIsOpenHolidayDialog(false)}
      />
    </Stack>
  );
};
