import { getCollectiveUsers } from '../CreateDocumentDialog.helpers';
import { useVerifyEmployeeDataToCreateData } from '../Hooks/useVerifyEmployeeDataToCreateDocument';
import {
  globalTotalsVar,
  setGlobalTotalsVar,
} from '../CreateDocumentDialog.vars';
import { Button } from '../../../../../newComponents/Button';
import { useReactiveVar } from '@apollo/client';
import { employeeInfoDialogVar } from '../../../Employees/Employees.vars';
import type { ConfirmSignersButtonProps } from '../CreateDocumentDialog.types';
import { globalSnackbarVar } from '../../../../../cache.reactiveVars';
import { useScreenSize } from '../../../../../Hooks';

export const ConfirmSignersButton = (props: ConfirmSignersButtonProps) => {
  const { handleContent, form } = props;
  const { isMobile } = useScreenSize();
  const globalTotals = useReactiveVar(globalTotalsVar);
  const { onClose: onCloseEmployeeInfo } = useReactiveVar(
    employeeInfoDialogVar,
  );

  const { handleVerifyEmployeeData } = useVerifyEmployeeDataToCreateData();

  const verifyEmployeeData = async () => {
    const orderSignature = form.getValues('signersConfig.signInOrder');
    const companySigners = form.getValues('companySigners') ?? [];

    if (orderSignature && !companySigners?.length) {
      globalSnackbarVar({
        show: true,
        message: 'Se necesita al menos un firmante de empresa',
        severity: 'error',
        duration: 4000,
      });
      return;
    }

    const signers = form.getValues('signers') ?? [];
    const selectedVars = form.getValues('selectedVariables') ?? [];
    const sendAll = form.getValues('sendAll') ?? false;
    const myEditor = form.getValues('myEditor') ?? '';
    const preselectedEmployee = form.getValues('preselectedEmployee') ?? '';

    if (!selectedVars?.length) {
      form.setValue('verifyErrors', []);
      setGlobalTotalsVar({
        companySigners: companySigners?.length ?? 0,
      });
      handleContent('SETTINGS');
      return;
    }

    const { employeeIds, workCenterIds, workTitles, groups } =
      getCollectiveUsers({ signers });

    await handleVerifyEmployeeData({
      data: {
        employeeIds,
        workCenterIds,
        workTitles,
        groups,
        selectedVariables: myEditor ? selectedVars : [],
        sendAll: sendAll,
      },
      onSuccess: ({ errors = [], success = [] }) => {
        form.setValue('verifyErrors', errors ?? []);

        if (errors?.length) {
          handleContent('VERIFY');
          if (onCloseEmployeeInfo && preselectedEmployee) onCloseEmployeeInfo();
          return;
        }

        if (!errors?.length && !success?.length) {
          handleContent('SETTINGS');
          return;
        }

        handleContent('SETTINGS');
        setGlobalTotalsVar({
          employees: success?.length ?? 0,
        });
      },
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        size={isMobile ? 'medium' : 'large'}
        color="default"
        onClick={() => handleContent('EDITOR')}
      >
        Volver
      </Button>
      <Button
        variant="contained"
        size={isMobile ? 'medium' : 'large'}
        onClick={verifyEmployeeData}
        disabled={globalTotals?.employees === 0}
      >
        Asignar
      </Button>
    </>
  );
};
