export enum Steps {
  // eslint-disable-next-line no-unused-vars
  General,
  // eslint-disable-next-line no-unused-vars
  Authorizers,
  // eslint-disable-next-line no-unused-vars
  Employees,
}
export type Authorizer = {
  _id: string;
  names?: string;
  lastNameP?: string;
  lastNameM?: string;
  rfc?: string;
  curp?: string;
  profilePicture?: string;
};

export type OrderableAuthorizer = Authorizer & { id: string };

export type BaseAuthorizer = Pick<
  OrderableAuthorizer,
  'id' | 'names' | 'lastNameP' | 'lastNameM' | 'profilePicture'
>;

type AssigneeType =
  | 'Employee'
  | 'WorkCenter'
  | 'WorkTitle'
  | 'Group'
  | 'ALL_EMPLOYEES';

export type Assignee = {
  _id?: string; // workTitle and group does not have _id
  name?: string; // ^-- so name is used for workTitle and group
  profilePicture?: string;
  type: AssigneeType;
};

export type AssigneeError = {
  id: string;
  message: string;
  success: boolean;
  user: string;
  _id: string;
};

/**
 * this is the type created by the form in the create dialog
 */
export type AuthorizationTeam = {
  _id?: string;
  name: string;
  description: string;
  color: string;
  signInOrder: boolean;
  authorizers: Authorizer[];
  assignedTo: Assignee[];
  assignedToErrors: AssigneeError[];
};

// AuthorizationTeam fields type
export type AuthorizationTeamFields = keyof AuthorizationTeam;
export type AuthorizationTeamRequiredFields = Pick<
  AuthorizationTeam,
  'name' | 'description' | 'authorizers' | 'color'
>;
export type FormTriggerFieldsType = Record<Steps, AuthorizationTeamFields[]>;

export function getAuthorizerFullName(authorizer: BaseAuthorizer) {
  return `${authorizer.names} ${authorizer.lastNameP} ${authorizer.lastNameM}`;
}
