import { ScreenTitle } from '../../../../layoutComponents/ScreenTitle/ScreenTitle';
import { ScreenHeaderWrapper } from '../../../../layoutComponents/ScreenHeader/ScreenHeaderWrapper';
import React from 'react';

export const HolidaysHeader: React.FC = () => {
  return (
    <ScreenHeaderWrapper>
      <ScreenTitle title="Vacaciones" icon="sun_foggy_fill" />
    </ScreenHeaderWrapper>
  );
};
