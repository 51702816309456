import { useMutation, useApolloClient } from '@apollo/client';
import { MARK_ONBOARDING_AS_COMPLETED } from '../UserOnboardingDialog.gql';
import { GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS } from '../../../../containers/RHPod/EmployeePortal/gql';
import { useGetMyProfile } from '../../../../containers/RHPod/EmployeePortal/useGetMyProfile';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import {
  resetUserOnboardingDialog,
  setUserOnboardingDialogVar,
} from '../UserOnboardingDialog.vars';

export function useMarkOnboardingAsCompleted() {
  const client = useApolloClient();
  const { fetchMyProfile } = useGetMyProfile();
  const [markOnboardingAsCompleted] = useMutation(MARK_ONBOARDING_AS_COMPLETED);

  const handleMarkOnboardingAsCompleted = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Completando registro...',
      });

      const { data } = await markOnboardingAsCompleted();
      const { isCompleted } = data.markOnboardingAsCompleted;
      if (!isCompleted) {
        globalSnackbarVar({
          show: true,
          message: 'Ocurrió un error, al completar el registro',
          severity: 'error',
        });
        return;
      }

      await client.refetchQueries({
        include: [GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS],
      });
      await fetchMyProfile();
      resetUserOnboardingDialog();
      setUserOnboardingDialogVar({ onboardingCompleted: true });
      window.scrollTo(0, 0);
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleMarkOnboardingAsCompleted };
}
