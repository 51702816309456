// @ts-check
import React from 'react';
import { Avatar as MuiAvatar, Box } from '@mui/material';
import { Tooltip } from '../Tooltip';

const AvatarWrapper = ({ tooltip, children, ...props }) => {
  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, props);
        })}
      </Box>
    </Tooltip>
  );
};

/**
 * @summary Represents an avatar component with customizable color and size.
 * @param {import('./Avatar.types').AvatarProps} Props
 * @returns {React.JSX.Element}
 */
export const Avatar = ({
  color = 'default',
  size = 'medium',
  tooltip,
  cssColor,
  ...props
}) => {
  const [width, height] = [avatarSizes[size], avatarSizes[size]];
  const colorClassName = colorClassNames[color];

  let css = {};
  let backgroundProperty = {};
  if (cssColor) {
    if (cssColor?.startsWith('linear-gradient')) {
      backgroundProperty = { backgroundImage: `${cssColor} !important` };
    } else {
      backgroundProperty = { backgroundColor: `${cssColor} !important` };
    }
    css = { ...css, ...backgroundProperty };
  }
  if (tooltip) {
    return (
      <AvatarWrapper tooltip={tooltip}>
        <MuiAvatar
          sx={{ width, height, ...css }}
          {...props}
          className={[props.className, colorClassName].join(' ')}
        />
      </AvatarWrapper>
    );
  }

  return (
    <MuiAvatar
      sx={{ width, height, ...css }}
      {...props}
      className={[props.className, colorClassName].join(' ')}
    />
  );
};

export const avatarSizes = {
  tiny: '24px',
  small: '32px',
  medium: '40px',
  large: '48px',
  huge: '64px',
  giant: '96px',
};

const colorClassNames = {
  default: 'MuiAvatar-colorDefault',
  primary: 'MuiAvatar-colorPrimary',
  secondary: 'MuiAvatar-colorSecondary',
  info: 'MuiAvatar-colorInfo',
  success: 'MuiAvatar-colorSuccess',
  warning: 'MuiAvatar-colorWarning',
  error: 'MuiAvatar-colorError',
};
