import React from 'react';
import { Typography } from '../../../../../../newComponents/Typography';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomTheme } from '../../../../../../theme';
import { Button } from '../../../../../../newComponents/Button';

export const DisplayHolidaysSchemeHeader: React.FC = () => {
  const theme: CustomTheme = useTheme();

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack className={'DisplayHolidaysSchemeHeaderTitles'}>
          <Typography variant={'h4'}>Esquemas de vacaciones</Typography>
          <Typography variant={'body1'} color={theme.newPalette.text.secondary}>
            Los esquemas de vacaciones te ayudan a configurar el tipo de
            vacaciones que se asignan a tus empleados.
          </Typography>
        </Stack>
        <Button variant={'contained'} color={'primary'} disabled>
          Crear esquema personalizado
        </Button>
      </Stack>
    </>
  );
};
