import { DialogTitle, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { AuthorizersTeam } from '../DisplayAuthorizersTeams.types';
import { Typography, Dialog } from '../../../../../../../newComponents';
import { Icon } from '../../../../../../../components/Icons/Icons';
import {
  AuthorizationTeam,
  Steps,
} from '../AddAuthorizersDialog/CreateHolidayAuthorizationTeamDialog.types';
import { UATDForm } from './UATDForm';
import { RowBaseItem } from '../AddAuthorizersDialog/setEmployeesSlide/TableComponent';

interface UpdateAuthorizationGroupDialogProps {
  open: boolean;
  onClose: () => void;
  team?: AuthorizersTeam;
}

export type UpdateAuthorizersTeamDialogFormType = AuthorizationTeam & {
  team: AuthorizersTeam;
  preloadedEmployees: RowBaseItem[];
};

export const UpdateAuthorizersTeamDialog: React.FC<
  UpdateAuthorizationGroupDialogProps
> = ({ open, onClose, team }) => {
  const theme = useTheme();
  const form = useForm<UpdateAuthorizersTeamDialogFormType>({
    defaultValues: {
      team: team || null,
      name: team?.name || '',
      description: team?.description || '',
      color: team?.color || '#2648E8',
      authorizers: team?.authorizers || [],
      signInOrder: team?.signInOrder || false,
      assignedTo: [],
      assignedToErrors: [],
      preloadedEmployees: [],
    },
    mode: 'onBlur',
    shouldFocusError: true,
  });
  const { register } = form;
  const [step, setStep] = useState(Steps.General);

  const handleClose = () => {
    setStep(Steps.General);
    onClose();
  };

  useEffect(() => {
    register('authorizers', {
      required: 'Registra al menos un autorizador',
      validate: (value) =>
        value.length > 0 || 'Debes agregar al menos un autorizador',
    });

    register('assignedTo', {
      validate: (value, form) => {
        return (
          form.preloadedEmployees.length > 0 ||
          value.length > 0 ||
          'Debes agregar al menos un empleado'
        );
      },
    });

    register('assignedToErrors', {
      validate: (value) =>
        value.length === 0 ||
        'Algunos empleados no se pudieron agregar. Revisa antes de continuar.',
    });
  }, [register]);

  useEffect(() => {
    if (team) {
      setStep(Steps.General);
      form.setValue('team', team);
      form.setValue('name', team.name);
      form.setValue('description', team.description);
      form.setValue('color', team.color);
      form.setValue('signInOrder', team.signInOrder);
      form.setValue(
        'authorizers',
        team.authorizers.map((a) => ({
          _id: a._id,
          names: a.names,
          lastNameP: a.lastNameP,
          lastNameM: a.lastNameM,
          rfc: a.rfc,
          curp: a.curp,
          profilePicture: a.profilePicture,
        })),
      );
      form.setValue('assignedTo', []);
    }
  }, [form, team]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        slideMode
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle
          sx={{
            borderBottom: `1px solid ${theme.newPalette.divider}`,
          }}
        >
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <IconButton onClick={handleClose}>
              <Icon
                fill={theme.customPalette.textColor.text_black}
                icon="close_line"
                height="20px"
              />
            </IconButton>
            <Typography variant="h6">Editar grupo de autorizadores</Typography>
          </Stack>
        </DialogTitle>
        <UATDForm
          onClose={handleClose}
          form={form}
          step={step}
          setStep={setStep}
          preselectedTeam={team}
        />
      </Dialog>
    </>
  );
};
