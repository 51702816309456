import { DataGrid, esES } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { Icon } from '../Icons/Icons';
import { useTheme } from '@mui/material/styles';
import { styles } from './Datagrid.styles';

export const ColumnSettingsIcon = ({
  openColumnSettings = () => {},
  bgcolor = 'hsl(228deg 83% 98%)',
  iconColor,
  headerHeight,
}) => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      display="flex"
      width={headerHeight}
      height={headerHeight}
      bgcolor={bgcolor}
    >
      <Icon
        fill={iconColor ?? theme.customPalette.primary.blue}
        height="32px"
        icon="settings_4_line"
        onClick={() => openColumnSettings(true)}
        pointer
      />
    </Box>
  );
};

/**
 * @typedef {import('@mui/x-data-grid').DataGridProps} DataGridProps
 *
 * @typedef CustomSoraGridProps
 * @property {string} [borderRadius]
 * @property {string} [ headerRadius ]
 * @property {string} [ headerColor ]
 * @property {string} [ headerTextColor ]
 * @property {() => void} [ handleCellClick ]
 * @property {boolean} [ hideConfig ]
 * @property {boolean} [disabled]
 * @property {string} [optionNameInColumnSetting]
 * @property {string} [minHeight]
 * @property {import('@mui/material').SxProps} [datagridSx]
 *
 * @param {DataGridProps & CustomSoraGridProps} props
 */
export const SoraGrid = ({
  borderRadius,
  headerRadius,
  headerColor,
  headerTextColor,
  handleCellClick,
  hideConfig,
  headerHeight = 52,
  rowHeight = 48,
  ...props
}) => {
  const theme = useTheme();
  const styleProps = {
    borderRadius,
    headerRadius,
    headerColor,
    headerTextColor,
    rowHeight,
    headerHeight,
    ...props,
  };

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <DataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        onCellClick={handleCellClick}
        sx={styles({ props: styleProps, theme })}
        {...props}
      />
      {!hideConfig && (
        <ColumnSettingsIcon
          bgcolor={headerColor}
          iconColor={headerTextColor}
          openColumnSettings={props.openColumnSettings}
          headerHeight={props.headerHeight}
        />
      )}
    </Box>
  );
};
