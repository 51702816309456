//@ts-check

import { makeVar } from '@apollo/client';

//Constant to open dialog to add authorizers to a template
/** @type {import('./Templates.types').addAuthorizersTemplateDialogType} */
export const addAuthorizersTemplateDialogVarDefault = {
  open: false,
};
export const addAuthorizersTemplateDialogVar = makeVar(
  addAuthorizersTemplateDialogVarDefault,
);
export const resetAddAuthorizersTemplateDialogVar = () => {
  addAuthorizersTemplateDialogVar(addAuthorizersTemplateDialogVarDefault);
};

//Constant to manage the authorizers section of a template
/** @type {import('./Templates.types').TemplateItemType} */
const templateItemVarDefault = {
  _id: null,
  isOrdered: false,
  authorizers: [],
  selectedAuthorizers: [],
  templateToUpdate: null,
  updateAferSelectAdmin: false,
};
export const templateItemVar = makeVar(templateItemVarDefault);
export const resetTemplateItemVar = () => {
  templateItemVar(templateItemVarDefault);
};

/** @param {Partial<import('./Templates.types').TemplateItemType>} input */
export const setTemplateItemVar = (input) => {
  templateItemVar({
    ...templateItemVar(),
    ...input,
  });
};

//Constant to open dialog to confirm authorizers
/** @type {import('./Templates.types').confirmAuthorizersDialogType} */
export const confirmAuthorizersDialogDefault = {
  open: false,
};
export const confirmAuthorizersDialogVar = makeVar(
  confirmAuthorizersDialogDefault,
);
export const resetConfirmAuthorizersDialogVar = () => {
  confirmAuthorizersDialogVar(confirmAuthorizersDialogDefault);
};

//Constant to open dialog to preview a template
/** @type {import('./Templates.types').openPreviewTemplateDialogType} */
export const openPreviewTemplateDialogDefault = {
  open: false,
  template: null,
};
export const openPreviewTemplateDialogVar = makeVar(
  openPreviewTemplateDialogDefault,
);
export const resetOpenPreviewTemplateDialogVar = () => {
  openPreviewTemplateDialogVar(openPreviewTemplateDialogDefault);
};

//Constant to open dialog to reject a template
/** @type {import('./Templates.types').rejectTemplateDialogType} */
export const rejectTemplateDialogDefault = {
  open: false,
  templateId: null,
};
export const rejectTemplateDialogVar = makeVar(rejectTemplateDialogDefault);
export const resetRejectTemplateDialogVar = () => {
  rejectTemplateDialogVar(rejectTemplateDialogDefault);
};

//Constant to open dialog to approve a template
/** @type {import('./Templates.types').approveTemplateDialogType} */
export const approveTemplateDialogDefault = {
  open: false,
  templateId: null,
};
export const approveTemplateDialogVar = makeVar(approveTemplateDialogDefault);
export const resetApproveTemplateDialogVar = () => {
  approveTemplateDialogVar(approveTemplateDialogDefault);
};

//Constant to open dialog to create a template
/** @type {import('./Templates.types').createTemplateDialogType} */
export const createTemplateDialogDefault = {
  open: false,
};
export const createTemplateDialogVar = makeVar(createTemplateDialogDefault);
export const resetCreateTemplateDialogVar = () => {
  createTemplateDialogVar(createTemplateDialogDefault);
};

//Constant to open dialog to update a template
/** @type {import('./Templates.types').updateTemplateDialogType} */
export const updateTemplateDialogDefault = {
  open: false,
  currentTemplate: null,
  updateType: 'SETTINGS',
};
export const updateTemplateDialogVar = makeVar(updateTemplateDialogDefault);
export const resetUpdateTemplateDialogVar = () => {
  updateTemplateDialogVar(updateTemplateDialogDefault);
};

//Constant to open dialog to delete a template
/** @type {import('./Templates.types').deleteTemplateDialogType} */
export const deleteTemplateDialogDefault = {
  open: false,
  templateId: null,
  onComplete: null,
};
export const deleteTemplateDialogVar = makeVar(deleteTemplateDialogDefault);
export const resetDeleteTemplateDialogVar = () => {
  deleteTemplateDialogVar(deleteTemplateDialogDefault);
};

//Constant to open dialog to publish a template
/** @type {import('./Templates.types').publishTemplateDialogType} */
export const publishTemplateDialogDefault = {
  open: false,
  templateId: null,
};
export const publishTemplateDialogVar = makeVar(publishTemplateDialogDefault);
export const resetPublishTemplateDialogVar = () => {
  publishTemplateDialogVar(publishTemplateDialogDefault);
};

//Constant to open cards/table of templates in document creator
/** @type {import('./Templates.types').openTemplatesListDialogType} */
export const openTemplatesListDialogDefault = {
  open: false,
  onOpenTemplate: () => {},
};
export const openTemplatesListDialogVar = makeVar(
  openTemplatesListDialogDefault,
);
export const resetOpenTemplatesListDialogVar = () => {
  openTemplatesListDialogVar(openTemplatesListDialogDefault);
};

//Value to refetch templates queries after an UPDATE, DELETE OR CREATE action
export const updateTemplatesDefault = false;
export const updateTemplatesVar = makeVar(updateTemplatesDefault);

//Template selected in document creator
export const selectTemplateToInsertDefault = null;
export const selectTemplateToInsertVar = makeVar(selectTemplateToInsertDefault);
export const resetSelectTemplateToInsertVar = () => {
  selectTemplateToInsertVar(selectTemplateToInsertDefault);
};

export const resetAllFilterTemplates = () => {
  resetAddAuthorizersTemplateDialogVar();
  resetTemplateItemVar();
  resetConfirmAuthorizersDialogVar();
  resetOpenPreviewTemplateDialogVar();
  resetRejectTemplateDialogVar();
  resetApproveTemplateDialogVar();
  resetCreateTemplateDialogVar();
  resetUpdateTemplateDialogVar();
  resetDeleteTemplateDialogVar();
  resetSelectTemplateToInsertVar();
};

export const templatesReactiveVars = {
  addAuthorizersTemplateDialogVar: {
    read: () => addAuthorizersTemplateDialogVar(),
  },
  templateItemVar: {
    read: () => templateItemVar(),
  },
  getAllCompanyAdmins: {
    keyArgs: ['filter'],
    /** @type {import('../../../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.admins || [];
      const incomingElements = incoming?.admins || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.admins = merged;
      return result;
    },
  },
};
