import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { DOCUMENT_STATUS } from '../RequestedEmployeeDocumentDialog.constants';
import { GET_REQUESTED_EMPLOYEE_DOCUMENTS } from '../RequestedEmployeeDocumentsDialog.gql';
import {
  requestedEmployeeDocumentsDialogVar,
  setRequestedEmployeeDocumentsDialogVar,
} from '../RequestedEmployeeDocumentsDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export const useGetRequestedEmployeeDocuments = () => {
  const { loading, data } = useQuery(GET_REQUESTED_EMPLOYEE_DOCUMENTS, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al cargar documentos',
        severity: 'error',
      });
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({ open: true, text: 'Cargando documentos...' });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  useEffect(() => {
    const requestedEmployeeDocuments =
      data?.getRequestedEmployeeDocuments || [];

    setRequestedEmployeeDocumentsDialogVar({ requestedEmployeeDocuments });

    if (!requestedEmployeeDocuments.length) return;

    const thereAreRejectedOrNotUploadedDocuments =
      requestedEmployeeDocuments.some(
        (document) =>
          document.status === DOCUMENT_STATUS.REJECTED ||
          document.status === DOCUMENT_STATUS.NOT_UPLOADED,
      );
    if (!thereAreRejectedOrNotUploadedDocuments) {
      const { onFinish } = requestedEmployeeDocumentsDialogVar();
      onFinish();
    }
  }, [data]);
};
