import { makeVar } from '@apollo/client';

type ElectronicSignatureCreationDialogVar = {
  open: boolean;
  stepNumberLabel: string;
  onCreationSuccess: () => void;
  onClose?: () => void;
};

const electronicSignatureCreationDialogDefault: ElectronicSignatureCreationDialogVar =
  {
    open: false,
    stepNumberLabel: '',
    onCreationSuccess: () => {},
    onClose: () => {},
  };

export const electronicSignatureCreationDialogVar = makeVar(
  electronicSignatureCreationDialogDefault,
);

export const resetElectronicSignatureCreationDialog = () => {
  electronicSignatureCreationDialogVar(
    electronicSignatureCreationDialogDefault,
  );
};

export const setElectronicSignatureCreationDialogVar = (
  input: Partial<ElectronicSignatureCreationDialogVar>,
) => {
  electronicSignatureCreationDialogVar({
    ...electronicSignatureCreationDialogVar(),
    ...input,
  });
};
