//@ts-check
import { useTheme } from '@mui/system';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../Typography/Typography';
import { FileUploadWrapper } from './FileUploadComponent.styles';

/**
 * @summary FileUploadComponent for displaying a file upload area.
 * @param {import('./FileUpload.types').FileUploadComponentProps} props
 */
export const FileUploadComponent = ({
  disabled = false,
  onlyIcon = false,
  title = 'Subir fotografía',
  iconSize = '18px',
  status = 'default',
  sx,
  content,
}) => {
  /** @type {import('./../../theme').CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <>
      <FileUploadWrapper
        sx={{ ...(sx && { ...sx }) }}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={1}
        onlyIcon={onlyIcon}
        disabled={disabled}
        status={status}
      >
        {content && content}
        {!content && (
          <Icon
            height={iconSize}
            style={{ width: '33px', textAlign: 'center' }}
            fill={palette.text.disabled}
            icon={'upload_cloud_3_line'}
          />
        )}
        {!content && !onlyIcon && (
          <Typography variant="caption" color={palette.text.disabled}>
            {title}
          </Typography>
        )}
      </FileUploadWrapper>
    </>
  );
};
