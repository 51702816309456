import React, { useState } from 'react';
import { Button, Dialog, IconButton } from '../../../../../../../newComponents';
import { DialogActions, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import {
  DownloadAdminFileConstants,
  DownloadImportTemplateScreen,
} from './DownloadImportTemplateScreen';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  GET_HOLIDAY_AUTHORIZERS_TEAMS,
  IMPORT_AUTHORIZERS_TEAM,
  RETRY_IMPORT_AUTHORIZERS_TEAM,
} from '../../../Holidays.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../../cache.reactiveVars';
import {
  ImportAuthorizersTeamRow,
  ImportError,
  ImportSteps,
} from './MassiveAuthorizersTeamsImport.types';
import { ImportMassiveAuthorizersErrorSlide } from './ImportMassiveAuthorizersErrorSlide';

export const MassiveAuthorizersTeamsImport: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const client = useApolloClient();
  const [importAuthorizersTeamMutation] = useMutation(IMPORT_AUTHORIZERS_TEAM);
  const [retryImportAuthorizersTeam] = useMutation(
    RETRY_IMPORT_AUTHORIZERS_TEAM,
  );
  const [step, setStep] = useState(ImportSteps.DOWNLOAD_TEMPLATE);
  const [importErrors, setImportErrors] = useState<ImportError[]>([]);
  const [loadedRows, setLoadedRows] = useState<ImportAuthorizersTeamRow[]>([]);

  const handleClose = () => {
    setStep(ImportSteps.DOWNLOAD_TEMPLATE);
    onClose();
  };

  const handleFileUpload = async (files: File[]) => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Importando autorizadores...',
      });
      const { data } = await importAuthorizersTeamMutation({
        variables: {
          input: {
            file: files[0],
          },
        },
      });
      switch (data.importAuthorizersTeam.__typename) {
        case 'Error':
          globalSnackbarVar({
            show: true,
            message: data.importAuthorizersTeam.message,
          });
          break;
        case 'ImportAuthorizersTeamError':
          setStep(ImportSteps.UPLOAD_TEMPLATE_ERROR);
          setImportErrors(data.importAuthorizersTeam.errors);
          setLoadedRows(data.importAuthorizersTeam.rows);
          break;
        default:
          globalSnackbarVar({
            show: true,
            message: 'Autorizadores importados correctamente.',
          });
          handleClose();
          client.refetchQueries({ include: [GET_HOLIDAY_AUTHORIZERS_TEAMS] });
          break;
      }
    } catch (e) {
      console.error(e);
      globalSnackbarVar({
        show: true,
        message: 'Error al importar autorizadores. Contacte a soporte.',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  const handleOmitErrors = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Importando autorizadores...',
      });
      const { data } = await retryImportAuthorizersTeam({
        variables: {
          input: {
            rows: loadedRows.map((r) => ({
              team: r.team,
              employeeRfc: r.employeeRfc,
              signInOrder: r.signInOrder,
              authorizers: r.authorizers,
              line: r.line,
            })),
          },
        },
      });

      switch (data.retryImportAuthorizersTeam.__typename) {
        case 'Error':
          globalSnackbarVar({
            show: true,
            message: data.retryImportAuthorizersTeam.message,
          });
          break;
        case 'ImportAuthorizersTeamError':
          setStep(ImportSteps.UPLOAD_TEMPLATE_ERROR);
          setImportErrors(data.retryImportAuthorizersTeam.errors);
          setLoadedRows(data.retryImportAuthorizersTeam.rows);
          break;
        default:
          globalSnackbarVar({
            show: true,
            message: 'Autorizadores importados correctamente.',
          });
          handleClose();
          break;
      }
    } catch (e) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Error al importar autorizadores. Contacte a soporte.',
      });
    } finally {
      globalBackdropVar({
        open: false,
      });
    }
  };

  return (
    <>
      <Dialog
        slideMode
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle>
          <IconButton icon="close_line" onClick={handleClose} />
          Actualización masiva
        </DialogTitle>
        <DialogContent dividers sx={{ display: 'flex' }}>
          {step === ImportSteps.DOWNLOAD_TEMPLATE && (
            <DownloadImportTemplateScreen
              downloadAdminFileConstant={
                DownloadAdminFileConstants.AUTHORIZERS_FORM
              }
              onFileUpload={async (files) => {
                await handleFileUpload(files);
              }}
            />
          )}
          {step === ImportSteps.UPLOAD_TEMPLATE_ERROR && (
            <ImportMassiveAuthorizersErrorSlide errors={importErrors} />
          )}
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button variant="outlined" color="default" onClick={handleClose}>
            Cancelar
          </Button>
          {step === ImportSteps.UPLOAD_TEMPLATE_ERROR && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOmitErrors}
              disabled={!loadedRows.length}
              tooltipText={
                !loadedRows.length && 'No hay registros para importar'
              }
            >
              Continuar y omitir errores
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
