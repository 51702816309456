import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Stack,
  Stepper,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Dialog, ConfirmDialog } from '../../../newComponents/Dialog';
import { Button } from '../../../newComponents/Button';
import { Step } from '../../../newComponents/Step';
import { StepLabel } from '../../../newComponents/StepLabel';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { useScreenSize } from '../../../Hooks';
import { LottieAnimationSection } from '../../LottieAnimation';
import {
  electronicSignatureWorkflowDialogVar,
  resetElectronicSignatureWorkflowDialog,
} from './ElectronicSignatureWorkflowDialog.vars';
import { WORKFLOW_TYPES } from './ElectronicSignatureWorkflowDialog.constants';

export function ElectronicSignatureWorkflowDialog() {
  const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);
  const { isMobile } = useScreenSize();
  const { open, steps, currentStep, workflow, onStartWorkflow } =
    useReactiveVar(electronicSignatureWorkflowDialogVar);

  const allStepsCompleted = currentStep >= steps.length;

  const handleCancel = () => {
    setShowConfirmCloseDialog(true);
  };

  const handleFinish = () => {
    resetElectronicSignatureWorkflowDialog();
  };

  const handleConfirmClose = () => {
    resetElectronicSignatureWorkflowDialog();
    setShowConfirmCloseDialog(false);
  };

  if (allStepsCompleted) {
    return (
      <Dialog fullWidth maxWidth="xl" open={open}>
        <DialogTitle>
          <Stack height="40px"></Stack>
        </DialogTitle>
        <DialogContent sx={{ borderBottom: 'none' }}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            sx={{ minHeight: isMobile ? '100%' : '65vh' }}
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <Stack
              flex={isMobile ? 0 : 1}
              justifyContent="center"
              alignItems="center"
            >
              <LottieAnimationSection animation="soriWink" />
            </Stack>
            <Stack flex={isMobile ? 0 : 1}>
              <Stack maxWidth={isMobile ? '100%' : '470px'}>
                <Stack mb={3} gap={2}>
                  <Typography
                    variant={isMobile ? 'h5' : 'h3'}
                    textAlign={isMobile ? 'center' : 'left'}
                  >
                    ¡Bien hecho!
                  </Typography>
                  <Typography
                    variant={isMobile ? 'body2' : 'body1'}
                    textAlign={isMobile ? 'center' : 'left'}
                  >
                    {workflow === WORKFLOW_TYPES.CREATION
                      ? 'Has creado tu NIP de firma electrónica, ya puedes volver a firmar tus documentos pendientes.'
                      : 'Haz recuperado tu NIP de firma electrónica, ya puedes volver a firmar tus documentos pendientes.'}
                  </Typography>
                </Stack>
                <Stepper
                  activeStep={currentStep}
                  orientation="vertical"
                  sx={{ width: '100%' }}
                >
                  {steps.map((label) => (
                    <Step key={label} active>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {!isMobile && (
                  <Stack mt={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleFinish}
                    >
                      Finalizar
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: isMobile ? '16px 24px' : '24px' }}>
          {isMobile && (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              onClick={handleFinish}
            >
              Finalizar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog fullWidth maxWidth="xl" onClose={handleCancel} open={open}>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              {isMobile && (
                <Typography variant="subtitle2">
                  {workflow === WORKFLOW_TYPES.CREATION
                    ? 'Crea tu NIP'
                    : '¿Olvidaste tu NIP?'}
                </Typography>
              )}
            </Stack>
            <IconButton icon="close_line" onClick={handleCancel} />
          </Stack>
        </DialogTitle>
        <DialogContent dividers={isMobile} sx={{ borderBottom: 'none' }}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            sx={{ minHeight: isMobile ? '100%' : '65vh' }}
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <Stack
              flex={isMobile ? 0 : 1}
              justifyContent="center"
              alignItems="center"
            >
              {workflow === WORKFLOW_TYPES.CREATION ? (
                <LottieAnimationSection animation="soriWink" />
              ) : (
                <LottieAnimationSection animation="revokeElectronicSignature" />
              )}
            </Stack>
            <Stack flex={isMobile ? 0 : 1} width="100%">
              <Stack maxWidth={isMobile ? '100%' : '470px'}>
                {!isMobile && (
                  <Typography variant="h3" mb={3}>
                    {workflow === WORKFLOW_TYPES.CREATION
                      ? 'Crea tu NIP'
                      : '¿Olvidaste tu NIP?'}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  mb={3}
                  textAlign={isMobile ? 'center' : 'left'}
                >
                  {workflow === WORKFLOW_TYPES.CREATION
                    ? 'Para crear tu NIP deberás:'
                    : 'Para recuperarlo deberás:'}
                </Typography>
                <Stepper
                  activeStep={currentStep}
                  orientation="vertical"
                  sx={{ width: '100%' }}
                >
                  {steps.map((label) => (
                    <Step key={label} active>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {!isMobile && (
                  <Stack mt={3} direction="row" gap={2}>
                    <Button
                      variant="outlined"
                      color="default"
                      size="large"
                      onClick={handleCancel}
                      fullWidth
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={onStartWorkflow}
                      fullWidth
                    >
                      Empecemos
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: isMobile ? '16px 24px' : '24px' }}>
          {isMobile && (
            <Stack direction="row" gap={2} width="100%">
              <Button
                variant="outlined"
                color="default"
                size="medium"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                size={isMobile ? 'medium' : 'large'}
                onClick={onStartWorkflow}
                fullWidth
              >
                Empecemos
              </Button>
            </Stack>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="Atención"
        severity="error"
        open={showConfirmCloseDialog}
        cancelLabel="Volver"
        confirmLabel="Finalizar el proceso"
        onConfirm={handleConfirmClose}
        onClose={() => setShowConfirmCloseDialog(false)}
        onCancel={() => setShowConfirmCloseDialog(false)}
        DialogProps={{
          fullScreen: false,
          fullWidth: true,
          sx: { borderRadius: '16px' },
        }}
      >
        <Stack>
          <Typography variant="body1" color="grey">
            Estás a punto de cancelar el proceso.
          </Typography>
        </Stack>
      </ConfirmDialog>
    </>
  );
}
