import { Color } from '../../../../newComponents/Button/Button.types';

export enum HolidaysTableTabs {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  REJECTED = 'REJECTED',
  // eslint-disable-next-line no-unused-vars
  IN_PROGRESS = 'IN_PROGRESS',
  // eslint-disable-next-line no-unused-vars
  INCOMING = 'INCOMING',
  // eslint-disable-next-line no-unused-vars
  FINISHED = 'FINISHED',
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL', // for single employee table
}

export type HolidayTableTabItem = {
  label: string;
  key: HolidaysTableTabs;
  color: Color;
  textColor: string;
};
