import { useLazyQuery } from '@apollo/client';
import { GET_TEMPLATE } from '../../gql';
import { TemplateRowDetail } from '../Template.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { useCallback } from 'react';

export type GetTemplateInput = {
  templateId: string;
  onSuccess: (template: TemplateRowDetail) => void;
};

export const useGetTemplate = () => {
  const [GetTemplate] = useLazyQuery(GET_TEMPLATE, {
    fetchPolicy: 'cache-and-network',
  });

  const handleGetTemplate = useCallback(
    async (input: GetTemplateInput) => {
      try {
        const { templateId, onSuccess } = input;
        globalBackdropVar({
          open: true,
          clickable: false,
          text: 'Abriendo plantilla...',
        });

        const templateRes = await GetTemplate({
          query: GET_TEMPLATE,
          variables: { _id: templateId },
          fetchPolicy: 'network-only',
        });

        const { getTemplate } = templateRes.data;
        if (getTemplate.__typename === 'TemplateResult') {
          onSuccess(getTemplate);
        }
        if (getTemplate.__typename === 'ResourceNotFound') {
          globalSnackbarVar({
            show: true,
            severity: 'error',
            message: 'La plantilla no se encuentra registrada',
          });
        }
      } catch (error) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Ocurrió un error, contacte a Sora',
        });
      } finally {
        globalBackdropVar({ open: false });
      }
    },
    [GetTemplate],
  );

  return { handleGetTemplate };
};
