export type ImportError = {
  message: string;
  line: number;
  type: string;
  value: string;
};

export type ImportAuthorizersTeamRow = {
  team: string;
  employeeRfc: string;
  signInOrder: Boolean;
  authorizers: string[];
  line: number;
};

// eslint-disable-next-line no-unused-vars
export enum ImportSteps {
  // eslint-disable-next-line no-unused-vars
  DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE',
  // eslint-disable-next-line no-unused-vars
  UPLOAD_TEMPLATE = 'UPLOAD_TEMPLATE',
  // eslint-disable-next-line no-unused-vars
  UPLOAD_TEMPLATE_ERROR = 'UPLOAD_TEMPLATE_ERROR',
}
