import { gql } from '@apollo/client';

export const GET_MY_COMPANY_HOLIDAYS = gql`
  query getMyCompanyHolidays($input: GetCompanyHolidaysInput!) {
    getMyCompanyHolidays(input: $input) {
      __typename
      ... on HolidaysResult {
        holidays {
          _id
          date
          title
          type
          displayTitle
          creationType
          color
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const GET_EMPLOYEE_HOLIDAYS_SUMMARY = gql`
  query getEmployeeHolidaysSummary {
    getEmployeeHolidaysSummary {
      __typename
      ... on GetEmployeeHolidaysSummaryResult {
        holidaySetupError
        holidaysSummary {
          year
          holidaysTaken
          holidaysAvailable
          employmentStartDate
          startDate
          endDate
          expired
        }
        pendingHolidayRequest {
          _id
          requestForYear
          daysRequested
          status
          approveEmployeeDocumentId
          holidayTakenEmployeeDocumentId
          comments
          authorizations {
            status
            comments
            updatedAt
            authorizer {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
          timestamps {
            createdAt
            updatedAt
          }
        }
        unsignedApprovedHolidayRequest {
          _id
          requestForYear
          daysRequested
          status
          approveEmployeeDocumentId
          holidayTakenEmployeeDocumentId
          comments
          authorizations {
            status
            comments
            updatedAt
            authorizer {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
          timestamps {
            createdAt
            updatedAt
          }
        }
        rejectedHolidayRequest {
          _id
          requestForYear
          daysRequested
          status
          approveEmployeeDocumentId
          holidayTakenEmployeeDocumentId
          comments
          authorizations {
            status
            comments
            updatedAt
            authorizer {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
          timestamps {
            createdAt
            updatedAt
          }
        }
        approvedHolidayRequestToSignAsTaken {
          _id
          requestForYear
          daysRequested
          status
          approveEmployeeDocumentId
          holidayTakenEmployeeDocumentId
          comments
          authorizations {
            status
            comments
            updatedAt
            authorizer {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
          timestamps {
            createdAt
            updatedAt
          }
        }
        employmentStartDate
      }
      ... on Error {
        message
      }
    }
  }
`;

export const CREATE_HOLIDAY_REQUEST = gql`
  mutation createHolidayRequest($input: createHolidayRequestInput!) {
    createHolidayRequest(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;

export const CANCEL_HOLIDAY_REQUEST = gql`
  mutation cancelHolidayRequest($input: CancelHolidayRequestInput!) {
    cancelHolidayRequest(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;
