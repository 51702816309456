import { useLazyQuery } from '@apollo/client';
import { sifeEndpoints } from '../../../../API/sifeEndpoints';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { GET_MY_USER_PROFILE } from '../../../RHPod/EmployeePortal/gql';
import { GET_USER_DATA_TYPES } from '../ProfileDialog.constants';

export type UseGetUserDataType = {
  onSuccess?: (response: any) => void;
};

export function useGetUserData() {
  const [getMyUserProfile] = useLazyQuery(GET_MY_USER_PROFILE);

  const handleGetUserData = async (data: UseGetUserDataType) => {
    const { onSuccess } = data;

    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo información de usuario...',
      });

      const [response, userProfileRes] = await Promise.all([
        sifeEndpoints.userData.get({}, {}),
        getMyUserProfile(),
      ]);

      const { user: userProfile } = userProfileRes?.data?.getMyProfile ?? {};

      if (response.status === 200 || response?.status === 204) {
        if (onSuccess) {
          onSuccess({
            ...response?.data?.data,
            birthdate: userProfile?.birthdate,
          });
        }
        return;
      }

      const errors = response.data?.errors ?? [];
      const [errorType] = errors.map((error) => error.type);

      if (GET_USER_DATA_TYPES[errorType]) {
        return globalSnackbarVar({
          show: true,
          message: GET_USER_DATA_TYPES[errorType].message,
          severity: 'error',
        });
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener usuario',
        severity: 'error',
      });
    } catch {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error, contacte a Sora',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleGetUserData };
}
