import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { Avatar } from '../../../newComponents/Avatar';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { useScreenSize } from '../../../Hooks';
import { CompletePersonalInformationContent } from './CompletePersonalInformationContent';
import { setLogoutDialogVar } from '../LogoutDialog/LogoutDialog.vars';
import {
  completePersonalInformationDialogVar,
  resetCompletePersonalInformationDialog,
} from './CompletePersonalInformationDialog.vars';

export const CompletePersonalInformationDialog = () => {
  const { isMobile } = useScreenSize();
  const { open, stepNumberLabel, showLogoutDialogAtClose, onClose } =
    useReactiveVar(completePersonalInformationDialogVar);

  const handleClose = () => {
    if (showLogoutDialogAtClose) {
      return setLogoutDialogVar({ open: true });
    }
    resetCompletePersonalInformationDialog();
    onClose && onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
      onClose={handleClose}
    >
      <DialogTitle component="div" sx={{ padding: '16px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2} alignItems="center">
            {stepNumberLabel && (
              <Avatar color="primary" size="tiny">
                {stepNumberLabel}
              </Avatar>
            )}
            <Typography variant="h6">Completa tu expediente</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <IconButton
              icon="close_line"
              onClick={handleClose}
              aria-label="Cerrar diálogo"
            />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <CompletePersonalInformationContent />
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Stack height="40px"></Stack>
      </DialogActions>
    </Dialog>
  );
};
