import { Stack } from '@mui/material';
import { useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';
import { PdfViewer } from '../../../components/PdfViewer';
import { CircularProgress } from '../../../newComponents/Progress';
import { Icon } from '../../../components/Icons/Icons';
import { Button } from '../../../newComponents/Button';
import { IconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';
import { DOWNLOAD_USER_FILE } from '../../../containers/RHPod/Documents/gql';
import { DOWNLOAD_MY_FILE } from '../../../containers/RHPod/EmployeePortal/gql';
import { useScreenSize } from '../../../Hooks';
import {
  fileViewerDialogVar,
  resetFileViewerDialog,
} from './FileViewerDialog.vars';

export const FileViewerContent = () => {
  const { isMobile } = useScreenSize();
  const fileViewerDialog = useReactiveVar(fileViewerDialogVar);
  const { isAdmin, userId, file, isCarta, mode } = fileViewerDialog;

  const QUERY = isAdmin ? DOWNLOAD_USER_FILE : DOWNLOAD_MY_FILE;
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      type: isCarta ? 'CARTA' : file?.type,
      searchId: file?.id,
      userId: userId || undefined,
      asAttachment: false,
    },
  });

  const [downloadFile] = useLazyQuery(DOWNLOAD_USER_FILE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      window.open(res.downloadUserFile, '_self');
    },
  });

  const handleDownloadFile = async () => {
    await downloadFile({
      variables: {
        type: 'DOCUMENT',
        searchId: file?.id,
        userId: userId || undefined,
        asAttachment: true,
      },
    });
  };

  const documentUrl = data?.downloadUserFile || data?.downloadMyFile?.url || '';

  if (loading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', minHeight: '80vh' }}
      >
        <CircularProgress size={80} />
      </Stack>
    );
  }

  if (!documentUrl) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', minHeight: '80vh' }}
        gap={2}
      >
        <Icon icon="alert_line" height="50px" color="error" />
        <Typography variant="h6" customColor color="white">
          No se pudo cargar el documento
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={resetFileViewerDialog}
        >
          Cerrar e intentar de nuevo
        </Button>
      </Stack>
    );
  }

  if (
    mode === 'VISIBLE_ONLY' &&
    ['png', 'jpg', 'jpeg'].includes(fileViewerDialog.fileExtension)
  ) {
    return (
      <>
        <Stack
          alignItems="flex-end"
          justifyContent="center"
          sx={{
            position: 'absolute',
            backgroundColor: '#2b2e36',
            height: '40px',
            padding: '0 10px',
            width: '100%',
            zIndex: 1,
          }}
        >
          <IconButton
            icon="download_2_line"
            color="white"
            size="small"
            onClick={() => handleDownloadFile()}
          />
        </Stack>
        <Stack
          p={2}
          sx={{
            marginTop: '40px',
            boxSizing: 'border-box',
            overflow: 'visible',
            alignItems: 'center',
            overflowY: 'hidden',
            minHeight: isMobile ? 'auto' : '80vh',
          }}
        >
          <img
            src={documentUrl}
            alt={file?.title || 'Imagen'}
            style={{
              height: '100%',
              width: isMobile ? '100%' : '80%',
              objectFit: 'contain',
            }}
          />
        </Stack>
      </>
    );
  }

  return <PdfViewer pdfUrl={documentUrl} />;
};
