import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../components/Icons/Icons';
import { Switch } from '../../../../newComponents/Switch/Switch';
import { Upload } from '../../../../newComponents/Upload/Upload';
import { splitInBatches } from '../../../../utils/utils';
import { FormControlLabel } from '../../../Landing/Development/Components/FormControlLabel/FormControlLabel';
import { VALIDATE_USER_INFO } from '../../Employees/Employees.gql';
import { ConfirmDialog } from '../../../../newComponents/Dialog';
import { mapAllFiles } from './PdfDocuments.utils';
import { Typography } from '../../../../newComponents/Typography/Typography';
import { ErrorsTable } from './ErrorsTable';
import { filesVar } from '../../../../cache.reactiveVars';

export const UploadDocumentsDialog = ({
  open,
  onClose,
  setOpenDocumentList,
  accumulateDocs,
  originalFiles = [],
  employee,
}) => {
  const theme = useTheme();
  const palette = theme.newPalette;

  const [detectFileName, setDetectFileName] = useState(false);
  const [openFileLimitDialog, setOpenFileLimitDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateUserInfo] = useMutation(VALIDATE_USER_INFO);
  const [openErrorsTable, setOpenErrorsTable] = useState(false);
  const maxFiles = 250;

  const [files, setFiles] = useState({
    acceptedFiles: [],
    rejectedFiles: [],
  });

  const setTimeLoading = (hasErrors) => {
    setTimeout(() => {
      setLoading(false);
      if (!hasErrors && !accumulateDocs) {
        return setOpenDocumentList(true);
      }
      if (!hasErrors && accumulateDocs) {
        onClose(detectFileName);
      }
    }, 1000);
  };

  const validateFilesFromServer = async (files) => {
    const filesToVerifyBatch = splitInBatches({
      array: files,
      size: 10,
    });

    const finalResponses = [];
    for (let index = 0; index < filesToVerifyBatch.length; index++) {
      const batches = filesToVerifyBatch[index];
      const promisesResults = await Promise.all(
        batches.map(async (file) => {
          try {
            const result = await validateUserInfo({
              variables: { input: { file: file.name } },
            });
            const response = result?.data?.validateUserInfo;
            return {
              ...file,
              assignedTo: response.success
                ? [
                    {
                      _id: response?.user?._id,
                      fullName: response?.user?.fullName,
                      profilePicture: response?.user?.profilePicture,
                      workCenterName: response?.user?.workCenterName,
                      type: 'Employee',
                    },
                  ]
                : false,
            };
          } catch (error) {
            return {
              ...file,
            };
          }
        }),
      );
      finalResponses.push(...promisesResults);
    }
    setLoading(false);
    filesVar([...finalResponses, ...originalFiles]);
    if (!accumulateDocs) {
      return setOpenDocumentList(true);
    }
    onClose(detectFileName);
  };

  const handleChangeUpload = async (acceptedFiles = [], rejectedFiles = []) => {
    setLoading(true);
    const allFiles = [...acceptedFiles, ...rejectedFiles];
    const totalFiles = allFiles.length + originalFiles.length;
    if (totalFiles > maxFiles) {
      setLoading(false);
      return setOpenFileLimitDialog(true);
    }
    if (rejectedFiles.length) {
      if (!accumulateDocs) {
        filesVar([]);
      }
      setFiles({
        acceptedFiles: [...acceptedFiles],
        rejectedFiles: [...rejectedFiles],
      });
      setOpenErrorsTable(true);
      setLoading(false);
      return;
    }

    const { modifiedFilesArr } = mapAllFiles(allFiles, employee);
    if (!detectFileName) {
      filesVar([...modifiedFilesArr, ...originalFiles]);
      setTimeLoading(false);
      return;
    }
    if (detectFileName) {
      await validateFilesFromServer(modifiedFilesArr);
    }
  };

  const handleCloseErrorsTable = () => {
    setOpenErrorsTable(false);
    setFiles({
      acceptedFiles: [],
      rejectedFiles: [],
    });
  };

  return (
    <>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth="xs"
        onClose={onClose}
        showCloseButton={false}
      >
        <DialogTitle display="flex" component="div" alignItems="center" gap={2}>
          <IconButton onClick={onClose} disabled={loading}>
            <Icon
              fill={palette.text.secondary}
              icon="close_line"
              height="20px"
            />
          </IconButton>
          <Typography variant="h6">Enviar documentos PDF</Typography>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ py: 3, px: '20px', display: 'flex', flexDirection: 'column' }}
        >
          {loading && (
            <Stack
              sx={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 3,
              }}
            >
              <CircularProgress color="primary" />
              <Typography variant="subtitle1">Cargando archivos...</Typography>
            </Stack>
          )}

          {!loading && (
            <>
              {!employee && (
                <Stack sx={{ mb: 3 }}>
                  <FormControlLabel
                    onChange={(e, newValue) => setDetectFileName(newValue)}
                    value="option1"
                    control={
                      <Switch
                        color="primary"
                        checked={detectFileName}
                        sx={{
                          '&.MuiSwitch-sizeMedium': {
                            marginLeft: '-10px',
                          },
                        }}
                      />
                    }
                    label="Detectar firmantes desde el nombre del archivo"
                    labelPlacement="end"
                    sx={{
                      fontWeight: '600',
                      marginRight: '0px',
                      marginLeft: '0px',
                    }}
                  />
                  <Typography variant="caption" color={palette.text.secondary}>
                    Al activar esta opción, se buscará el RFC del firmante
                    dentro del nombre del archivo y se le asignará de forma
                    automática. Si no se detecta podrás agregarlo de forma
                    manual.
                  </Typography>
                </Stack>
              )}
              <Upload
                variant={'illustration'}
                orientation={'column'}
                accept={
                  employee ? 'image/png, image/jpeg, .pdf' : 'application/pdf'
                }
                title="Seleccionar archivos"
                onChange={handleChangeUpload}
                validateExtBeforeUpload
                //The limit increased to 250 files, but the copy is showing at 100 for users on purpose.
                maxFiles={maxFiles}
                sx={{
                  flex: 1,
                  display: 'flex',
                  backgroundColor: palette.grey.grey200,
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '250px',
                }}
                content={
                  <Stack
                    component="ul"
                    pt={1}
                    sx={{ color: palette.grey.grey500 }}
                  >
                    <li>
                      <Typography
                        variant="body2"
                        color={palette.text.secondary}
                      >
                        Solo se pueden cargar hasta 100 archivos
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="body2"
                        color={palette.text.secondary}
                      >
                        Cada archivo debe pesar hasta 25 MB
                      </Typography>
                    </li>
                  </Stack>
                }
              />
            </>
          )}
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title="Atención"
        open={openFileLimitDialog}
        onClose={() => setOpenFileLimitDialog(false)}
        onConfirm={() => setOpenFileLimitDialog(false)}
        confirmLabel="Volver a intentar"
      >
        <Typography variant="body1" color={palette.text.secondary}>
          Solo se puede cargar hasta 100 archivos PDF.
          <br />
          Verifica tu selección y vuelve a intentarlo
        </Typography>
      </ConfirmDialog>
      {openErrorsTable && (
        <ErrorsTable
          open={openErrorsTable}
          onClose={handleCloseErrorsTable}
          files={files}
          onContinue={handleChangeUpload}
        />
      )}
    </>
  );
};
