import { HolidaysTableTabs, HolidayTableTabItem } from './HolidaysTable.types';
import { CustomTheme } from '../../../../theme';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton, Tooltip, Typography } from '../../../../newComponents';
import {
  IconOptions,
  IconColors,
} from '../../../../components/Icons/IconTypes';
import { EmployeeAvatar } from '../../../../businessComponents/EmployeeAvatar';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { PopoverComments } from './PopoverComments';
import {
  HolidayRequest,
  HolidayRequestStatus,
} from '../../EmployeePortal/MyCalendar/MyCalendar.types';
import { Stack } from '@mui/material';
import { AuthorizersAvatarGroup } from './AuthorizersAvatarGroup';
import { HolidayStatusTableAction } from './HolidayStatusTableAction';

export const HOLIDAYS_TABLE_TABS = ({
  theme,
}: {
  theme: CustomTheme;
}): HolidayTableTabItem[] => [
  {
    label: 'Pendientes por revisar',
    key: HolidaysTableTabs.PENDING,
    color: 'warning',
    textColor: theme.newPalette.warning.dark,
  },
  {
    label: 'Rechazadas',
    key: HolidaysTableTabs.REJECTED,
    color: 'error',
    textColor: theme.newPalette.error.dark,
  },
  {
    label: 'Vacaciones en progreso',
    key: HolidaysTableTabs.IN_PROGRESS,
    color: 'success',
    textColor: theme.newPalette.grey.grey600,
  },
  {
    label: 'Próximas vacaciones',
    key: HolidaysTableTabs.INCOMING,
    color: 'default',
    textColor: theme.newPalette.grey.grey600,
  },
  {
    label: 'Vacaciones finalizadas',
    key: HolidaysTableTabs.FINISHED,
    color: 'default',
    textColor: theme.newPalette.grey.grey600,
  },
];

type HOLIDAYS_COLUMNS_props = {
  theme: CustomTheme;
  onRejectHolidays: (holiday: HolidayRequest) => void;
  onAuthorizeHolidays: (holiday: HolidayRequest) => void;
  userId?: string;
};

export const HOLIDAYS_COLUMNS: ({
  theme,
  onRejectHolidays,
  onAuthorizeHolidays,
  userId,
}: HOLIDAYS_COLUMNS_props) => GridColDef[] = ({
  theme,
  onRejectHolidays,
  onAuthorizeHolidays,
  userId,
}) => [
  {
    field: 'employee',
    headerName: 'Empleado',
    hideable: true,
    width: 250,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const fullName = `${row?.employee?.names} ${row?.employee?.lastNameP} ${row?.employee?.lastNameM}`;
      return (
        <Stack
          direction={'row'}
          gap={1}
          justifyContent={'flex-start'}
          alignItems={'center'}
          px={1}
          sx={{ width: '100%' }}
        >
          <Tooltip title={fullName} placement="top">
            <span>
              <EmployeeAvatar
                profilePicture={row?.employee?.profilePicture}
                name={row?.employee?.names}
                size="tiny"
              />
            </span>
          </Tooltip>
          <Stack alignItems={'flex-start'}>
            <Typography component={'div'} variant="body2">
              {fullName}
            </Typography>
            <Typography
              component={'div'}
              variant="body2"
              color={theme.newPalette.text.secondary}
            >
              {row?.workCenter?.name}
            </Typography>
          </Stack>
        </Stack>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Estatus',
    hideable: true,
    width: 100,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row }: { row: HolidayRequest } = params;
      return <HolidayStatusTableAction userId={userId} row={row} />;
    },
  },
  {
    field: 'startDate',
    headerName: 'Inicio',
    hideable: true,
    width: 120,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const startDate = row?.daysRequested[0];
      const date = startDate
        ? format(new Date(startDate), 'dd MMM yyyy', {
            locale: es,
          })
        : 'N/A';
      return <Typography variant="body2">{date}</Typography>;
    },
  },
  {
    field: 'endDate',
    headerName: 'Termino',
    hideable: true,
    width: 120,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const { daysRequested } = row;
      const endDate = daysRequested[daysRequested.length - 1];
      const date = endDate
        ? format(new Date(endDate), 'dd MMM yyyy', {
            locale: es,
          })
        : 'N/A';

      return <Typography variant="body2">{date}</Typography>;
    },
  },
  {
    field: 'comments',
    headerName: 'Comentarios',
    hideable: true,
    width: 120,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      return <PopoverComments holiday={row} />;
    },
  },
  {
    field: 'authorizers',
    headerName: 'Autorizadores',
    hideable: true,
    width: 300,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row }: { row: HolidayRequest } = params;

      return (
        <AuthorizersAvatarGroup
          authorizers={row.authorizationTeam.authorizers}
          signInOrder={row.authorizationTeam.signInOrder}
          handleOpenAddSigners={() => {}}
          handleOpenSignersPopover={() => {}}
        />
      );
    },
  },
  {
    field: 'buttons',
    headerName: '',
    hideable: false,
    width: 300,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      const { row } = params;
      const status: HolidayRequestStatus = row?.status;

      const userHasAuthorized = row.authorizations.some(
        (auth) => auth.authorizer._id === userId,
      );

      return (
        <Stack direction={'row'}>
          {status === HolidayRequestStatus.PENDING && !userHasAuthorized && (
            <>
              <Tooltip title={'Autorizar vacaciones '} placement={'top'}>
                <span>
                  <IconButton
                    onClick={() => onAuthorizeHolidays(row)}
                    icon="checkbox_circle_fill"
                    color="success"
                  />
                </span>
              </Tooltip>
              <Tooltip title={'Rechazar vacaciones '} placement={'top'}>
                <span>
                  <IconButton
                    onClick={() => onRejectHolidays(row)}
                    icon="close_circle_fill"
                    color="error"
                  />
                </span>
              </Tooltip>
            </>
          )}
        </Stack>
      );
    },
  },
];

export const menuActionsItems: {
  label: string;
  icon: IconOptions;
  color: IconColors;
  action: string;
}[] = [
  {
    label: 'Cancelar',
    icon: 'close_line',
    color: 'error',
    action: 'CANCEL',
  },
];
