export const workcenterFields = [
  {
    id: 'name',
    name: 'workcenterName',
    label: 'Nombre del Centro de Trabajo',
    required: true,
    maxLength: 70,
    defaultHelperText:
      'El nombre se debe escribir tal y como aparece en los recibos de nomina',
  },
  {
    id: 'code',
    name: 'workcenterCode',
    label: 'Clave del Centro de Trabajo',
    required: true,
    maxLength: 70,
    defaultHelperText:
      'La clave es un identificador, siglas o número de Centro de Trabajo',
  },
  {
    id: 'streetName',
    name: 'streetName',
    label: 'Calle',
    required: true,
  },
  {
    id: 'externalNumber',
    name: 'externalNumber',
    label: 'No. Exterior',
    required: true,
  },
  {
    id: 'internalNumber',
    name: 'internalNumber',
    label: 'No. Interior',
    required: false,
  },
  {
    id: 'colony',
    name: 'colony',
    label: 'Colonia',
    required: true,
  },
  {
    id: 'postalCode',
    name: 'postalCode',
    label: 'C.P.',
    required: true,
  },
];

export const formErrorMapper = {
  workcenterName: ({ error }) => {
    return {
      pattern: 'Nombre no válido',
      required: 'Campo requerido',
      maxLength: 'Máximo 70 caracteres',
    }[error.type];
  },
  workcenterCode: ({ error }) => {
    return {
      pattern: 'Codigo no válido',
      required: 'Campo requerido',
      maxLength: 'Máximo 70 caracteres',
    }[error.type];
  },
  streetName: ({ error }) => {
    return {
      pattern: 'Calle no válida',
      required: 'Campo requerido',
    }[error.type];
  },

  externalNumber: ({ error }) => {
    return {
      pattern: 'Número exterior no válido',
      required: 'Campo requerido',
    }[error.type];
  },

  internalNumber: ({ error }) => {
    return {
      pattern: 'Número interior no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  colony: ({ error }) => {
    return {
      validate: 'Colonia no válida',
      required: 'Campo requerido',
    }[error.type];
  },
  postalCode: ({ error }) => {
    return {
      validate: 'C.P no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  country: ({ error }) => {
    return {
      validate: 'País no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  state: ({ error }) => {
    return {
      validate: 'Estado no válido',
      required: 'Campo requerido',
    }[error.type];
  },
  city: ({ error }) => {
    return {
      validate: 'ciudad no válida',
      required: 'Campo requerido',
    }[error.type];
  },
  payrollId: ({ error }) => {
    return {
      validate: 'Número de nómina invalido',
      required: 'Campo requerido',
    }[error.type];
  },
};

export const functionalities = {
  employee: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  receipt: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  document: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  workCenter: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
};

export const buildDefaultvalues = ({ name, code, addressData }) => {
  return {
    workcenterName: name,
    workcenterCode: code,
    streetName: addressData?.streetName,
    externalNumber: addressData?.externalNumber,
    internalNumber: addressData?.internalNumber,
    colony: addressData?.colony,
    postalCode: addressData?.postalCode,
    state: { name: addressData?.federalEntity.state } || '',
    country: { name: addressData?.federalEntity.country } || '',
    city: addressData?.federalEntity.city || '',
  };
};

export const staticDrawerColumns = {
  payrollId: true,
  fullName: true,
  rfc: true,
  curp: true,
  workTitle: true,
  workCenterName: true,
  alta: true,
  createdAt: true,
  bajaDate: true,
  unsignedDocuments: true,
  signedDocuments: true,
  cantBeSignedDocuments: true,
  unsignedReceipts: true,
  signedReceipts: true,
  cantBeSignedReceipts: true,
  employmentStartDate: false,
};

export const dialogClass = {
  dialog: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 0,
    minHeight: '100vh',
  },
};

const statusFilterMap = {
  total: null,
  activeUsers: 'ACTIVE',
  inactiveUsers: 'PREREGISTERED',
  absentUsers: 'ABSENT',
  bajaUsers: 'BAJA',
  suingUsers: null,
};
export const getMappedStatuses = ({ filter }) => {
  let legalStatus = null;
  if (filter === 'suingUsers') {
    legalStatus = 'SUING';
  }

  return {
    status: statusFilterMap[filter],
    legalStatus,
  };
};
