import React from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import { RenderExpandedText } from '../../../../../../../../components/ExpandedText/RenderExpandedText';
import { IconButton } from '../../../../../../../../newComponents/IconButton';
import {
  BaseAuthorizer,
  getAuthorizerFullName,
} from '../CreateHolidayAuthorizationTeamDialog.types';
import { newPaletteType } from '../../../../../../../../theme';
import { Avatar } from '../../../../../../../../newComponents/Avatar';
import { DeleteActionType } from './TableComponent';

export type AuthorizersTableColumnType = {
  id: string;
  label: string;
  align: 'left' | 'right' | 'center';
  disabledPadding: boolean;
  style: SxProps;
  render: (params: {
    row: BaseAuthorizer;
    palette: newPaletteType;
    handleDeleteItems: (action: DeleteActionType, row?: BaseAuthorizer) => void;
    selectedRows: BaseAuthorizer[];
  }) => React.JSX.Element;
};

export const COLUMNS_TABLE_AUTHORIZERS: AuthorizersTableColumnType[] = [
  {
    id: 'name',
    label: 'Nombre',
    align: 'left',
    disabledPadding: true,
    style: {
      '&.MuiTableCell-root': {
        border: 'none',
        pl: 0.5,
      },
    },
    render: ({ row, palette }) => {
      const name = getAuthorizerFullName(row);
      const profilePicture = row.profilePicture;
      const AvatarValue = () => {
        if (profilePicture) {
          return <Avatar src={profilePicture} size={'small'} />;
        } else {
          return (
            <Avatar
              size={'small'}
              color={'primary'}
            >{`${name.charAt(0)}`}</Avatar>
          );
        }
      };

      const CustomText = () => {
        return (
          <Stack width="250px ">
            <Typography variant="subtitle2">{name}</Typography>

            <RenderExpandedText
              arrow
              tooltipMaxWidth="10px"
              text={'Autorizador'}
            >
              <Typography
                variant="body2"
                color={palette.text.secondary}
                component="span"
              >
                Autorizador
              </Typography>
            </RenderExpandedText>

            <Typography
              variant="body2"
              color={palette.text.secondary}
            ></Typography>
          </Stack>
        );
      };

      return (
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <AvatarValue />
          <Stack flexWrap="wrap" width="100%" whiteSpace="normal">
            <CustomText />
          </Stack>
        </Stack>
      );
    },
  },
  {
    id: 'delete',
    label: '',
    align: 'right',
    disabledPadding: true,
    style: {
      '&.MuiTableCell-root': {
        border: 'none',
      },
    },
    render: ({ row, handleDeleteItems, selectedRows = [] }) => {
      const rowIsSelected = selectedRows.find((s) => s.id === row.id);
      return (
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          <Stack>
            <IconButton
              icon="delete_bin_line"
              size="small"
              color="error"
              className={rowIsSelected ? '' : 'delete_button'}
              onClick={() => handleDeleteItems('single', row)}
            />
          </Stack>
        </Stack>
      );
    },
  },
];
