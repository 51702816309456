// @ts-check
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButton as MuiIconButton } from '../../../newComponents/IconButton';
import { Typography } from '../../../newComponents/Typography';

/**
 * @typedef {import('./MobileViewerDocument').PdfUrl} PdfUrl
 *
 * @typedef {object} PdfData
 * @property {number} numPages
 * @property {number} currentPage
 *
 * @typedef {object} MobileToolbarProps
 * @property {PdfUrl} pdfUrl
 * @property {PdfData} pdfData
 * @property {number} scale
 * @property {() => void} handleZoomIn
 * @property {() => void} handleZoomOut
 * @property {() => void} handleResetZoom
 */

const IconButton = styled(MuiIconButton)(() => ({
  color: '#fff',
  ':disabled': {
    color: '#495057',
  },
}));

/** @param {MobileToolbarProps} props */
export const MobileToolbar = (props) => {
  const {
    pdfUrl,
    pdfData,
    scale,
    handleZoomIn,
    handleZoomOut,
    handleResetZoom,
  } = props;

  function getFileNameFromURL() {
    const splittedURL = pdfUrl.split('/');
    return splittedURL[splittedURL.length - 1];
  }

  const handleDownload = async () => {
    const fileName = pdfUrl.startsWith('data:application/pdf')
      ? 'download.pdf'
      : getFileNameFromURL();

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = fileName;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#2b2e36',
        height: '40px',
        padding: '0 10px',
      }}
    >
      <Box sx={{ display: 'flex', width: '100px' }}>
        <Typography
          variant="subtitle1"
          component="div"
          fontWeight="bold"
          color="#fff"
        >
          {pdfData.currentPage} / {pdfData.numPages}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <IconButton
          icon="zoom_out_line"
          size="small"
          disabled={scale <= 1}
          onClick={handleZoomOut}
          color="white"
        />

        <IconButton
          icon="zoom_in_line"
          size="small"
          disabled={scale >= 2}
          onClick={handleZoomIn}
          color="white"
        />
        <IconButton
          icon="aspect_ratio_line"
          size="small"
          onClick={handleResetZoom}
          color="white"
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100px', justifyContent: 'flex-end' }}>
        <IconButton
          icon="download_2_line"
          size="small"
          onClick={handleDownload}
          color="white"
        />
      </Box>
    </Box>
  );
};
