import { DocumentNode, gql } from '@apollo/client';

export const GET_COMPANY_SCHEMES: DocumentNode = gql`
  query getCompanySchemes {
    getCompanySchemes {
      __typename
      ... on GetCompanySchemesResult {
        schemes {
          _id
          scheme {
            ... on DaysEarnedByYear {
              days
              year
            }
          }
          approveTemplateId
          companyId
          title
          authorizersTeamId
          holidayTakenTemplateId
          type
          employeesCount
        }
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_HOLIDAY_SCHEME: DocumentNode = gql`
  query getEmployeesByHolidayScheme($input: getEmployeesByHolidaySchemeInput!) {
    getEmployeesByHolidayScheme(input: $input) {
      __typename
      ... on getEmployeesByHolidaySchemeResult {
        employees {
          _id
          name
          profilePicture
          type
          workCenterName
        }
        total
      }
    }
  }
`;

export const UPDATE_HOLIDAY_SCHEME: DocumentNode = gql`
  mutation updateHolidayScheme($input: UpdateHolidaySchemeInput!) {
    updateHolidayScheme(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;

export const DELETE_EMPLOYEES_FROM_HOLIDAY_SCHEME: DocumentNode = gql`
  mutation deleteEmployeesFromHolidayScheme(
    $input: deleteEmployeesFromHolidaySchemeInput!
  ) {
    deleteEmployeesFromHolidayScheme(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;
