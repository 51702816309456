//@ts-check
import { makeVar } from '@apollo/client';
import { workTitlesReactiveVars } from './containers/Settings/Preferences/WorkTitles/WorkTitles.vars';
import { groupsReactiveVars } from './containers/Settings/Preferences/Groups/Groups.vars';
import { employeesFinderReactivesVars } from './businessComponents/EmployeesFinder/EmployeesFinder.vars';
import { supportDialogReactiveVars } from './businessComponents/SupportDialog/SupportDialog.vars';
import { recordChecklistReactiveVars } from './containers/Settings/Preferences/RecordChecklist/RecordChecklist.vars';
import { sendOnboardingNotifDialogVar } from './containers/RHPod/Employees/Employees.vars';
import { navBarStateVar } from './businessComponents/NavBar/NavBar.vars';
import { employeeTableVars } from './containers/RHPod/Employees/EmployeeTable/EmployeeTable.vars';
import { employeesStatusFilterVars } from './businessComponents/EmployeesCounterByStatus/EmployeesCounterByStatus.vars';
import { changeEmployeeLegalStatusDialogVar } from './containers/RHPod/Employees/Dialogs/ChangeEmployeeLegalStatusDialog/ChangeEmployeeLegalStatusDialog.vars';
import { commercialPanelReactiveVars } from './containers/Landing/CommercialPanel/ClientAnalitics/CommercialPanel.vars';
import { customersBillingReactiveVars } from './containers/Landing/CommercialPanel/CustomersBilling.vars';
import { userAndRolesReactiveVars } from './containers/MyCompany/Settings/UsersAndRoles/UserAndRoles.vars';
import { dashboardReactiveVars } from './containers/RHPod/Dashboard/Dashboard.vars';
import { employeeAbsenceReactiveVars } from './containers/RHPod/Absence/EmployeeAbsence.vars';
import { templatesReactiveVars } from './containers/MyCompany/Settings/Preferences/Templates/Templates.vars';
import { myPortalReactiveVars } from './containers/RHPod/EmployeePortal/MyPortal/MyPortal.vars';

/**
 * @typedef {object} GlobalSnackbarParams
 * @property {boolean} [show=false]
 * @property {string} [message='']
 * @property {'success'|'info'|'warning'|'error'} [severity='success']
 * @property {number} [duration=6000]
 */

/** @type {GlobalSnackbarParams}  */
const globalSnackbar = {
  show: false,
  message: '',
  severity: 'success',
  duration: 6000,
};

/**
 * @typedef {object} GlobalBackdropParams
 * @property {number} [total=100]
 * @property {number} [current=0]
 * @property {'circular'|'linear'} [feedbackType='circular']
 * @property {boolean} [open=false]
 * @property {boolean} [clickable=true]
 * @property {'determinate'|'indeterminate'} [variant='indeterminate']
 * @property {string} [text='']
 */

/** @type {GlobalBackdropParams}  */
const globalBackdrop = {
  total: 100,
  current: 0,
  feedbackType: 'circular',
  open: false,
  clickable: true,
  variant: 'indeterminate',
  text: '',
};

/**
 * @typedef {object} RefetchFunctionsParams
 * @property {() => any} [refetchEmployees]
 * @property {() => any} [refetchReceipts]
 * @property {() => any} [refetchDocuments]
 * @property {() => any} [refetchFixedEmployeeCount]
 * @property {() => any} [refetchReceiptCount]
 * @property {() => any} [refetchCredits]
 * @property {() => any} [refetchPermissions]
 * @property {() => any} [refetchCurrentUser]
 * @property {() => any} [refetchDataChangeRequest]
 * @property {() => any} [refetchAllTemplates]
 * @property {() => any} [refetchCompanyEmployees]
 * @property {() => any} [refetchCompanyEmployeesCounter]
 */

/** @type {RefetchFunctionsParams}  */
const refetchFunctions = {
  refetchEmployees: () => null,
  refetchReceipts: () => null,
  refetchDocuments: () => null,
  refetchFixedEmployeeCount: () => null,
  refetchReceiptCount: () => null,
  refetchCredits: () => null,
  refetchPermissions: () => null,
  refetchCurrentUser: () => null,
  refetchDataChangeRequest: () => null,
  refetchAllTemplates: () => null,
  refetchCompanyEmployees: () => null,
  refetchCompanyEmployeesCounter: () => null,
};

export const isLoggedInVar = makeVar(!!localStorage.token);
export const currentCompanyVar = makeVar(
  localStorage.currentCompany ? JSON.parse(localStorage.currentCompany) : null,
);
export const userCompaniesVar = makeVar(
  localStorage.userCompanies ? JSON.parse(localStorage.userCompanies) : null,
);
export const userRolesVar = makeVar(
  localStorage.userRoles
    ? localStorage.userRoles !== 'undefined'
      ? JSON.parse(localStorage.userRoles)
      : null
    : null,
);
export const currentRoleVar = makeVar(
  localStorage.currentRole ? JSON.parse(localStorage.currentRole) : null,
);
export const profileDialogVar = makeVar(false);
export const dialogOpenVar = makeVar({
  profile: false,
  addEmployee: false,
  addMassiveEmployee: false,
  addWorkCenter: false,
  addWorkCenterMasive: false,
  importCredits: false,
  downloadMyDocuments: false,
  sessionExpired: false,
  changeEmployeeStatus: false,
  sendRecovery: false,
  adminPanel: false,
  downloadCartas: false,
  addNotice: false,
  addMasterUser: false,
  welcomeDialog: false,
  onConfirm: () => {},
  onClose: () => {},
});
export const genericDialogVar = makeVar({
  open: false,
});

export const moduleBlockedDialogVar = makeVar({
  open: false,
});
export const employeeSearchVar = makeVar('');
export const dashboardFiltersVar = makeVar({
  workcenter: [],
  dateFilter: null,
  userTypes: [],
});
export const documentCountVar = makeVar({
  all: 0,
  signed: 0,
  unsigned: 0,
  unsignable: 0,
  waitingMySignature: 0,
  waitingCompSigners: 0,
  waitingEmployees: 0,
  signedByMe: 0,
});

export const globalSnackbarVar = makeVar(globalSnackbar);
export const globalSnackbarVarGenericError = () => {
  globalSnackbarVar({
    show: true,
    message: 'Hubo un error. Contacte a Sora',
    severity: 'error',
  });
};

export const globalWarningsVar = makeVar({ open: false, warnings: [] });
/**
 *
 * @type {import('@apollo/client').ReactiveVar<{refetch: (() => void), selectedTask: *[], open: boolean}>}
 */
export const globalHideDocumentsDialogVar = makeVar({
  open: false,
  selectedTask: [],
  refetch: () => {},
});
export const globalDeleteDocumentsDialogVar = makeVar({
  open: false,
  documents: [],
  refetch: () => {},
});
export const globalDownloadSnackbarVar = makeVar({
  downloads: [],
});
export const globalSessionWillExpireDialogVar = makeVar({
  open: false,
  onClose: () => {},
  title: '',
  content: '',
  footer: '',
  acceptLabel: '',
  cancelLabel: '',
  loading: false,
  loadingMessage: '',
  icon: null,
  emoji: '',
  closeOnEscKey: false,
  closeOnBackdropClick: false,
});
export const globalBackdropVar = makeVar(globalBackdrop);
export const sifeIframeVar = makeVar({ open: false });
/**
 *
 * @type {import('@apollo/client').ReactiveVar<import('./cache.reactiveVars.types').User|null>}
 */
export const userVar = makeVar(
  localStorage.user ? JSON.parse(localStorage.user) : null,
);
export const pendingDocsVar = makeVar(
  localStorage.pendingDocs ? JSON.parse(localStorage.pendingDocs) : null,
);
export const refetchFunctionsVar = makeVar(refetchFunctions);
export const certificateVar = makeVar({
  certificate: null,
  key: null,
  password: null,
  metadata: null,
  publicKeyBase64: null,
  success: false,
});
export const permissionsListVar = makeVar({
  superAdmin: null,
  adminRh: null,
  adminWC: null,
  defaultSuperAdmin: null,
  defaultAdminRh: null,
  defaultAdminWC: null,
});

const permissionsObject = {
  code: '',
  read: false,
  create: false,
  update: false,
  delete: false,
  active: false,
};

export const userPermissionsDefault = {
  hra: permissionsObject,
  dash: permissionsObject,
  recs: permissionsObject,
  docs: permissionsObject,
  mply: permissionsObject,
  wc: permissionsObject,
  cgr: permissionsObject,
  lr: permissionsObject,
  templates: permissionsObject,
  workTitles: permissionsObject,
  groups: permissionsObject,
  compData: permissionsObject,
  supAdmins: permissionsObject,
  mod: permissionsObject,
  consump: permissionsObject,
  bill: permissionsObject,
  roles: permissionsObject,
  signDocs: permissionsObject,
  recordChecklist: permissionsObject,
  requestDocsToEmployees: permissionsObject,
  approveDocsFromEmployees: permissionsObject,
  authorizeTemplates: permissionsObject,
  publishTemplates: permissionsObject,
  holidays: permissionsObject,
};

export const userPermissionsVar = makeVar(userPermissionsDefault);

export const filesVar = makeVar([]);

const confirmExitDialogDefault = {
  open: false,
  onConfirm: () => {},
  onClose: () => {},
};
export const confirmExitDialogVar = makeVar(confirmExitDialogDefault);

export const resetConfirmExitDialogVar = () =>
  confirmExitDialogVar(confirmExitDialogDefault);

export const reactiveVars = {
  ...workTitlesReactiveVars,
  ...employeesFinderReactivesVars,
  ...supportDialogReactiveVars,
  ...commercialPanelReactiveVars,
  ...customersBillingReactiveVars,
  ...groupsReactiveVars,
  ...recordChecklistReactiveVars,
  ...employeeTableVars,
  ...employeesStatusFilterVars,
  ...userAndRolesReactiveVars,
  ...dashboardReactiveVars,
  ...employeeAbsenceReactiveVars,
  ...templatesReactiveVars,
  ...myPortalReactiveVars,
  confirmExitDialogVar: {
    read() {
      return confirmExitDialogVar();
    },
  },
  sendOnboardingNotifDialogVar: {
    read() {
      return sendOnboardingNotifDialogVar();
    },
  },
  changeEmployeeLegalStatusDialogVar: {
    read() {
      return changeEmployeeLegalStatusDialogVar();
    },
  },
  isLoggedInVar: {
    read() {
      return isLoggedInVar();
    },
  },
  currentCompanyVar: {
    read() {
      return currentCompanyVar();
    },
  },
  userCompaniesVar: {
    read() {
      return userCompaniesVar();
    },
  },
  userRolesVar: {
    read() {
      return userRolesVar();
    },
  },
  currentRoleVar: {
    read() {
      return currentRoleVar();
    },
  },
  profileDialogVar: {
    read() {
      return profileDialogVar();
    },
  },
  employeeSearchVar: {
    read() {
      return employeeSearchVar();
    },
  },
  dashboardFiltersVar: {
    read() {
      return dashboardFiltersVar();
    },
  },
  documentCountVar: {
    read() {
      return documentCountVar();
    },
  },
  globalSnackbarVar: {
    read() {
      return globalSnackbarVar();
    },
  },
  globalDownloadSnackbarVar: {
    read() {
      return globalDownloadSnackbarVar();
    },
  },
  globalSessionWillExpireDialogVar: {
    read() {
      return globalSessionWillExpireDialogVar();
    },
  },
  globalBackdropVar: {
    read() {
      return globalBackdropVar();
    },
  },
  dialogOpenVar: {
    read() {
      return dialogOpenVar();
    },
  },
  genericDialogVar: {
    read() {
      return genericDialogVar();
    },
  },
  userVar: {
    read() {
      return userVar();
    },
  },
  pendingDocsVar: {
    read() {
      return pendingDocsVar();
    },
  },
  refetchFunctionsVar: {
    read() {
      return refetchFunctionsVar();
    },
  },
  certificateVar: {
    read() {
      return certificateVar();
    },
  },
  sifeIframeVar: {
    read() {
      return sifeIframeVar();
    },
  },
  permissionsListVar: {
    read() {
      return permissionsListVar();
    },
  },
  userPermissionsVar: {
    read() {
      return userPermissionsVar();
    },
  },
  navBarStateVar: {
    read() {
      return navBarStateVar();
    },
  },
};
