//@ts-check
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import {
  Tooltip,
  Chip,
  Avatar,
  StatusBadge,
  IconButton,
} from '../../../../../newComponents';
import { belongsToSelectedWorkCenters } from '../PdfDocuments.utils';
import { RenderExpandedText } from '../../../../../components/ExpandedText/RenderExpandedText';

/**
 * @typedef {import('../../../../../theme').CustomTheme} CustomTheme
 */

/** @type {React.CSSProperties} */
const imageAvatarStyle = {
  margin: '0 auto',
  width: '100%',
  height: '100%',
  textAlign: 'center',
  objectFit: 'cover',
  textIndent: '10000px',
};

/** @type {React.CSSProperties} */
const iconSelectedStyle = {
  position: 'absolute',
  top: 0, // ajustar esto para controlar la posición vertical del stack
  left: 0,
  right: 0,
  bottom: 0,
  background: '#161C24',
  borderRadius: '50%',
  zIndex: 99,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: '0.6',
};

const avatarColorsByType = {
  WorkCenter: 'success',
  WorkTitle: 'error',
  Group: 'warning',
};

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.profilePicture
 * @param {string} props.type
 * @param {import('../../../../../newComponents/Avatar/Avatar.types').Size} props.size
 * @returns {React.JSX.Element|null} The JSX representation of the autcomplete component.
 */
const RenderSelectedAvatar = ({ name, profilePicture, type, size }) => {
  if (type === 'workCenters') {
    return (
      <Avatar size={size} color="default">
        <Icon width="20px" icon="building_line" />
        <Stack sx={iconSelectedStyle}>
          <Icon icon="check_line" fill="#fff" width="20px" height="20px" />
        </Stack>
      </Avatar>
    );
  }
  if (profilePicture && type === 'employees') {
    return (
      <Avatar size={size}>
        <img style={imageAvatarStyle} src={profilePicture} alt={name} />
        <Stack sx={iconSelectedStyle}>
          <Icon icon="check_line" fill="#fff" width="20px" height="20px" />
        </Stack>
      </Avatar>
    );
  }

  if (!profilePicture && type === 'employees') {
    return (
      <Avatar size={size} color="default">
        {name.charAt(0)}
        <Stack sx={iconSelectedStyle}>
          <Icon icon="check_line" fill="#fff" width="20px" height="20px" />
        </Stack>
      </Avatar>
    );
  }

  if (['companySigners', 'authorizers'].includes(type)) {
    return (
      <StatusBadge
        overlap="circular"
        status="compSigner"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar size={size} color="default">
          {`${name.charAt(0)}`}
          <Stack sx={iconSelectedStyle}>
            <Icon icon="check_line" fill="#fff" width="20px" height="20px" />
          </Stack>
        </Avatar>
      </StatusBadge>
    );
  }
  return null;
};

/**
 *
 * @component
 * @typedef {object} CustomAvatarSignersProps - The props for the autocomplete component.
 * @property {import('../../../../../newComponents/Avatar/Avatar.types').Size} [size='medium']
 * @property {"default"|"primary"|"secondary"|"info"|"success"|"warning"|"error"} color
 * @property {string} profilePicture - The HTML id attribute for the input element.
 * @property {string|undefined} type
 * @property {string} name
 * @property {boolean} isAutocomplete
 * @property {Array} [ selectedItems ]
 * @property {string} id
 * @property {string} [workCenterId]
 *
 * @param {CustomAvatarSignersProps} Props
 * @returns {React.JSX.Element} The JSX representation of the autcomplete component.
 */
export const CustomAvatarSigners = ({
  size = 'medium',
  profilePicture,
  type,
  name = '',
  color = 'primary',
  isAutocomplete,
  selectedItems = [],
  id,
  workCenterId,
}) => {
  const alreadyIncludedInSelectedWorkCenters = belongsToSelectedWorkCenters(
    selectedItems,
    { workCenterId },
  );

  const isItemSelected =
    isAutocomplete &&
    (selectedItems?.some((item) => item?._id === id) ||
      alreadyIncludedInSelectedWorkCenters);

  if (isItemSelected) {
    return (
      <RenderSelectedAvatar
        name={name}
        size={size}
        type={type}
        profilePicture={profilePicture}
      />
    );
  }

  if (type === 'WorkCenter') {
    return (
      <Avatar size={size} color={color}>
        <Icon width="17px" icon="building_line" />
      </Avatar>
    );
  }
  if (type === 'WorkTitle') {
    return (
      <Avatar size={size} color={color}>
        <Icon width="17px" icon="briefcase_3_line" />
      </Avatar>
    );
  }

  if (type === 'ALL_EMPLOYEES') {
    return (
      <Avatar size={size} color={color}>
        <Icon width="17px" icon="group_line" />
      </Avatar>
    );
  }

  if (type === 'Employee' && profilePicture) {
    return <Avatar src={profilePicture} size={size} />;
  }
  if (type === 'Employee' && !profilePicture) {
    return <Avatar size={size} color={color}>{`${name.charAt(0)}`}</Avatar>;
  }
  if (['companySigners', 'authorizers'].includes(type) && profilePicture) {
    return (
      <StatusBadge
        overlap="circular"
        status="compSigner"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar src={profilePicture} size={size} />
      </StatusBadge>
    );
  }

  if (['companySigners', 'authorizers'].includes(type) && !profilePicture) {
    return (
      <StatusBadge
        overlap="circular"
        status="compSigner"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar color={color} size="medium">
          {`${name.charAt(0)}`}
        </Avatar>
      </StatusBadge>
    );
  }
  return <Avatar size={size} color={color}>{`${name.charAt(0)}`}</Avatar>;
};

export const COLUMNS_TABLE_SIGNERS = [
  {
    id: 'name',
    label: 'Nombre',
    align: 'left',
    disabledPadding: true,
    style: {
      '&.MuiTableCell-root': {
        border: 'none',
        pl: 0.5,
      },
    },
    render: ({ row, palette }) => {
      const AvatarValue = () => {
        if (row.success === false) {
          return (
            <Avatar
              color="error"
              sx={{
                '&.MuiAvatar-colorError': {
                  background: palette.error.transparent24,
                  color: palette.error.main,
                },
              }}
            >
              <Icon
                icon="error_warning_line"
                height="20px"
                fill={palette.error.main}
              />
            </Avatar>
          );
        }

        return (
          <CustomAvatarSigners
            name={row.fullName}
            type={row.type}
            profilePicture={row.profilePicture}
            color={avatarColorsByType[row.type] ?? 'primary'}
            isAutocomplete={false}
            selectedItems={[]}
            id=""
          />
        );
      };

      const CustomText = () => {
        if (row.success === false) {
          return (
            <>
              <Typography variant="subtitle2">{row.user}</Typography>
              <Typography variant="body2" color={palette.error.main}>
                {row.message}
              </Typography>
            </>
          );
        }

        if (row?.type === 'ALL_EMPLOYEES') {
          return (
            <>
              <Typography variant="subtitle2">{row.fullName}</Typography>
              <Typography variant="body2" color={palette.text.secondary}>
                El documento se enviará a todos los empleados
              </Typography>
            </>
          );
        }

        if (row?.type === 'WorkCenter') {
          return (
            <Stack justifyContent="center" width="200px">
              <RenderExpandedText text={row.name || 'Sin nombre de CT'} arrow>
                <Typography variant="subtitle2" component="span">
                  {row.name || 'Sin nombre de CT'}
                </Typography>
              </RenderExpandedText>
              <Stack
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                mt="2px"
              >
                <Typography variant="body2" color={palette.text.secondary}>
                  Centro de trabajo
                </Typography>
              </Stack>
            </Stack>
          );
        }

        if (['WorkTitle', 'Group'].includes(row?.type)) {
          return (
            <Stack justifyContent="center" width="200px">
              <RenderExpandedText text={row.name || 'Sin nombre'} arrow>
                <Typography variant="subtitle2" component="span">
                  {row.name || 'Sin nombre'}
                </Typography>
              </RenderExpandedText>
              <Stack
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                mt="2px"
              >
                <Typography variant="body2" color={palette.text.secondary}>
                  {row?.type === 'WorkTitle' ? 'Puesto de trabajo' : 'Grupo'}
                </Typography>
              </Stack>
            </Stack>
          );
        }

        return (
          <Stack width="250px ">
            <Typography variant="subtitle2">
              {row.fullName || row.name || 'Sin nombre'}
            </Typography>

            <RenderExpandedText
              arrow
              tooltipMaxWidth="10px"
              text={
                row.type === 'employees'
                  ? row.workCenterName
                  : 'Firmante de empresa'
              }
            >
              <Typography
                variant="body2"
                color={palette.text.secondary}
                component="span"
              >
                {row.type === 'employees' && row.workCenterName}
                {row.type === 'companySigners' && 'Firmante de empresa'}
              </Typography>
            </RenderExpandedText>

            <Typography
              variant="body2"
              color={palette.text.secondary}
            ></Typography>
          </Stack>
        );
      };

      return (
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <AvatarValue />
          <Stack flexWrap="wrap" width="100%" whiteSpace="normal">
            <CustomText />
          </Stack>
        </Stack>
      );
    },
  },
  {
    id: 'delete',
    label: '',
    align: 'right',
    disabledPadding: true,
    style: {
      '&.MuiTableCell-root': {
        border: 'none',
      },
    },
    render: ({ row, deleteSigners, selected = [], colDef }) => {
      const totalEmployees = row?.employeeCount || 0;
      const defaultEmployeeId = colDef?.defaultSelectedEmployee?._id;
      const disableActionButtons = defaultEmployeeId
        ? defaultEmployeeId === row?._id
        : false;

      return (
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          <Stack>
            {row?.type === 'workCenters' && (
              <Tooltip title="Firmantes" arrow>
                <div>
                  <Chip
                    variant="soft"
                    color="primary"
                    label={totalEmployees}
                    size="small"
                    style={{ minWidth: '30px', height: '20px' }}
                  />
                </div>
              </Tooltip>
            )}
          </Stack>
          <Stack>
            {!disableActionButtons && (
              <IconButton
                disabled={disableActionButtons}
                icon="delete_bin_line"
                size="small"
                color="error"
                className={
                  selected.includes(row.id || row._id) ? '' : 'delete_button'
                }
                onClick={() => deleteSigners(row, 'single')}
              />
            )}
          </Stack>
        </Stack>
      );
    },
  },
];
