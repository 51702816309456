import { styled, Stack } from '@mui/material';
import type { FileStatus } from './FileUpload.types';

type CustomProps = {
  onlyIcon: boolean;
  disabled: boolean;
  status: FileStatus;
};

export const FileUploadWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'onlyIcon' && prop !== 'disabled',
})<CustomProps>(({ onlyIcon, disabled, status, theme }) => ({
  backgroundColor:
    status === 'error'
      ? theme.newPalette.error.transparent16
      : theme.newPalette.grey.transparent8,
  cursor: 'pointer',
  width: onlyIcon ? '64px' : '344px',
  height: onlyIcon ? '64px' : '106px',
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor:
    status === 'error'
      ? theme.newPalette.error.main
      : theme.newPalette.grey.transparent32,
  borderRadius: '12px',
  ...(disabled && { opacity: '.5' }),
}));
