import { useState, useCallback } from 'react';
import { GET_COMPANY_SIGNER_NOTIFICATIONS_CONFIG } from '../Profile.gql';
import { useApolloClient } from '@apollo/client';
import type { GetCompanySignerNotificationsConfigType } from '../ProfileDialog.types';

export const useGetCompanySignerNotificationsConfig =
  (): GetCompanySignerNotificationsConfigType => {
    const [data, setData] = useState<any>(null);
    const [canShowNotificationTab, setCanShowNotificationTab] =
      useState<boolean>(false);
    const graphqlClient = useApolloClient();

    const fetchNotificationsConfig = useCallback(async () => {
      try {
        const result = await graphqlClient.query({
          query: GET_COMPANY_SIGNER_NOTIFICATIONS_CONFIG,
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true,
        });

        const config =
          result?.data?.getCompanySignerNotificationsConfig || null;
        setData(config);
        setCanShowNotificationTab(config !== null);
      } catch (err) {
        setCanShowNotificationTab(false);
      }
    }, [graphqlClient]);

    return {
      data,
      canShowNotificationTab,
      fetchNotificationsConfig,
    };
  };
