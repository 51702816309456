import { useReactiveVar } from '@apollo/client';
import { Redirect, Route } from 'react-router-dom';
import IndependentUser from '../containers/RHPod/IndependentUser/IndependentUser';
import { currentRoleVar, userCompaniesVar } from '../cache.reactiveVars';
// import logout from './logout';

const RoleRoute = ({
  children,
  admin = false,
  employee = false,
  independent = false,
  ...rest
}) => {
  const companies = useReactiveVar(userCompaniesVar);
  const currentRole = useReactiveVar(currentRoleVar);
  // const history = useHistory();
  const isMaster = currentRole?.isMaster;
  const isAdminRh = currentRole?.isAdminRh;
  const isWcAdmin = currentRole?.isAdmin?.length;
  const isEmployee = currentRole?.isEmployee;

  //TODO: Revisar en que escenarios un usuario no tiene un currentRole

  if (!currentRole && companies?.length > 0) {
    if (independent) {
      return <IndependentUser />;
    }
    return <Redirect to="/rhpod/independent" />;
    // return logout(history);
  }

  if (!isMaster && !isWcAdmin && !isAdminRh && !isEmployee) {
    if (independent) {
      return <IndependentUser />;
    }
    return <Redirect to="/rhpod/independent" />;
  }

  if (currentRole && rest?.path?.includes('independent')) {
    return <Redirect to="/rhpod/admin" />;
  }

  if (admin && !isMaster && !isWcAdmin && !isAdminRh) {
    return <Redirect to="/rhpod/empleado" />;
  }

  if (employee && !isEmployee) return <Redirect to="/rhpod/admin" />;

  return <Route {...rest}>{children}</Route>;
};

export default RoleRoute;
