import React, { useState } from 'react';
import { Stack } from '@mui/material';
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '../../../../newComponents';
import { HolidayAuthorizers } from './AuthorizersTeams/containers/HolidayAuthorizers';
import { HolidaySchemes } from './HolidaySchemes/containers/HolidaySchemes';
import { usePermissionChecker } from '../../../../Hooks';

// eslint-disable-next-line no-unused-vars
enum Modules {
  // eslint-disable-next-line no-unused-vars
  holidaySchema = 'holidaySchema',
  // eslint-disable-next-line no-unused-vars
  authorizers = 'authorizers',
}

export const Holidays: React.FC = () => {
  const allowed: Boolean = usePermissionChecker({
    permission: 'holidays',
    action: 'update',
  });
  const [module, setModule] = useState<Modules>(Modules.holidaySchema);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newModule: Modules,
  ) => {
    if (newModule !== null) {
      setModule(newModule);
    }
  };

  return (
    <>
      <Stack>
        {allowed ? (
          <>
            <Stack className="pills" direction={'row'}>
              <ToggleButtonGroup
                color="primary"
                value={module}
                exclusive
                onChange={handleChange}
                aria-label="Modulo seleccionado"
              >
                <ToggleButton
                  sx={{ width: 'auto!important' }}
                  value={Modules.holidaySchema}
                  color={'primary'}
                  variant={'contained'}
                >
                  Esquemas de vacaciones
                </ToggleButton>
                <ToggleButton
                  sx={{ width: 'auto!important' }}
                  value={Modules.authorizers}
                  variant={'contained'}
                >
                  Autorizadores de vacaciones
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack>
              {module === Modules.holidaySchema ? (
                <HolidaySchemes />
              ) : (
                <HolidayAuthorizers />
              )}
            </Stack>
          </>
        ) : (
          <Typography variant={'body2'}>
            No tienes permisos para ver este modulo
          </Typography>
        )}
      </Stack>
    </>
  );
};
