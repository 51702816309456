import React from 'react';
import { Collapse, IconButton, Paper, Stack, useTheme } from '@mui/material';
import { SystemProps } from '@mui/system';
import { Button } from '../../../../../../../../newComponents/Button';
import { Icon } from '../../../../../../../../components/Icons/Icons';
import { Checkbox } from '../../../../../../../../newComponents/Checkbox';
import InfiniteLoader from 'react-window-infinite-loader/dist/index.esm';
import { FixedSizeList } from 'react-window/dist/index.esm';
import { Typography } from '../../../../../../../../newComponents/Typography';
import { RowItem } from './TableRowItem';
import { OrderableAuthorizer } from '../CreateHolidayAuthorizationTeamDialog.types';
import { AuthorizersTableColumnType } from './TableColumns';

export type DeleteActionType = 'massive' | 'single';

type TableComponentProps = {
  width?: SystemProps['width'];
  height: number;
  headerBgColor: string;
  headerTextColor: string;
  rows: OrderableAuthorizer[];
  columns: AuthorizersTableColumnType[];
  handleCheckAll: (checked: boolean) => void;
  selectedRows: OrderableAuthorizer[];
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    row: OrderableAuthorizer,
  ) => void;
  handleDeleteItems: (
    action: DeleteActionType,
    row?: OrderableAuthorizer,
  ) => void;
  areAllChecked: boolean;
  loadedRowCount: number;
  showIndexOnRows: boolean;
  loadMore?: (args: any[]) => any;
};

export const TableComponent: React.FC<TableComponentProps> = ({
  width,
  height,
  headerBgColor,
  headerTextColor,
  rows = [],
  columns = [],
  handleCheckAll,
  selectedRows = [],
  handleCheckboxChange,
  handleDeleteItems,
  areAllChecked,
  loadedRowCount,
  showIndexOnRows = false,
  loadMore = () => {},
}) => {
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <>
      <Collapse in={Boolean(selectedRows.length > 0)}>
        <Stack
          minHeight="76px"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: palette.primary.lighter,
          }}
          px={selectedRows?.length ? 2 : 3}
        >
          <Button
            variant="text"
            color="primary"
            size="large"
            sx={{
              '&.MuiButton-textPrimary': {
                padding: 0,
                pointerEvents: 'none',
              },
            }}
          >
            {selectedRows.length} firmantes seleccionados
          </Button>
          <IconButton onClick={() => handleDeleteItems('massive')}>
            <Icon
              icon="delete_bin_line"
              width="20px"
              fill={palette.primary.main}
            />
          </IconButton>
        </Stack>
      </Collapse>
      {rows?.length > 0 && (
        <Stack
          component={Paper}
          sx={{
            width,
            maxHeight: 470,
            borderRadius: '0 0 10px 10px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            className={'mxp'}
            gap={2}
            sx={{
              px: 2,
              py: 2,
              background: headerBgColor,
              height: 56,
            }}
          >
            <Checkbox
              sx={{
                color: headerTextColor,
              }}
              color="primary"
              checked={areAllChecked}
              name="general"
              onChange={(e, checked) => handleCheckAll(checked)}
            />
            <Typography color={headerTextColor} variant={'body2'}>
              Nombre
            </Typography>
          </Stack>
          <InfiniteLoader
            isItemLoaded={(index) => index < loadedRowCount}
            itemCount={rows.length}
            threshold={0}
            loadMoreItems={loadMore}
          >
            {({ onItemsRendered, ref: refLoader }) => (
              <FixedSizeList
                ref={refLoader}
                height={rows?.length ? Math.min(rows.length * 76, height) : 400}
                width={'100%'}
                itemCount={rows.length}
                itemSize={76}
                itemData={rows}
                onItemsRendered={onItemsRendered}
              >
                {({ index, style }) => (
                  <RowItem
                    style={style}
                    row={rows[index]}
                    columns={columns}
                    handleDeleteItems={handleDeleteItems}
                    selectedRows={selectedRows}
                    handleCheckboxChange={handleCheckboxChange}
                    index={index}
                    showIndex={showIndexOnRows}
                  />
                )}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        </Stack>
      )}
    </>
  );
};
