//@ts-check
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ErrorBoundary, withErrorBoundary } from '@sentry/react';

import ConfirmationDialog from '../../../../components/Dialogs/ConfirmationDialog';
import { DefaultErrorComponent } from '../../../../components/ErrorBoundary/ErrorBoundary';
import { Icon } from '../../../../components/Icons/Icons';
import {
  emptyData,
  PersonalDataForm,
  ContactDataForm,
  JobDataForm,
} from './EmployeeInfo.constants';
import { EmployeeInfoRecords } from './EmployeeInfoRecords/EmployeeInfoRecords';
import { useDownloads } from '../../../../Hooks/useDownloads';
import { EmployeeInfoCredits } from './EmployeeInfoCredits/EmployeeInfoCredits';
import { AddOtpDialog } from '../../../../components/Dialogs/AddOtpDialog';
import { EmployeeInfoContent, SkeletonEmployee } from './EmployeeInfo.styles';
import { EmployeeInfoHeader } from './EmployeeInfoHeader/EmployeeInfoHeader';
import {
  currentCompanyVar,
  globalSnackbarVar,
  userPermissionsVar,
  confirmExitDialogVar,
  refetchFunctionsVar,
} from '../../../../cache.reactiveVars';
import { EmployeeInfoGeneralData } from './EmployeeInfoGeneralData/EmployeeInfoGeneralData';
import { ConfirmExitDialog } from './ConfirmExitDialog/ConfirmExitDialog';
import { useForm } from 'react-hook-form';
import { employeeInfoDialogVar } from '../Employees.vars';
import { Dialog } from '../../../../newComponents/Dialog';
import { GET_EMPLOYEE_INFO } from './EmployeeInfo.gql';
import { loggerUtil } from '../../../../utils/loggerUtil';
import { useScreenSize } from '../../../../Hooks';
import { CreateEmployeeAbsenceDialog } from '../../Absence/CreateEmployeeAbsenceDialog/CreateEmployeeAbsenceDialog';
import { FinishEmployeeAbsenceDialog } from '../../Absence/CreateEmployeeAbsenceDialog/FinishEmployeeAbsenceDialog';
import { resetAllDocumentsTableVars } from '../../Documents_New/DocumentsTable/DocumentsTable.vars';
import { resetDocumentsTableAdvancedFiltersDialogVar } from '../../Documents_New/DocumentsTable/DocumentsTableAdvancedFiltersDialog/DocumentsTableAdvancedFiltersDialog.vars';
import { resetDocumentsStatusFilterVar } from '../../Documents_New/DocumentsCounters/DocumentsCounters.vars';
import AbsenceTable from '../../Absence/AbsenceTable/AbsenceTable';
import { refetchAbsenceQueriesVar } from '../../Absence/EmployeeAbsence.vars';
import { createDocumentDialogVar } from '../../Documents/CreateDocuments/CreateDocumentDialog.vars';
import { HolidaysTable } from '../../Holidays/components/HolidaysTable';
import { openFileViewerDialogForLetter } from '../../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';

/**
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 */

export const EmployeeInfo = withErrorBoundary(
  ({ setTableCreditsPage }) => {
    const employeeInfoDialog = useReactiveVar(employeeInfoDialogVar);
    const { open, employeeId, employeeWorkSpaceId, tab, onClose } =
      employeeInfoDialog;
    const { open: openCreateDocument } = useReactiveVar(
      createDocumentDialogVar,
    );

    const [tabEmployeeValue, setEmployeeTabValue] = useState('employeeRecords');

    const [openDownload, setOpenDownload] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);

    /** @type {CustomTheme} */
    const theme = useTheme();

    const { handleAddDownload } = useDownloads();
    const currentCompany = useReactiveVar(currentCompanyVar);
    const hideCreditsSection = Boolean(
      currentCompany?.configs?.hideCreditsAndSavings?.active,
    );

    const userPermissions = useReactiveVar(userPermissionsVar);

    const employeePersonalDataForm = useForm({
      defaultValues: PersonalDataForm,
    });
    const employeeContactDataForm = useForm({
      defaultValues: ContactDataForm,
    });
    const employeeNominaDataForm = useForm({
      defaultValues: JobDataForm,
    });

    const [
      getEmployeeInfo,
      { loading, refetch: refetchCurrentUser, data, error: employeeInfoError },
    ] = useLazyQuery(GET_EMPLOYEE_INFO, {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (
          data?.getEmployeeInfo?.employeeInfo?.__typename === 'UserNotFound'
        ) {
          return globalSnackbarVar({
            show: true,
            message: 'No se encontró información del usuario',
            severity: 'error',
          });
        }
        if (data?.getEmployeeInfo?.employeeInfo) {
          const { employeeInfo } = data?.getEmployeeInfo || {};
          employeePersonalDataForm.reset({
            curp: employeeInfo?.curp || '',
            rfc: employeeInfo?.rfc || '',
            names: employeeInfo?.names || '',
            lastNameP: employeeInfo?.lastNameP || '',
            lastNameM: employeeInfo?.lastNameM || '',
            gender: employeeInfo?.gender || '',
            maritalStatus: employeeInfo?.maritalStatus || '',
            birthdate: employeeInfo?.birthdate
              ? new Date(employeeInfo?.birthdate)
              : null,
          });
          employeeContactDataForm.reset({
            email: employeeInfo?.email || '',
            phone: employeeInfo?.phone || '',
            country: employeeInfo?.address?.country || '',
            street: employeeInfo?.address?.street || '',
            internalNumber: employeeInfo?.address?.internalNumber || '',
            externalNumber: employeeInfo?.address?.externalNumber || '',
            colony: employeeInfo?.address?.colony || '',
            zipCode: employeeInfo?.address?.zipCode || '',
            city: employeeInfo?.address?.city || '',
            state: employeeInfo?.address?.state || '',
          });
          employeeNominaDataForm.reset({
            payrollId: employeeInfo?.payrollId || '',
            workCenterId: employeeInfo?.workCenterId || '',
            workTitle: employeeInfo?.workTitle || '',
            salary: employeeInfo?.salary || 0,
            socialSecurityNumber: employeeInfo?.socialSecurityNumber || '',
            contractType: employeeInfo?.contractType || '',
            workDayType: employeeInfo?.workDayType || '',
            employmentStartDate: employeeInfo?.employmentStartDate
              ? new Date(employeeInfo?.employmentStartDate)
              : null,
          });
        }
      },
      onError(err) {
        globalSnackbarVar({
          show: true,
          message:
            'Ocurrió un error al obtener la información del usuario, intente de nuevo o contacte a Sora.',
          severity: 'error',
        });
        loggerUtil.error('Error al obtener la información del usuario', err);
      },
    });

    let employeeData = data?.getEmployeeInfo?.employeeInfo;
    let employeeDocumentCounts = data?.getEmployeeInfo?.employeeDocumentCounts;
    let employeeReceiptCounts = data?.getEmployeeInfo?.employeeReceiptCounts;
    let recordChecklistAssigned =
      data?.getEmployeeInfo?.recordChecklistAssigned;
    let recordChecklistAssignedDocumentsCounts =
      data?.getEmployeeInfo?.recordChecklistAssignedDocumentsCounts;

    useEffect(() => {
      if (employeeId && open) {
        getEmployeeInfo({
          variables: {
            input: {
              employeeId: employeeId,
              employeeWorkSpaceId: employeeWorkSpaceId,
            },
          },
        });
        resetAllDocumentsTableVars();
        resetDocumentsStatusFilterVar();
        resetDocumentsTableAdvancedFiltersDialogVar();
      }
    }, [employeeId, employeeWorkSpaceId, getEmployeeInfo, open]);

    useEffect(() => {
      if (open) {
        refetchFunctionsVar({
          ...refetchFunctionsVar(),
          refetchCurrentUser: refetchCurrentUser,
        });
        refetchAbsenceQueriesVar({
          ...refetchAbsenceQueriesVar(),
          isEmployeeId: true,
        });
      } else {
        setEmployeeTabValue('employeeRecords');
      }

      if (open && tab) {
        setEmployeeTabValue(tab);
      }
    }, [refetchCurrentUser, open, tab]);

    const resetEmployeePersonalDataForm = () => {
      employeePersonalDataForm.reset({
        curp: employeeData?.curp || '',
        rfc: employeeData?.rfc || '',
        names: employeeData?.names || '',
        lastNameP: employeeData?.lastNameP || '',
        lastNameM: employeeData?.lastNameM || '',
        gender: employeeData?.gender || '',
        maritalStatus: employeeData?.maritalStatus || '',
        birthdate: employeeData?.birthdate
          ? new Date(employeeData?.birthdate)
          : null,
      });
    };

    const resetEmployeeContactDataForm = () => {
      employeeContactDataForm.reset({
        email: employeeData?.email || '',
        phone: employeeData?.phone || '',
        country: employeeData?.address?.country || '',
        street: employeeData?.address?.street || '',
        internalNumber: employeeData?.address?.internalNumber || '',
        externalNumber: employeeData?.address?.externalNumber || '',
        colony: employeeData?.address?.colony || '',
        zipCode: employeeData?.address?.zipCode || '',
        city: employeeData?.address?.city || '',
        state: employeeData?.address?.state || '',
      });
    };

    const resetEmployeeNominaDataForm = () => {
      employeeNominaDataForm.reset({
        payrollId: employeeData?.payrollId || '',
        workCenterId: employeeData?.workCenterId || '',
        workTitle: employeeData?.workTitle || '',
        salary: employeeData?.salary || 0,
        socialSecurityNumber: employeeData?.socialSecurityNumber || '',
        contractType: employeeData?.contractType || '',
        workDayType: employeeData?.workDayType || '',
        employmentStartDate: employeeData?.employmentStartDate
          ? new Date(employeeData?.employmentStartDate)
          : null,
      });
    };

    const resetEmployeeForms = () => {
      resetEmployeePersonalDataForm();
      resetEmployeeContactDataForm();
      resetEmployeeNominaDataForm();
    };

    const employeePersonalDataFormDirtyAndNotSubmitted =
      employeePersonalDataForm.formState.isDirty &&
      !employeePersonalDataForm.formState.isSubmitted;
    const employeeNominaDataFormDirtyAndNotSubmitted =
      employeeNominaDataForm.formState.isDirty &&
      !employeeNominaDataForm.formState.isSubmitted;
    const isAnyFormDirtyAndNotSubmitted =
      employeePersonalDataFormDirtyAndNotSubmitted ||
      employeeNominaDataFormDirtyAndNotSubmitted;

    const handelTabChange = (newValue) => {
      if (isAnyFormDirtyAndNotSubmitted && newValue !== 'general') {
        return confirmExitDialogVar({
          open: true,
          onConfirm: () => {},
          onClose: () => {
            resetEmployeeForms();
            setEmployeeTabValue(newValue);
          },
        });
      }
      setEmployeeTabValue(newValue);
    };

    const handleClose = () => {
      if (isAnyFormDirtyAndNotSubmitted) {
        return confirmExitDialogVar({
          open: true,
          onConfirm: () => {},
          onClose: () => {
            resetEmployeeForms();
            onClose();
          },
        });
      }
      employeeData = emptyData;
      if (!openCreateDocument) {
        resetAllDocumentsTableVars();
        resetDocumentsStatusFilterVar();
        resetDocumentsTableAdvancedFiltersDialogVar();
      }
      onClose();
    };

    const handlePrintLetter = ({ employeeId }) => {
      openFileViewerDialogForLetter({ userId: employeeId });
    };

    const handleDownloadEmployeeRecords = async (res) => {
      if (!res) return setOpenDownload(false);

      handleAddDownload({
        download: {
          name: `expediente-${employeeData.rfc}`,
          status: 'pending',
          action: 'downloadEmployeeRecords',
          message: '',
          payload: { userId: employeeId, rfc: employeeData.rfc },
        },
        onFails: () => {
          setOpenDownload(false);
        },
        onAdded: () => {
          employeeData = emptyData;
          onClose();
        },
      });
    };
    const { isMobile } = useScreenSize();

    const showSkeleton =
      loading ||
      !open ||
      (!employeeData && !employeeInfoError) ||
      !employeeData;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        slideMode
        slideTimeout={300}
        fullScreen={isMobile}
      >
        {!isMobile && (
          <DialogTitle
            sx={{
              pb: 0,
              position: 'sticky',
              backgroundColor: 'white',
              zIndex: '2000',
              borderBottom: `1px solid ${theme.newPalette.grey.grey400}`,
            }}
          >
            <Icon
              fill={theme.newPalette.grey.grey400}
              icon="close_line"
              height="26px"
              onClick={handleClose}
              pointer
            />
          </DialogTitle>
        )}
        <EmployeeInfoContent>
          <ErrorBoundary fallback={<DefaultErrorComponent />}>
            {showSkeleton ? (
              <SkeletonEmployee height={170} />
            ) : (
              <EmployeeInfoHeader
                employeeData={employeeData}
                theme={theme}
                employeeId={employeeId}
                refetchCurrentUser={refetchCurrentUser}
                handlePrintLetter={handlePrintLetter}
                userPermissions={userPermissions}
                tabValue={tabEmployeeValue}
                setTabValue={handelTabChange}
                handleCreditsSection={hideCreditsSection}
                handleClose={handleClose}
              />
            )}
            {tabEmployeeValue === 'generalData' && (
              <EmployeeInfoGeneralData
                employeeData={employeeData}
                loading={loading}
                userPermissions={userPermissions}
                employeePersonalDataForm={employeePersonalDataForm}
                resetEmployeePersonalDataForm={resetEmployeePersonalDataForm}
                employeeContactDataForm={employeeContactDataForm}
                resetEmployeeNominaDataForm={resetEmployeeNominaDataForm}
                employeeNominaDataForm={employeeNominaDataForm}
                resetEmployeeContactDataForm={resetEmployeeContactDataForm}
              />
            )}
            {tabEmployeeValue === 'employeeRecords' && (
              <EmployeeInfoRecords
                employeeId={employeeId}
                employeeData={employeeData}
                employeeDocumentCounts={employeeDocumentCounts}
                employeeReceiptCounts={employeeReceiptCounts}
                recordChecklistAssigned={recordChecklistAssigned}
                recordChecklistAssignedDocumentsCounts={
                  recordChecklistAssignedDocumentsCounts
                }
                employeeDataLoading={loading}
              />
            )}
            {tabEmployeeValue === 'absences' && (
              <AbsenceTable employeeId={employeeId} />
            )}
            {tabEmployeeValue === 'holidays' && (
              <HolidaysTable employeeId={employeeId} />
            )}
            {!hideCreditsSection && tabEmployeeValue === 'credits' && (
              <EmployeeInfoCredits
                employeeId={employeeId}
                setTableCreditsPage={setTableCreditsPage}
              />
            )}
            <ConfirmExitDialog />
            <ConfirmationDialog
              key={`download-employee-records-${employeeId}`}
              showDivider={false}
              open={openDownload}
              onClose={handleDownloadEmployeeRecords}
              title="Descargar expediente laboral"
              content="El proceso de descarga iniciará en segundo plano y recibirás una notificación cuando se haya completado."
              acceptLabel="Iniciar proceso"
            />

            <AddOtpDialog
              open={showOtpModal}
              handleClose={() => setShowOtpModal(false)}
              employeeData={employeeData}
            />

            <CreateEmployeeAbsenceDialog />
            <FinishEmployeeAbsenceDialog />
          </ErrorBoundary>
        </EmployeeInfoContent>
      </Dialog>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
