import React from 'react';
import { Box, Card, CardContent, Stack } from '@mui/material';
import { CalendarEvent, CalendarEventTypes } from './SoraCalendar.types';
import { Icon } from '../../components/Icons/Icons';
import { useTheme } from '@mui/material/styles';
import { Typography } from '../Typography';
import { useScreenSize } from '../../Hooks';
import { IconButton } from '../IconButton';

type DotProps = {
  eventType: CalendarEventTypes;
  color: string;
};

const Dot: React.FC<DotProps> = ({ eventType, color }) => {
  //TODO: Review if we need add different colors for different event types
  return (
    <Box
      width="8px"
      height="8px"
      borderRadius="50%"
      sx={{ background: color }}
      mr={1}
    />
  );
};

type CalendarEventsSideBarProps = {
  events?: CalendarEvent[];
  handleEventClick?: (event: CalendarEvent) => void;
  handleDeleteEventClick?: (event: CalendarEvent) => void;
  isSupportPanel?: boolean;
};

function dateFormatter(date: Date, includeYear = false): string {
  const monthString = date.toLocaleString('es-Mx', { month: 'long' });
  const monthCapitalized =
    monthString.charAt(0).toUpperCase() + monthString.slice(1);
  const day = date.getDate();
  if (includeYear) {
    return `${day} ${monthCapitalized} ${date.getFullYear()}`;
  }
  return `${day} ${monthCapitalized}`;
}

export const CalendarEventsSideBar: React.FC<CalendarEventsSideBarProps> = ({
  events,
  handleEventClick,
  handleDeleteEventClick,
  isSupportPanel = false,
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  const editEvent = (event: CalendarEvent) => {
    if (isSupportPanel) {
      return handleEventClick?.(event);
    } else {
      if (event.type === CalendarEventTypes.COMPANY) {
        return handleEventClick?.(event);
      }
    }
  };

  return (
    <Stack mx={2} p={2}>
      <Stack direction={'row'} gap={2}>
        <Typography component={'span'} color={'gray_70'}>
          <Icon icon={'calendar_check_line'} color={'grey'} />
        </Typography>
        <Typography variant="h6" mb={2}>
          Festivos
        </Typography>
      </Stack>

      <Stack gap={2}>
        {events.length === 0 && (
          <Typography variant={'body1'}>No hay eventos</Typography>
        )}
        {events?.map((event, index) => (
          <Card
            key={index}
            onClick={() => editEvent(event)}
            sx={{
              cursor: 'pointer',
              borderRadius: '16px',
              border: `1px solid ${theme.customPalette.grayColors.gray_30}`,
              '&:hover': {
                backgroundColor: theme.customPalette.grayColors.gray_10,
              },
            }}
          >
            <CardContent>
              <Stack
                direction={'row'}
                alignItems={'start'}
                justifyContent={'space-between'}
              >
                <Stack>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Dot eventType={event.type} color={event.color} />
                    <Typography variant="body1" fontWeight={'bold'}>
                      <strong>{dateFormatter(event.date, isMobile)}</strong>
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body1"
                    color={theme.newPalette.text.secondary}
                  >
                    {event.title}
                  </Typography>
                </Stack>
                <Stack>
                  {(event.type === CalendarEventTypes.COMPANY ||
                    isSupportPanel) &&
                    !!handleDeleteEventClick && (
                      <IconButton
                        icon={'delete_bin_line'}
                        color={'error'}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteEventClick?.(event);
                        }}
                      />
                    )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};
