import React from 'react';
import { EditHolidaySchemeDialogFormType } from './EditHolidaySchemeDialog.types';
import { UseFormReturn } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../../newComponents/Typography';
import { useTheme } from '@mui/material/styles';
import { newPaletteType } from '../../../../../../../theme';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { DaysEarnedByYearScheme } from '../../holidaySchemes.types';
import { useScreenSize } from '../../../../../../../Hooks';

type BlockProps = React.PropsWithChildren<{
  color?: 'success' | 'warning' | 'error' | 'info';
  title: string;
}>;

const Block: React.FC<BlockProps> = ({ color, children, title }) => {
  const theme = useTheme();
  const palette: newPaletteType = theme.newPalette;
  const allBlockSx = {
    success: {
      backgroundColor: palette.success.lighter,
      color: palette.success.dark,
    },
    warning: {
      backgroundColor: palette.warning.lighter,
      color: palette.warning.dark,
    },
  };

  const currentSx = allBlockSx[color] || {};

  return (
    <Stack p={2} sx={{ backgroundColor: currentSx.backgroundColor, flex: '1' }}>
      {children || (
        <Typography
          variant={'body2'}
          color={currentSx.color}
          textAlign={'center'}
        >
          {title}
        </Typography>
      )}
    </Stack>
  );
};

const mergeRepeatedElements = (daysScheme: DaysEarnedByYearScheme[]) => {
  const grouped = daysScheme.reduce((acc, scheme) => {
    if (!acc[scheme.days]) acc[scheme.days] = [];
    acc[scheme.days].push(scheme.year);
    return acc;
  }, {});

  return Object.keys(grouped).map((daysEarnedIndex) => {
    const daysEarned = grouped[daysEarnedIndex];
    const year =
      daysEarned.length === 1
        ? daysEarned[0].toString()
        : `${daysEarned[0]} - ${daysEarned[daysEarned.length - 1]}`;
    return {
      year,
      days: daysEarnedIndex,
    };
  });
};

const YearEarnedBox: React.FC<{
  year: string;
  days: string;
  color?: 'success' | 'warning';
}> = ({ year, days, color }) => {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Block color={color} title={year} />
      <Stack p={1}>
        <Icon icon={'arrow_right_line'} />
      </Stack>
      <Block color={color} title={`${days} días`} />
    </Stack>
  );
};

type DaysGrantedProps = {
  form: UseFormReturn<EditHolidaySchemeDialogFormType>;
};
export const DaysGranted: React.FC<DaysGrantedProps> = ({ form }) => {
  const { getValues } = form;
  const holidayScheme = getValues('selectedScheme');
  const { scheme = [] } = holidayScheme;
  const { isMobile } = useScreenSize();
  const theme = useTheme();
  const palette = theme.newPalette;

  const mergedScheme = mergeRepeatedElements(
    scheme as DaysEarnedByYearScheme[],
  );

  return (
    <Stack
      sx={{
        minWidth: '400px',
        borderRight: !isMobile ? `1px solid ${palette.divider}` : undefined,
      }}
      p={2}
      gap={1}
    >
      <Typography variant="body1">
        <strong>Días otorgados</strong>
      </Typography>
      <Stack className="DayGranted_DayList">
        <Stack direction={'row'} alignItems={'center'}>
          <Block color={'success'} title={'Periodo cumplido'} />
          <Stack p={1}>
            <Icon icon={'arrow_right_line'} />
          </Stack>
          <Block color={'warning'} title={'Se otorgan'} />
        </Stack>
        {mergedScheme.map((scheme) => (
          <YearEarnedBox
            key={scheme.year}
            year={scheme.year}
            days={scheme.days.toString()}
          />
        ))}
      </Stack>
    </Stack>
  );
};
